import React from 'react';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
import './App.scss';
import './custom.scss';
import store from './state/store';
import AppRoutes from './routes/AppRoutes';

import 'react-toastify/dist/ReactToastify.css';

function App() {
    return (
        <Provider store={store}>
            <AppRoutes />
            <ToastContainer />
        </Provider>
    );
}

export default App;
