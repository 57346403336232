import { showToastError } from './toastHelper';

const errors = {
    ACTIVITIES_RESET_ELEMENTS: 'Unable to reset activity elements',
    ACTIVITIES_RETRIEVE: 'Unable to retrieve activities',
    ACTIVITIY_ADD: 'Unable to add activity',
    ACTIVITIY_DELETE: 'Unable to delete activity',
    ACTIVITIY_UPDATE: 'Unable to update activity',
    ACTIVITY_RETRIEVE: 'Unable to retrieve activity',
    ACTIVITY_REWARDEE_RETRIEVAL: 'Unable to retrieve activity rewardees',
    ACTIVITY_REWARDEE_INDIVIDUAL_ADD: 'Unable to add individual rewardee',
    ACTIVITY_REWARDEE_INDIVIDUAL_REMOVE: 'Unable to remove individual rewardee',
    ACTIVITY_REWARDEE_GROUP_ADD: 'Unable to add group rewardees',
    ACTIVITY_REWARDEE_GENERATE_REWARD: 'Unable to generate rewards for rewardee',
    ACTIVITY_RESET_REWARDS: 'Unable to reset rewards for activity',
    ACTIVITY_SHARE_REWARDS: 'Unable to share rewards for activity',
    ACTIVITY_REWARDEES_SHARE_REWARD: 'Unable to share rewards for receiver',
    ISSUED_REWARDS_RETRIEVE: 'Unable to retrieve issued rewards',
    AUTH_GET_PROFILE: 'Unable to retrieve logged user information',
    AUTH_LOGIN_FAILED: 'Unable to login with provided credentials',
    AUTH_REQUEST_PW_RESET: 'Unable to fullfill password reset request',
    AUTH_PW_RESET: 'Unable to reset user password',
    ELEMENT_ADD: 'Unable to add element',
    ELEMENT_DELETE: 'Unable to delete element',
    ELEMENT_RETRIEVE: 'Unable to retrieve element',
    ELEMENT_UPDATE: 'Unable to update element',
    ELEMENTS_FILTER_BY_STATUS: 'Unable to filter elements by status',
    ELEMENTS_FILTER_BY_TYPE: 'Unable to filter elements by type',
    ELEMENTS_RETRIEVE: 'Unable to retrieve elements',
    ELEMENTS_TEMPLATE_RETRIEVE: 'Unable to retrieve template elements',
    GROUP_ADD: 'Unable to add group',
    GROUP_RETRIEVE: 'Unable to retrive group',
    GROUPS_FILTER_BY_EMAIL: 'Unable to filter groups by email',
    GROUPS_FILTER_BY_NAME: 'Unable to filter groups by name',
    GROUPS_FILTER_BY_STATUS: 'Unable to filter groups by status',
    GROUPS_RETRIEVE: 'Unable to retrive receiver',
    ISSUER_PROFILE_RETRIEVE: 'Unable to retrieve issuer profile information',
    ORGANIZATIONS_RETRIEVE: 'Unable to retrieve organizations information',
    ORGANIZATION_RETRIEVE: 'Unable to retrieve organization information',
    ORGANIZATION_ADD: 'Unable to add organization information',
    ORGANIZATION_TXT_GENERATE: 'Unable to retrieve organization domain TXT',
    ORGANIZATION_VERIFY: 'Unable to verify organization domain',
    ORGANIZATION_UPDATE: 'Unable to update organization',
    ORGANIZATION_DELETE: 'Unable to delete organization',
    ORGANIZATION_USER_SEARCH_BY_NAME: 'Unable to search user by name',
    ORGANIZATION_USER_UPDATE: 'Unable to update organization user',
    ORGANIZATION_USER_DELETE: 'Unable to delete organization user',
    ORGANIZATION_USERS_RETRIEVE: 'Unable to retrieve organization users',
    ORGANIZATION_USER_INVITE: 'Unable to invite user to the organization',
    ORGANIZATION_USER_INVITE_TOKEN_MISSING: 'Unable to find token to join this organization',
    ORGANIZATION_USER_ACCEPT_INVITE: 'Unable to accept the invitation to the organization',
    ORGANIZATION_USER_RETRIVE: 'Unable to organization retrieve user',
    ORGANIZATION_USER_PASSWORD_CHANGE: 'Unable to change the password of organization user',
    RECEIVER_ADD_TO_GROUP: 'Unable to add receiver to group',
    RECEIVER_REMOVE_FROM_GROUP: 'Unable remove receiver from group',
    RECEIVERS_FILTER_BY_EMAIL: 'Unable to filter receivers by email',
    RECEIVERS_FILTER_BY_NAME: 'Unable to filter receivers by name',
    RECEIVERS_FILTER_BY_STATUS: 'Unable to filter receivers by status',
    RECEIVERS_RETRIEVE: 'Unable to retrive receivers',
    RECEIVERS_INVITE: 'Unable to invite receivers',
    RECEIVER_INVITE: 'Unable to invite receiver',
    ZEDEID_HOLDER_PUB_KEY: 'Unable to retreive holder public key'
};

const notifyError = (isError, error, message = '') => {
    if (isError) {
        const errorMsg = error?.response?.data?.message || error?.data?.message;
        showToastError(`${errorMsg ? `${errorMsg}.` : ''} ${message}`);
    }
};

export { notifyError as default, errors };
