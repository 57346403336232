import React from 'react';
import SuperUserDashboard from './Admin/SuperUserDashboard';
import OrgAdminDashboard from './OrgUser/OrgAdminDashboard';
import OrgUserDashboard from './OrgUser/OrgUserDashboard';

import { useGetProfileQuery } from '../services/authService';
import constants from '../utils/constants';

const DashboardMapper = () => {
    const { data: profile } = useGetProfileQuery();

    const Dashboard = React.useMemo(() => {
        if (profile?.role) {
            switch (profile.role) {
                case constants.ROLE_SUPER_USER:
                    return SuperUserDashboard;
                case constants.ROLE_ORG_ADMIN:
                    return OrgAdminDashboard;
                case constants.ROLE_ORG_USER:
                    return OrgUserDashboard;
                default:
                    return () => '';
            }
        }
        return () => '';
    }, [profile]);

    return <Dashboard />;
};

export default DashboardMapper;
