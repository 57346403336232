import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import constants from '../../utils/constants';

import superUserMapping from './mappings/super-user.mapping.json';
import orgAdminMapping from './mappings/org-admin.mapping.json';
import orgUserMapping from './mappings/org-user.mapping.json';
import { useGetProfileQuery } from '../../services/authService';
import './Sidebar.styles.scss';

const Sidebar = () => {
    const { pathname, state } = useLocation();
    const { data: profile } = useGetProfileQuery();
    const sidebarMapping = React.useMemo(() => {
        if (profile) {
            const { role } = profile;
            switch (role) {
                case constants.ROLE_SUPER_USER:
                    return superUserMapping;
                case constants.ROLE_ORG_ADMIN:
                    return orgAdminMapping;
                case constants.ROLE_ORG_USER:
                    return orgUserMapping;
                default:
                    return [];
            }
        }
        return [];
    }, [profile]);

    // Router state variable is used to keep initial active nav item
    const getActiveLinkClassName = (mappingURI) => {
        let className = 'nav-link';
        if (state?.overrideActiveRoute && mappingURI.includes(state?.overrideActiveRoute)) {
            className = 'nav-link active';
        }
        if (!state?.overrideActiveRoute && pathname.includes(mappingURI)) {
            className = 'nav-link active';
        }

        return className;
    };

    return (
        <nav className="col-md-2 px-0 d-none d-md-block sidebar bg-white">
            <div className="sidebar-sticky">
                <Link to="/" className="w-100">
                    <div className="p-2 mb-5">
                        <div className="layout-navitem-logo-wrapper">
                            <img
                                src="/assets/vector/mr-sidebar-logo.svg"
                                alt="Micro Rewards"
                                className="layout-navitem-logo"
                            />
                        </div>
                    </div>
                </Link>
                <ul className="nav flex-column flex-grow-1 overflow-auto">
                    {sidebarMapping.map((mapping) => (
                        <li key={mapping.uri} className="nav-item">
                            <Link
                                className={getActiveLinkClassName(mapping?.uri)}
                                to={mapping?.uri}>
                                {mapping.icon && (
                                    <div
                                        style={{
                                            maskImage: `url(${mapping.icon})`,
                                            WebkitMaskImage: `url(${mapping.icon})`
                                        }}
                                        className="nav-icon"
                                    />
                                )}
                                {mapping.name}
                            </Link>
                        </li>
                    ))}
                </ul>
                <ul className="nav flex-column">
                    <li className="nav-item">
                        <Link to="/signout" className="nav-link">
                            <div className="nav-icon logout" />
                            Logout
                        </Link>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default Sidebar;
