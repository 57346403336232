import React, { useState } from 'react';
import * as _ from 'lodash';
import PaginatedTableHeader from '../PaginatedTableHeader/PaginatedTableHeader';

import './ScrollTable.styles.scss';

const ROW_HEIGHT = 75;

export default function ScrollTable({
    columns,
    columnNames,
    activeFilters,
    filters,
    rows,
    data,
    actions,
    headerActions,
    pagination = false,
    pageSize = 5,
    onRowClick,
    onSetFilters,
    rounded = false
}) {
    const [currentPage, setCurrentPage] = useState(1);

    const paginatedRows = pagination
        ? rows.slice((currentPage - 1) * pageSize, currentPage * pageSize)
        : rows;

    const getEmptyMarker = () => <div style={{ textAlign: 'center', width: '100%' }} />;

    const renderRoundedBorders = (rowIdx, colIdx) => {
        const classes = [];
        if (rounded) {
            if (rowIdx === 0) {
                if (colIdx === 0) {
                    classes.push('rounded-top-left');
                }
                if (!actions && colIdx === columns.length - 1) {
                    classes.push('rounded-top-right');
                }
            }
            if (rowIdx === rows.length - 1) {
                if (colIdx === 0) {
                    classes.push('rounded-bottom-left');
                }
                if (!actions && colIdx === columns.length - 1) {
                    classes.push('rounded-bottom-right');
                }
            }
        }
        return classes.join(' ');
    };

    const renderRoundedActionBorders = (rowIdx) => {
        const classes = [];
        if (rounded) {
            if (rowIdx === 0) {
                classes.push('rounded-top-right');
            }
            if (rowIdx === rows.length - 1) {
                classes.push('rounded-bottom-right');
            }
        }
        return classes.join(' ');
    };

    return (
        <div className="scroll-table">
            <div className="table-responsive">
                <table className="table table-borderless">
                    {columnNames || actions || filters ? (
                        <PaginatedTableHeader
                            columnNames={columnNames}
                            actions={headerActions}
                            filters={filters}
                            activeFilters={activeFilters}
                            onSetFilters={onSetFilters}
                            rounded={rounded}
                        />
                    ) : (
                        ''
                    )}
                    <tbody style={{ maxHeight: pageSize * ROW_HEIGHT }}>
                        {paginatedRows &&
                            paginatedRows.map((rowId, rowIdx) => (
                                <tr
                                    key={rowId}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        if (onRowClick) {
                                            onRowClick(data[rowId]);
                                        }
                                    }}>
                                    {columns.map((c, idx) => (
                                        <td className="fw-500 align-middle" key={idx}>
                                            <div
                                                className={`bg-white content ${renderRoundedBorders(
                                                    rowIdx,
                                                    idx
                                                )}`}>
                                                {_.isFunction(c)
                                                    ? c(data[rowId]) || getEmptyMarker()
                                                    : _.get(data[rowId], c, null) ||
                                                      getEmptyMarker()}
                                            </div>
                                        </td>
                                    ))}
                                    {actions && actions.length > 0 && (
                                        <td className="align-middle">
                                            <div
                                                className={`bg-white content text-truncate p-0 d-block ${renderRoundedActionBorders(
                                                    rowIdx
                                                )}`}>
                                                {actions.map((action, idx) => (
                                                    <button
                                                        type="button"
                                                        key={idx}
                                                        className={`btn ${
                                                            action?.isIcon
                                                                ? 'h-100'
                                                                : 'btn-sm btn-outline-primary btn-block'
                                                        }`}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            action.action(data[rowId]);
                                                        }}>
                                                        {action.name}
                                                    </button>
                                                ))}
                                            </div>
                                        </td>
                                    )}
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
