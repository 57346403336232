import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useLoginStatus } from '../hooks/auth';
import { useGetProfileQuery } from '../services/authService';

export const PrivateRoute = ({ component: Component, fallback, roles = [], ...rest }) => {
    const isLoggedIn = useLoginStatus();
    const { data: profile } = useGetProfileQuery();

    const hasPermission = roles?.includes?.(profile?.role);

    return (
        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /signin page
        isLoggedIn ? (
            <Route {...rest}>
                {hasPermission ? (
                    <Component {...rest} />
                ) : fallback ? (
                    <Redirect to={fallback} />
                ) : (
                    ''
                )}
            </Route>
        ) : (
            <Redirect to="/signin" />
        )
    );
};

export const PublicRoute = ({ component: Component, restricted, ...rest }) => {
    const isLoggedIn = useLoginStatus();
    return (
        // restricted = false meaning public route
        // restricted = true meaning restricted route
        <Route
            {...rest}
            render={(props) =>
                isLoggedIn && restricted ? <Redirect to="/" /> : <Component {...props} />
            }
        />
    );
};
