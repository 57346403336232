import React from 'react';
import { useHistory } from 'react-router';
import EditableLogo from '../components/EditableLogo';
import Divider from '../components/Divider';
import { BackBtn } from '../components/Buttons';
import constants from '../utils/constants';
import { getDisplayValueForUserRole } from '../utils/dataUtils';
import notifyError, { errors } from '../utils/rtkErrorHelper';

import authService, { useGetProfileQuery } from '../services/authService';
import { Form, PasswordFeild } from '../components/FormElements';
import { showToastError, showToastSuccess } from '../utils/toastHelper';

const MyProfile = () => {
    const history = useHistory();

    const [userAvatar, setUserAvatar] = React.useState(null);
    const [DisablePwdBtn, setDisablePwdBtn] = React.useState(false);
    const [user, setUser] = React.useState({
        newPassword: '',
        confirmNewPassword: '',
        currentPassword: ''
    });

    const {
        data: profile,
        error: getProfileError,
        isError: isGetProfileError
    } = useGetProfileQuery();

    const [
        changeUserPassword,
        {
            error: changeUserPasswordError,
            isError: isChangeUserPasswordError,
            isSuccess: isChangeUserPasswordSuccess
        }
    ] = authService.useChangeUserPasswordMutation();

    const parseUserData = () => {
        if (profile) {
            const { avatar } = profile;
            setUserAvatar(avatar);
        }
    };

    React.useEffect(() => {
        notifyError(isGetProfileError, getProfileError, errors.AUTH_GET_PROFILE);

        notifyError(
            isChangeUserPasswordError,
            changeUserPasswordError,
            errors.ORGANIZATION_USER_PASSWORD_CHANGE
        );
    }, [getProfileError, isGetProfileError, isChangeUserPasswordError, changeUserPasswordError]);

    React.useEffect(() => {
        parseUserData();
    }, [profile]);

    React.useEffect(() => {
        if (isChangeUserPasswordSuccess) {
            showToastSuccess('Organization user password changed successfully');
        }
    }, [isChangeUserPasswordSuccess]);

    const isOrgUser = React.useMemo(() => {
        let flag = false;
        if (profile?.role === constants.ROLE_ORG_USER) {
            flag = true;
        }

        return flag;
    }, [profile]);

    const onClickBack = () => {
        history.goBack();
    };

    const onChangePassword = (e) => {
        setUser({
            ...user,
            ...{ newPassword: e.target.value }
        });
    };

    const onChangeConfirmPassword = (e) => {
        setUser({
            ...user,
            ...{ confirmNewPassword: e.target.value }
        });
    };

    const onChangeCurrentPassword = (e) => {
        setUser({
            ...user,
            ...{ currentPassword: e.target.value }
        });
    };

    const onClickResetPassword = async () => {
        const { newPassword, confirmNewPassword, currentPassword } = user;

        if (newPassword && confirmNewPassword) {
            setDisablePwdBtn(true);

            const payload = {
                orgId: profile?.organizationId,
                userId: profile?.id,
                currentPassword: currentPassword,
                password: newPassword,
                confirm_password: confirmNewPassword,
                useCurrentPassword: true
            };
            await changeUserPassword(payload);

            setUser({ newPassword: '', confirmNewPassword: '', currentPassword: '' });
            setDisablePwdBtn(false);
        } else {
            showToastError('Both password and confirmation required.');
        }
    };

    return (
        <div>
            <div className="container-fluid py-4 px-5 mt-4 bg-white rounded">
                <div className="row mb-4 px-2 justify-content-between">
                    <BackBtn title="My Profile" onClick={onClickBack} />
                </div>
                <div className="row my-3">
                    <div className="col-lg-2">
                        <EditableLogo
                            logo={userAvatar || '/assets/img/mr-avatar-placeholder-2.png'}
                        />
                    </div>
                    <div className="col-lg-6">
                        <div className="container-fluid">
                            <div className="row mb-4">
                                <div className="col-md-5 fw-500">First Name</div>
                                <div className="col-md-7">
                                    <div className="text-truncate">{profile?.first_name}</div>
                                </div>
                            </div>
                            <Divider />
                            <div className="row my-4">
                                <div className="col-md-5 fw-500">Last Name</div>
                                <div className="col-md-7">
                                    <div className="text-truncate">{profile?.last_name}</div>
                                </div>
                            </div>
                            <Divider />
                            <div className="row my-4">
                                <div className="col-md-5 fw-500">Username</div>
                                <div className="col-md-7">
                                    <div className="text-truncate">{profile?.username}</div>
                                </div>
                            </div>
                            <Divider />
                            <div className="row my-4">
                                <div className="col-md-5 fw-500">Email</div>
                                <div className="col-md-7">
                                    <div className="text-truncate">{profile?.email}</div>
                                </div>
                            </div>
                            <Divider />
                            {isOrgUser && (
                                <>
                                    <div className="row my-4">
                                        <div className="col-md-5 fw-500">Role</div>
                                        <div className="col-md-7">
                                            <div className="text-truncate">
                                                {getDisplayValueForUserRole(profile?.role)}
                                            </div>
                                        </div>
                                    </div>
                                    <Divider />
                                </>
                            )}
                            <div className="row my-4">
                                <div className="col-md-5 fw-500">Status</div>
                                <div className="col-md-7">
                                    <div className="text-truncate text-capitalize">
                                        {profile?.is_active ? 'Active' : 'Inactive'}
                                    </div>
                                </div>
                            </div>
                            <Divider />
                        </div>
                    </div>
                </div>
                <div className="container-fluid bg-white rounded">
                    <div className="row my-3">
                        <h5 className="text-primary">Change Password</h5>
                    </div>
                    <Form initialValues={user} enableReinitialize>
                        <div className="row my-3">
                            <div className="col-lg-5">
                                <PasswordFeild
                                    name="currentPassword"
                                    label="Current Password"
                                    onChange={onChangeCurrentPassword}
                                />
                            </div>
                        </div>
                        <div className="row my-3">
                            <div className="col-lg-5">
                                <PasswordFeild
                                    name="newPassword"
                                    label="Password"
                                    onChange={onChangePassword}
                                />
                            </div>
                            <div className="col-lg-5">
                                <PasswordFeild
                                    name="confirmNewPassword"
                                    label="Confirm Password"
                                    onChange={onChangeConfirmPassword}
                                />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-12">
                                <button
                                    type="button"
                                    className="btn btn-primary py-2 fw-700"
                                    onClick={onClickResetPassword}
                                    disabled={DisablePwdBtn}>
                                    Change Password
                                </button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default MyProfile;
