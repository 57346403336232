import React from 'react';
import { useHistory } from 'react-router';
import * as Yup from 'yup';
import Breadcrumbs from '../../../components/Breadcrumbs';
import { BackBtn } from '../../../components/Buttons';
import { Form, SubmitButton, TextField } from '../../../components/FormElements';
import constants from '../../../utils/constants';
import {
    useAddOrganizationMutation,
    useInviteUserToOrgMutation
} from '../../../services/orgService';
import notifyError, { errors } from '../../../utils/rtkErrorHelper';
import { showToastError, showToastSuccess } from '../../../utils/toastHelper';
import { getCurrentEnv } from '../../../utils/dataUtils';

const organizationValidationSchema = Yup.object().shape({
    name: Yup.string().required('Enter organization name'),
    domain: Yup.string().required('Enter domain'),
    admin: Yup.string().required('Enter organization admin name'),
    adminEmail: Yup.string()
        .email('Invalid email address')
        .required('Enter organization admin email')
});

const OrganizationsAdd = () => {
    const history = useHistory();
    const [organizationData, setOrganizationData] = React.useState({
        name: '',
        domain: '',
        admin: '',
        adminEmail: '',
        admin_1: '',
        adminEmail_1: '',
        phone: ''
    });

    const [
        addOrganization,
        { isError: isAddOrganizationError, error: addOrganizationError }
    ] = useAddOrganizationMutation();

    const [
        inviteUser,
        { isError: isInviteUserError, error: inviteUserError, isSuccess: isInviteUserSuccess }
    ] = useInviteUserToOrgMutation();

    React.useEffect(() => {
        notifyError(isAddOrganizationError, addOrganizationError, errors.ORGANIZATION_ADD);
        notifyError(isInviteUserError, inviteUserError, errors.ORGANIZATION_USER_INVITE);
    }, [isAddOrganizationError, addOrganizationError, isInviteUserError, inviteUserError]);

    React.useEffect(() => {
        if (isInviteUserSuccess) {
            showToastSuccess('Organization created, admin invitation sent.');
        }
    }, [isInviteUserSuccess]);

    const onSubmitOrganization = async (values) => {
        const { name, domain, admin, adminEmail, phone, admin_1, adminEmail_1 } = values;
        const organization = {
            name,
            domain,
            descripition: ''
        };

        if ((!adminEmail_1 && admin_1) || (adminEmail_1 && !admin_1)) {
            return showToastError('Admin Email 2 and Admin Name 2 are required');
        }

        const orgAdmin = {
            username: admin,
            email: adminEmail,
            role: constants.ROLE_ORG_ADMIN,
            phone,
            env: getCurrentEnv()
        };

        const orgAdmin_1 = {
            username: admin_1,
            email: adminEmail_1,
            role: constants.ROLE_ORG_ADMIN,
            phone,
            env: getCurrentEnv()
        };

        const response = await addOrganization({ organization });
        if (response?.data?.id) {
            const { id: orgId } = response.data;
            await inviteUser({ user: orgAdmin, orgId });

            if (adminEmail_1 && admin_1) await inviteUser({ user: orgAdmin_1, orgId });
            history.push('/organizations');
        }
    };

    const onClickBack = () => {
        history.push('/organizations');
    };

    return (
        <div>
            <Breadcrumbs hideDashboard />
            <div className="container-fluid bg-white mt-4 p-3 rounded">
                <Form
                    validationSchema={organizationValidationSchema}
                    initialValues={organizationData}
                    onSubmit={onSubmitOrganization}
                    enableReinitialize>
                    <div className="row">
                        <div className="col-md-4 pl-4">
                            <BackBtn onClick={onClickBack} title="Add Organization" />
                        </div>
                        <div className="col-md-2 offset-md-6">
                            <SubmitButton
                                title="Add Organization"
                                className="btn btn-primary w-100 py-2"
                            />
                        </div>
                    </div>
                    <div className="container-fluid p-0">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="container-fluid">
                                    <div className="row justify-content-center my-3">
                                        <div className="col-12">
                                            <TextField
                                                name="name"
                                                placeholder="Organization Name"
                                                label="Organization Name"
                                            />
                                        </div>
                                    </div>
                                    <div className="row justify-content-center my-3">
                                        <div className="col-12">
                                            <TextField
                                                name="domain"
                                                placeholder="Domain"
                                                label="Domain"
                                            />
                                        </div>
                                    </div>
                                    <div className="row justify-content-center my-3">
                                        <div className="col-12">
                                            <TextField
                                                name="admin"
                                                placeholder="Organization Admin Name"
                                                label="Org Admin Name"
                                            />
                                        </div>
                                    </div>
                                    <div className="row justify-content-center my-3">
                                        <div className="col-12">
                                            <TextField
                                                name="adminEmail"
                                                placeholder="Organization Admin Email"
                                                label="Org Admin Email"
                                            />
                                        </div>
                                    </div>
                                    <div className="row justify-content-center my-3">
                                        <div className="col-12">
                                            <TextField
                                                name="admin_1"
                                                placeholder="Organization Admin 2 Name"
                                                label="Org Admin 2 Name"
                                            />
                                        </div>
                                    </div>
                                    <div className="row justify-content-center my-3">
                                        <div className="col-12">
                                            <TextField
                                                name="adminEmail_1"
                                                placeholder="Organization Admin 2 Email"
                                                label="Org Admin 2 Email"
                                            />
                                        </div>
                                    </div>
                                    <div className="row justify-content-center mt-3">
                                        <div className="col-12">
                                            <TextField
                                                name="phone"
                                                placeholder="Organization Telephone Number"
                                                label="Org Telephone"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default OrganizationsAdd;
