import React from 'react';
import dayjs from 'dayjs';
import * as Yup from 'yup';
import { Prompt } from 'react-router';
import {
    TextField,
    Select,
    SubmitButton,
    FileField,
    Form,
    MultilineTextField,
    DatePicker,
    Checkbox
} from '../../../components/FormElements';
import { TemplateCatalog } from '../../../components/CertificateTemplates';
import constants from '../../../utils/constants';
import { getDisplayValueForElementType } from '../../../utils/dataUtils';
import Breadcrumbs from '../../../components/Breadcrumbs';
import TemplatesDropdown from './TemplatesDropdown';
import { b64ToFile } from '../../../utils/fileUtils';

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Certificate name is required'),
    description: Yup.string().required('Certificate description is required')
});

const maxCustomAttributes = 10;

const elementTypeOptions = [
    {
        value: constants.ELEMENT_TYPE_BADGE,
        label: getDisplayValueForElementType(constants.ELEMENT_TYPE_BADGE)
    },
    {
        value: constants.ELEMENT_TYPE_CERTIFICATE,
        label: getDisplayValueForElementType(constants.ELEMENT_TYPE_CERTIFICATE)
    }
];

const ElementCertificate = ({ organizationId, onSave, onCancel, certificate: certificateData }) => {
    const [editingCustomAttributeIdx, setEditingCustomAttributeIdx] = React.useState(null);
    const [hasChanges, setHasChanges] = React.useState(false);
    const [certificate, setCertificate] = React.useState({
        name: '',
        type: constants.ELEMENT_TYPE_CERTIFICATE,
        certificate_logo: null,
        title: '',
        description: '',
        show_signature_one: true,
        signature_one: undefined,
        signature_title_one: 'Signature Title 1',
        show_signature_two: true,
        signature_two: undefined,
        signature_title_two: 'Signature Title 2',
        date: new Date(),
        receiverURL: '',
        is_template: false,
        template_name: '',
        display_qr: false,
        custom_attributes: [
            {
                label: 'Custom Attribute 1',
                value: 'Attribute Value 1'
            },
            {
                label: 'Custom Attribute 2',
                value: 'Attribute Value 2'
            }
        ]
    });

    const setCertificateData = (data) => {
        const certificateInformation = {
            ...data,
            date: dayjs(data?.date).toDate()
        };
        if (certificateInformation?.signature_one) {
            certificateInformation.show_signature_one = true;
        } else {
            certificateInformation.show_signature_one = false;
        }
        if (certificateInformation?.signature_two) {
            certificateInformation.show_signature_two = true;
        } else {
            certificateInformation.show_signature_two = false;
        }
        if (certificateInformation?.display_qr) {
            certificateInformation.display_qr = true;
        } else {
            certificateInformation.display_qr = false;
        }
        setCertificate(certificateInformation);
    };

    React.useEffect(() => {
        if (certificateData && typeof certificateData === 'object') {
            setCertificateData(certificateData);
        }
    }, [certificateData]);

    const handleOnFormChangesMade = () => {
        if (!hasChanges) {
            setHasChanges(true);
        }
    };

    const onAddCustomAttribute = () => {
        let attributeNumber = 0;
        if (certificate?.custom_attributes?.length > 0) {
            attributeNumber = certificate?.custom_attributes?.length;
        }
        const newAttribute = {
            label: `Custom Attribute ${attributeNumber + 1}`,
            value: `Custom value ${attributeNumber + 1}`
        };
        if (attributeNumber <= maxCustomAttributes - 1) {
            const newCustomAttributes = certificate?.custom_attributes?.slice() ?? [];
            newCustomAttributes.push(newAttribute);
            setCertificate({
                ...certificate,
                ...{ custom_attributes: newCustomAttributes }
            });
        }
        handleOnFormChangesMade();
    };

    const removeCustomAttribute = (index) => {
        const customAttributesCopy = certificate?.custom_attributes?.slice() ?? [];
        customAttributesCopy.splice(index, 1);
        setCertificate({
            ...certificate,
            ...{ custom_attributes: customAttributesCopy }
        });
        handleOnFormChangesMade();
    };

    const onChangeName = (e) => {
        setCertificate({
            ...certificate,
            ...{ name: e.target.value }
        });
        handleOnFormChangesMade();
    };
    const onChangeType = (e) => {
        setCertificate({
            ...certificate,
            ...{ type: e.target.value }
        });
        handleOnFormChangesMade();
    };
    const onChangeLogo = (e) => {
        setCertificate({
            ...certificate,
            ...{ certificate_logo: e.target.files[0] }
        });
        handleOnFormChangesMade();
    };
    const onChangeTitle = (e) => {
        setCertificate({
            ...certificate,
            ...{ title: e.target.value }
        });
        handleOnFormChangesMade();
    };
    const onChangeDescription = (e) => {
        setCertificate({
            ...certificate,
            ...{ description: e.target.value }
        });
        handleOnFormChangesMade();
    };
    const onChangeSeal1 = (e) => {
        setCertificate({
            ...certificate,
            ...{ signature_one: e.target.files[0] }
        });
        handleOnFormChangesMade();
    };
    const onChangeSign1 = (e) => {
        setCertificate({
            ...certificate,
            ...{ signature_title_one: e.target.value }
        });
        handleOnFormChangesMade();
    };
    const onChangeSeal2 = (e) => {
        setCertificate({
            ...certificate,
            ...{ signature_two: e.target.files[0] }
        });
        handleOnFormChangesMade();
    };
    const onChangeSign2 = (e) => {
        setCertificate({
            ...certificate,
            ...{ signature_title_two: e.target.value }
        });
        handleOnFormChangesMade();
    };
    const onChangeDate = (date) => {
        setCertificate({
            ...certificate,
            ...{ date }
        });
        handleOnFormChangesMade();
    };
    const onChangeReceiverUrl = (e) => {
        setCertificate({
            ...certificate,
            ...{ receiverURL: e.target.value }
        });
        handleOnFormChangesMade();
    };
    const onChangeTemplateName = (val) => {
        setCertificate({
            ...certificate,
            ...{ template_name: val }
        });
        handleOnFormChangesMade();
    };
    const onChangeHideSignature1 = (val) => {
        setCertificate({
            ...certificate,
            ...{ show_signature_one: val }
        });
        handleOnFormChangesMade();
    };
    const onChangeHideSignature2 = (val) => {
        setCertificate({
            ...certificate,
            ...{ show_signature_two: val }
        });
        handleOnFormChangesMade();
    };
    const onChangeQrDisplay = (val) => {
        setCertificate({
            ...certificate,
            ...{ display_qr: val }
        });
        handleOnFormChangesMade();
    };

    const onChangeAttributeLabel = (attributeIdx, e) => {
        const { custom_attributes } = certificate;
        const attribute = { ...custom_attributes[attributeIdx] };
        attribute.label = e.target.value || 'Custom Attribute 1';
        const changedAttributes = custom_attributes?.slice();
        changedAttributes[attributeIdx] = attribute;
        setCertificate({
            ...certificate,
            ...{ custom_attributes: changedAttributes }
        });
    };

    const onChangeAttributeValue = (attributeIdx, e) => {
        const { custom_attributes } = certificate;
        const attribute = { ...custom_attributes[attributeIdx] };
        attribute.value = e.target.value;
        const changedAttributes = custom_attributes?.slice();
        changedAttributes[attributeIdx] = attribute;
        setCertificate({
            ...certificate,
            ...{ custom_attributes: changedAttributes }
        });
    };

    const onSelectTemplate = (data) => {
        const parsedData = {
            is_template: false
        };
        const certificateLogo = b64ToFile(data?.certificate_logo, 'certificate_logo');
        const signatureOne = b64ToFile(data?.signature_one, 'signature_one');
        const signatureTwo = b64ToFile(data?.signature_two, 'signature_two');
        if (certificateLogo) {
            parsedData.certificate_logo = certificateLogo;
        }
        if (signatureOne) {
            parsedData.signature_one = signatureOne;
        }
        if (signatureTwo) {
            parsedData.signature_two = signatureTwo;
        }
        const cleanedCertificteInformation = {
            ...data,
            ...parsedData
        };
        // remove certificate name due to key errors in db;
        delete cleanedCertificteInformation?.name;
        setCertificateData(cleanedCertificteInformation);
    };

    const handleOnSave = (values) => {
        setHasChanges(false);
        onSave(values);
    };

    const handleOnEditCustomAttributeLabel = (idx) => {
        if (editingCustomAttributeIdx === idx) {
            setEditingCustomAttributeIdx(null);
        } else {
            setEditingCustomAttributeIdx(idx);
        }
    };

    return (
        <>
            <Prompt
                when={hasChanges}
                message="You have unsaved changes. Leaving this page will discard them. Are you sure?"
            />
            {!certificateData && (
                <div className="container-fluid my-1">
                    <div className="row">
                        <div className="col-md-4">
                            <Breadcrumbs hideDashboard />
                        </div>
                        <div className="col-md-4 offset-md-4 d-flex align-items-center">
                            <TemplatesDropdown
                                type={constants.ELEMENT_TYPE_CERTIFICATE}
                                onSelect={onSelectTemplate}
                            />
                        </div>
                    </div>
                </div>
            )}
            <div className="container-fluid mt-2 bg-white rounded">
                <Form
                    initialValues={certificate}
                    onSubmit={handleOnSave}
                    validationSchema={validationSchema}
                    enableReinitialize>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="container-fluid">
                                <div className="row my-3">
                                    <div className="col-12">
                                        <TextField
                                            name="name"
                                            label="Element Name"
                                            placeholder="Element Name"
                                            onChange={onChangeName}
                                        />
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <div className="col-md-6">
                                        <Select
                                            disabled
                                            name="type"
                                            label="Element Type"
                                            options={elementTypeOptions}
                                            placeholder="Choose Element type"
                                            onChange={onChangeType}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <FileField
                                            name="certificate_logo"
                                            label="Certificate Logo"
                                            onChange={onChangeLogo}
                                            accept="image/*"
                                        />
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <div className="col-12">
                                        <TextField
                                            name="title"
                                            label="Title"
                                            placeholder="Enter Title"
                                            onChange={onChangeTitle}
                                        />
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <div className="col-12">
                                        <MultilineTextField
                                            name="description"
                                            label="Description"
                                            placeholder="Enter Description"
                                            onChange={onChangeDescription}
                                        />
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <div className="col-md-6">
                                        <Checkbox
                                            name="show_signature_one"
                                            label="Signature/Seal 1"
                                            onChange={onChangeHideSignature1}
                                        />
                                        <FileField
                                            name="signature_one"
                                            onChange={onChangeSeal1}
                                            accept="image/*"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <TextField
                                            name="signature_title_one"
                                            label="Signature Title 1"
                                            placeholder="Enter Signature Title"
                                            onChange={onChangeSign1}
                                        />
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <div className="col-md-6">
                                        <Checkbox
                                            name="show_signature_two"
                                            label="Signature/Seal 2"
                                            onChange={onChangeHideSignature2}
                                        />
                                        <FileField
                                            name="signature_two"
                                            onChange={onChangeSeal2}
                                            accept="image/*"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <TextField
                                            name="signature_title_two"
                                            label="Signature Title 2"
                                            placeholder="Enter Signature Title"
                                            onChange={onChangeSign2}
                                        />
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <div className="col-12">
                                        <DatePicker
                                            name="date"
                                            label="Date"
                                            placeholder="Pick Date"
                                            onChange={onChangeDate}
                                        />
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <div className="col-12">
                                        <div className="qr-checkbox">
                                            <Checkbox
                                                name="display_qr"
                                                label="Certificate URL as QR Code"
                                                onChange={onChangeQrDisplay}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {certificate?.custom_attributes?.map((attribute, idx) => (
                                    <div className="row my-3" key={idx}>
                                        <div className="col-12 my-2">
                                            <div className="d-flex align-items-center">
                                                {editingCustomAttributeIdx === idx ? (
                                                    <TextField
                                                        name={`custom_attributes[${idx}].label`}
                                                        placeholder={`Custom Label ${idx + 1}`}
                                                        onChange={(e) =>
                                                            onChangeAttributeLabel(idx, e)
                                                        }
                                                    />
                                                ) : (
                                                    <label
                                                        className="fw-700 input-text-sm mb-0"
                                                        htmlFor={attribute.label}>
                                                        {attribute?.label}
                                                    </label>
                                                )}
                                                <button
                                                    type="button"
                                                    className="org-user-element-icon-sm"
                                                    onClick={() => {
                                                        handleOnEditCustomAttributeLabel(idx);
                                                    }}>
                                                    <img
                                                        src="/assets/vector/mr-update-primary.svg"
                                                        alt=""
                                                        width="100%"
                                                        height="100%"
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-md-10">
                                            <TextField
                                                name={`custom_attributes[${idx}].value`}
                                                placeholder={`Custom Value ${idx + 1}`}
                                                onChange={(e) => onChangeAttributeValue(idx, e)}
                                            />
                                        </div>
                                        <div className="col-md-1 p-0 d-flex align-items-end justify-content-center">
                                            <button type="button" className="btn p-0">
                                                <img
                                                    src="/assets/vector/mr-btn-update.svg"
                                                    alt="Update"
                                                />
                                            </button>
                                        </div>
                                        <div className="col-md-1 p-0 d-flex align-items-end justify-content-center">
                                            <button
                                                type="button"
                                                className="btn p-0"
                                                onClick={() => {
                                                    removeCustomAttribute(idx);
                                                }}>
                                                <img
                                                    src="/assets/vector/mr-btn-delete.svg"
                                                    alt="Delete"
                                                />
                                            </button>
                                        </div>
                                    </div>
                                ))}
                                <div className="row my-3 px-3">
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-border-rounded-xl px-5 py-2"
                                        onClick={onAddCustomAttribute}>
                                        Add Attribute
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 position-relative py-3">
                            <small className="fw-700">Preview</small>
                            <TemplateCatalog
                                organizationId={organizationId}
                                certificate={certificate}
                                templateName={certificate?.template_name}
                                onChangeTemplate={onChangeTemplateName}
                            />

                            <div className="container-fluid org-user-element-certificate-btn-container">
                                <div className="row my-3">
                                    <div className="col-md-4 offset-md-4">
                                        <Checkbox name="is_template" label="Save as template" />
                                    </div>
                                </div>
                                <div className="row justify-content-end">
                                    <div className="col-md-4">
                                        <SubmitButton
                                            title="Save"
                                            className="btn btn-primary py-2 fw-700 w-100"
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <button
                                            type="button"
                                            className="btn text-primary border py-2 fw-700 w-100"
                                            onClick={onCancel}>
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        </>
    );
};

export default ElementCertificate;
