import React from 'react';
import { Route, Switch } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import Tabs from '../../components/Tabs';

import Profile from './Profile';
import VerifyDomain from './VerifyDomain';
import ProfileEdit from './ProfileEdit';

export default function OrgUserDashboard() {
    const { url } = useRouteMatch();

    const tabs = [
        {
            label: 'Dashboard',
            route: `${url}`
        },
        {
            label: 'Profile',
            route: `${url}/profile`,
            includeRoutes: [`${url}/profile/edit`]
        }
    ];

    return (
        <div className="overflow-auto">
            <Tabs items={tabs} />
            <Switch>
                <Route component={Profile} path={`${url}/profile`} exact />
                <Route component={ProfileEdit} path={`${url}/profile/edit`} exact />
                <Route component={VerifyDomain} path={`${url}/profile/verify-domain`} exact />
            </Switch>
        </div>
    );
}
