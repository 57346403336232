import React from 'react';
import { useHistory } from 'react-router';
import { Form, TextField, SubmitButton } from '../../components/FormElements';
import { BackBtn } from '../../components/Buttons';
import { useGetProfileQuery } from '../../services/authService';
import {
    useGenerateOrganizationTXTMutation,
    useGetOrganizationQuery,
    useVerifyOrganizationDomainMutation
} from '../../services/orgService';
import notifyError, { errors } from '../../utils/rtkErrorHelper';
import { showToastError, showToastSuccess } from '../../utils/toastHelper';

const VerifyDomain = () => {
    const history = useHistory();

    const { data: profile } = useGetProfileQuery();
    const { data: orgDetails } = useGetOrganizationQuery(
        { orgId: profile?.organizationId },
        { skip: !profile?.organizationId }
    );

    const [
        verifyDomain,
        { isError: isVerifyDomainError, error: verifyDomainError, isSuccess: isVerifyDomainSuccess }
    ] = useVerifyOrganizationDomainMutation();

    const [
        generateTXT,
        { isError: isGenerateTxtError, error: generateTxtError }
    ] = useGenerateOrganizationTXTMutation();

    const [orgInfo, setOrgInfo] = React.useState({
        domain: '',
        txt: ''
    });

    const [isDomainVerified, setIsDomainVerified] = React.useState(false);

    const onChangeTXT = (e) => {
        setOrgInfo({
            ...orgInfo,
            ...{ txt: e.target.value }
        });
    };

    const onChangeDomain = (e) => {
        setOrgInfo({
            ...orgInfo,
            ...{ domain: e.target.value }
        });
    };

    const onClickBack = () => {
        history.push('/home/profile');
    };

    const onCopyToClipboard = () => {
        const value = orgInfo?.txt;
        if (value) {
            navigator.clipboard
                .writeText(value)
                .then(() => {
                    showToastSuccess('TXT Copied to clipboard');
                })
                .catch((error) => {
                    console.error(error);
                    showToastError('Failed to copy to clipboard');
                });
        } else {
            showToastError('No TXT found for copying');
        }
    };

    const onVerifyDomain = async () => {
        const { organizationId: orgId } = profile;
        await verifyDomain({ orgId });
    };

    const onGenerateTXT = async ({ domain }) => {
        const { organizationId: orgId } = profile;
        const response = await generateTXT({ domain, orgId });
        if (response?.data?.TXT) {
            setOrgInfo({ ...orgInfo, ...{ txt: response.data.TXT } });
        }
    };

    React.useEffect(() => {
        if (orgDetails) {
            const { domain, TXT: txt } = orgDetails;
            setOrgInfo({
                ...orgInfo,
                ...{ domain, txt }
            });
        }
    }, [orgDetails]);

    React.useEffect(() => {
        if (isVerifyDomainSuccess) {
            showToastSuccess('Domain verification successfull.');
            setIsDomainVerified(true);
        }
    }, [isVerifyDomainSuccess]);

    React.useEffect(() => {
        notifyError(isGenerateTxtError, generateTxtError, errors.ORGANIZATION_TXT_GENERATE);
        notifyError(isVerifyDomainError, verifyDomainError);
    }, [isGenerateTxtError, generateTxtError, isVerifyDomainError, verifyDomainError]);

    return (
        <div className="px-2 py-3 my-4 rounded bg-white">
            <div className="container-fluid">
                <BackBtn title="Verfiy Domain" onClick={onClickBack} />
                <div className="row my-3">
                    <div className="col-12">
                        <div className="fw-700 h6">Step 1</div>
                        <p>Enter your domain correctly and click Generate Domain Signature</p>
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <div className="fw-700 h6">Step 2</div>
                        <p>Add this as a txt record to your DNS</p>
                    </div>
                </div>
                <div className="row my-3 px-3">
                    <div className="col-9 border rounded">
                        <div className="container-fluid px-0">
                            <Form
                                initialValues={orgInfo}
                                onSubmit={onGenerateTXT}
                                enableReinitialize>
                                <div className="row my-4">
                                    <div className="col-md-3">Domain</div>
                                    <div className="col-md-5">
                                        <TextField
                                            name="domain"
                                            placeholder="Enter Domain"
                                            onChange={onChangeDomain}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <SubmitButton
                                            title="Generate Domain Signature"
                                            className="btn btn-primary fw-700 w-100"
                                        />
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <div className="col-md-3">TXT</div>
                                    <div className="col-md-8">
                                        <TextField onChange={onChangeTXT} name="txt" />
                                    </div>
                                    <div className="col-md-1 d-flex justify-content-end">
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={() => {
                                                onCopyToClipboard();
                                            }}>
                                            <img src="/assets/vector/mr-clipboard.svg" alt="" />
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12">
                        <div className="fw-700 h6">Step 3</div>
                        <p>Allow some time and click verify</p>
                    </div>
                </div>
                <div className="row my-3 px-3">
                    <div className="col-9 d-flex justify-content-between align-items-center p-3 border rounded">
                        <div>
                            {isDomainVerified && (
                                <div className="d-flex align-items-center">
                                    <img
                                        className="org-user-dashboard-home-profile-icon-lg"
                                        src="/assets/vector/mr-verified.svg"
                                        alt=""
                                    />
                                    <div className="px-2 fw-700 text-primary">Verified</div>
                                </div>
                            )}
                        </div>
                        <button
                            type="button"
                            className="btn btn-primary px-5 fw-700"
                            onClick={onVerifyDomain}>
                            Verify
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VerifyDomain;
