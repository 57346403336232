import React from 'react';
import dayjs from 'dayjs';
import { useHistory, useLocation } from 'react-router';
import { BackBtn } from '../../../components/Buttons';
import Breadcrumbs from '../../../components/Breadcrumbs';
import { Form, Checkbox } from '../../../components/FormElements';
import PaginatedTable from '../../../components/PaginatedTable';
import constants from '../../../utils/constants';
import { getCurrentEnv, normaliseObjectArray, parseDate } from '../../../utils/dataUtils';
import { useGetProfileQuery } from '../../../services/authService';
import {
    useGetActivityQuery,
    useGetRewardeesQuery,
    useShareSelectedRewardAgainMutation
} from '../../../services/activityService';
import notifyError, { errors } from '../../../utils/rtkErrorHelper';

const RewardedActivity = () => {
    const history = useHistory();
    const { state: routerState } = useLocation();

    const [activeFilters, setActiveFilters] = React.useState([]);
    const [activityData, setActivityData] = React.useState({
        name: 'Activity Name',
        start_date: new Date(),
        end_date: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
        is_badge: true,
        is_certificate: true,
        add_group: false,
        add_individuals: false
    });

    const {
        data: profile,
        error: getProfileError,
        isError: isGetProfileError
    } = useGetProfileQuery();

    const {
        data: selectedActivity,
        error: selectedActivityError,
        isError: isSelectedActivityError
    } = useGetActivityQuery(
        {
            orgId: profile?.organizationId,
            activityId: routerState?.activityId
        },
        { skip: !(profile?.organizationId && routerState?.activityId) }
    );

    const {
        data: rewardees,
        isError: isGetRewardeesError,
        error: getRewardeesError
    } = useGetRewardeesQuery(
        {
            orgId: profile?.organizationId,
            activityId: routerState?.activityId
        },
        { skip: !(profile?.organizationId && routerState?.activityId) }
    );

    const [
        shareSelectedActivityReward,
        { isError: isShareSelectedActivityRewardError, error: shareSelectedActivityRewardError }
    ] = useShareSelectedRewardAgainMutation();

    React.useEffect(() => {
        notifyError(isGetProfileError, getProfileError, errors.AUTH_GET_PROFILE);
        notifyError(isSelectedActivityError, selectedActivityError, errors.ACTIVITY_RETRIEVE);
        notifyError(isGetRewardeesError, getRewardeesError, errors.ACTIVITY_REWARDEE_RETRIEVAL);
        notifyError(
            isShareSelectedActivityRewardError,
            shareSelectedActivityRewardError,
            errors.ACTIVITY_REWARDEES_SHARE_REWARD
        );
    }, [
        getProfileError,
        isGetProfileError,
        selectedActivityError,
        isSelectedActivityError,
        isGetRewardeesError,
        getRewardeesError,
        isShareSelectedActivityRewardError,
        shareSelectedActivityRewardError
    ]);

    React.useEffect(() => {
        if (selectedActivity) {
            const { activity, rewardElemets: elements } = selectedActivity;

            let isCertificate = false;
            let isBadge = false;

            if (elements?.length) {
                isBadge = elements.some(
                    (element) => element?.type === constants.ELEMENT_TYPE_BADGE
                );
                isCertificate = elements.some(
                    (element) => element?.type === constants.ELEMENT_TYPE_CERTIFICATE
                );
            }

            setActivityData({
                ...activity,
                ...{
                    start_date: activity?.start_date && dayjs(activity?.start_date).toDate(),
                    end_date: activity?.end_date && dayjs(activity?.end_date).toDate(),
                    is_badge: isBadge,
                    is_certificate: isCertificate
                }
            });
        }
    }, [selectedActivity]);

    React.useEffect(() => {
        if (!routerState?.activityId) {
            history.push('/activities');
        }
    }, [routerState]);

    const filters = [
        { type: constants.FILTER_TYPE_TEXT },
        { type: constants.FILTER_TYPE_TEXT },
        {
            type: constants.FILTER_TYPE_OPTIONS,
            options: [
                { label: 'Reward Generated', value: true },
                { label: 'Not Generated', value: false }
            ]
        },
        {
            type: constants.FILTER_TYPE_OPTIONS,
            options: [
                { label: 'Reward Shared', value: true },
                { label: 'Not Shared', value: false }
            ]
        }
    ];

    const filterColumnRefs = ['name', 'email', 'generated', 'shared'];

    const parsedRewardees = React.useMemo(() => {
        let parsedData = {
            byId: {},
            allIds: []
        };
        if (rewardees?.length) {
            let data = rewardees;
            if (activeFilters?.some((filter) => filter && typeof filter === 'string')) {
                data = rewardees.filter((dataItem) => {
                    const availableFilterQueryList = [];
                    const rowDataValueList = [];
                    activeFilters.forEach((filterValue, filterIdx) => {
                        if (filterValue) {
                            const dataField = dataItem[filterColumnRefs[filterIdx]]
                                ?.toString?.()
                                ?.toLowerCase?.();
                            availableFilterQueryList.push(
                                filterValue?.toString?.()?.toLowerCase?.()
                            );
                            rowDataValueList.push(dataField);
                        }
                    });
                    return availableFilterQueryList.every((query, queryIdx) =>
                        rowDataValueList[queryIdx]?.includes?.(query)
                    );
                });
            }
            parsedData = normaliseObjectArray(data);
        }
        return parsedData;
    }, [rewardees, activeFilters]);

    const renderGeneratedStatus = (rowData) => {
        const { generated } = rowData?.reward;

        if (generated) {
            return (
                <div className="px-3">
                    <img src="/assets/vector/mr-verified.svg" alt="🗸" />
                </div>
            );
        }
        return '';
    };

    const onShareRewardAgain = async ({ id: receiverId, reward }) => {
        const { generated } = reward;
        if (generated) {
            const { organizationId: orgId } = profile;
            const { id: activityId } = selectedActivity?.activity;
            const env = getCurrentEnv();
            await shareSelectedActivityReward({
                orgId,
                activityId,
                receiverId,
                body: { env }
            });
        }
    };

    const renderSharedStatus = (rowData) => {
        const { shared } = rowData?.reward;

        if (shared) {
            return (
                <div className="px-3">
                    <img src="/assets/vector/mr-verified.svg" alt="🗸" />
                </div>
            );
        }
        return '';
    };

    const renderViewAction = (rowData) => {
        const { generated, shared } = rowData;
        if (generated && shared) {
            return (
                <button type="button" className="btn text-primary fw-700">
                    View
                </button>
            );
        }
        return ' ';
    };

    const renderShareAgainAction = (rowData) => {
        const { generated, shared } = rowData?.reward;

        if (generated && shared) {
            return (
                <button
                    onClick={(e) => {
                        e.stopPropagation();
                        onShareRewardAgain(rowData);
                    }}
                    type="button"
                    className="btn text-primary fw-700 p-0">
                    Share Again
                </button>
            );
        }
        return ' ';
    };

    const columns = [
        'name',
        'email',
        renderGeneratedStatus,
        renderSharedStatus,
        renderViewAction,
        renderShareAgainAction
    ];

    const columnNames = ['Name', 'Email', 'Generated', 'Shared'];

    const onSetFilters = (fltrs) => {
        setActiveFilters(fltrs ?? []);
    };

    const onClickBack = () => {
        history.goBack();
    };

    return (
        <div className="container-fluid">
            <Breadcrumbs rootName="Home" hideDashboard />
            <div className="p-4 rounded bg-white">
                <div className="p-3">
                    <BackBtn onClick={onClickBack} />
                </div>
                <Form initialValues={activityData} enableReinitialize>
                    <div className="container-fluid">
                        <div className="row my-3">
                            <div className="col-md-4">
                                <div className="h5 text-primary">Activity Details</div>
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-3 fw-700">Name</div>
                            <div className="col-md-4">{activityData?.name || ''}</div>
                        </div>
                        <div className="row my-5">
                            <div className="col-md-3 fw-700">Starting Date</div>
                            <div className="col-md-4">
                                {parseDate(activityData?.start_date, 'DD/MM/YYYY')}
                            </div>
                        </div>
                        <div className="row my-5">
                            <div className="col-md-3 fw-700">End Date</div>
                            <div className="col-md-4">
                                {parseDate(activityData?.end_date, 'DD/MM/YYYY')}
                            </div>
                        </div>
                        <div className="row my-3">
                            <div className="col-md-3">
                                <div className="h5 text-primary">Reward Elements</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 d-flex align-items-center">
                                <Checkbox name="is_badge" label="Badge" />
                            </div>
                            <div className="col-md-3 d-flex align-items-center">Badge</div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 d-flex align-items-center">
                                <Checkbox name="is_certificate" label="Certificate" />
                            </div>
                            <div className="col-md-3 d-flex align-items-center">Certificate</div>
                        </div>
                        <div className="row my-3">
                            <div className="col-md-3">
                                <div className="h5 text-primary">Reward Receivers</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <PaginatedTable
                                    rounded
                                    columns={columns}
                                    columnNames={columnNames}
                                    data={parsedRewardees.byId}
                                    rows={parsedRewardees.allIds}
                                    filters={filters}
                                    onSetFilters={onSetFilters}
                                    headerActions={[]}
                                />
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default RewardedActivity;
