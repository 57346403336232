import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { PrivateRoute, PublicRoute } from './CustomRoutes';

import SignInPage, {
    RegisterPage,
    LogoutPage,
    ForgotPasswordPage,
    ResetPasswordPage
} from '../pages/AuthPage';
import ModalContainer from '../components/ModalContainer';
import Layout from '../pages/Layout';
import InviteeJoin from '../pages/InviteeJoin';
import IssuerProfile from '../pages/IssuerProfile';
import { getAllUsers } from '../utils/roleUtils';

const AppRoutes = () => {
    const allRoles = getAllUsers();
    return (
        <Router>
            <div className="App">
                <Switch>
                    <PublicRoute restricted component={SignInPage} path="/signin" exact />
                    <PublicRoute
                        restricted
                        component={ForgotPasswordPage}
                        path="/forgot-password"
                        exact
                    />
                    <PublicRoute
                        restricted
                        component={ResetPasswordPage}
                        path="/reset-password"
                        exact
                    />
                    <PublicRoute restricted component={InviteeJoin} path="/invitee-join" exact />
                    <PublicRoute restricted component={RegisterPage} path="/register" exact />
                    <Route component={IssuerProfile} path="/issuer-profile/:issuerId" exact />
                    <Route component={LogoutPage} path="/signout" exact />
                    <PrivateRoute component={Layout} path="/" roles={allRoles} />
                </Switch>
                <ModalContainer />
            </div>
        </Router>
    );
};

export default AppRoutes;
