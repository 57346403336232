import { createApi } from '@rtk-incubator/rtk-query';
import { baseQueryWithAuth } from './baseQueries';

const authService = createApi({
    reducerPath: 'authService',
    baseQuery: baseQueryWithAuth,
    entityTypes: ['Auth'],
    endpoints: (builder) => ({
        getProfile: builder.query({
            query: () => '/auth/me/',
            provides: ['Auth']
        }),
        requestPasswordReset: builder.mutation({
            query: ({ email, env }) => ({
                url: `/auth/password-reset-request/`,
                method: 'POST',
                body: { email, env }
            })
        }),
        passwordReset: builder.mutation({
            query: ({ token, new_password, confirm_password }) => ({
                url: `/auth/password-reset/${token}/`,
                method: 'POST',
                body: { new_password, confirm_password }
            })
        }),
        changeUserPassword: builder.mutation({
            query: ({
                password,
                confirm_password,
                userId,
                currentPassword,
                useCurrentPassword
            }) => ({
                url: `/auth/me/change-password/`,
                method: 'POST',
                body: { password, confirm_password, userId, currentPassword, useCurrentPassword }
            })
        })
    })
});

export default authService;

export const { useGetProfileQuery } = authService;
