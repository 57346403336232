import React from 'react';
import Papa from 'papaparse';
import { useHistory } from 'react-router';
import { BackBtn } from '../../../components/Buttons';
import { downloadURI, getCurrentEnv } from '../../../utils/dataUtils';
import { showToastError, showToastSuccess } from '../../../utils/toastHelper';
import { useGetGroupsQuery, useInviteReceiversMutation } from '../../../services/receiverService';
import { useGetProfileQuery } from '../../../services/authService';
import notifyError, { errors } from '../../../utils/rtkErrorHelper';

const InviteMultiple = () => {
    const history = useHistory();
    const [parsedData, setParsedData] = React.useState([]);
    const [selectedFileName, setSelectedFileName] = React.useState('');
    const [selectedGroup, setSelectedGroup] = React.useState('');

    const { data: profile, isError: isProfileError, error: profileError } = useGetProfileQuery();
    const { data: groups, error: getGroupsError, isError: isGetGroupsError } = useGetGroupsQuery(
        { orgId: profile?.organizationId },
        { skip: !profile?.organizationId }
    );

    const [
        inviteReceivers,
        {
            error: inviteReceiversError,
            isError: isInviteReceiversError,
            isSuccess: isInviteReceiversSuccess
        }
    ] = useInviteReceiversMutation();

    const groupOptions = React.useMemo(() => {
        let options = [];
        if (groups?.length) {
            options = groups.map(({ group }) => ({
                label: group?.name || '',
                value: group?.id || ''
            }));
        }
        return options;
    }, [groups]);

    React.useEffect(() => {
        notifyError(isProfileError, profileError, errors.AUTH_GET_PROFILE);
        notifyError(isInviteReceiversError, inviteReceiversError, errors.RECEIVERS_INVITE);
        notifyError(isGetGroupsError, getGroupsError, errors.GROUPS_RETRIEVE);
    }, [
        isProfileError,
        profileError,
        isInviteReceiversError,
        inviteReceiversError,
        getGroupsError,
        isGetGroupsError
    ]);

    React.useEffect(() => {
        if (isInviteReceiversSuccess) {
            showToastSuccess('Invitations sent.');
        }
    }, [isInviteReceiversSuccess]);

    const onClickBack = () => {
        history.goBack();
    };

    const onClickDownloadTemplate = () => {
        const templateLocation = '/shared/Invite_Multiple_Template.csv';
        const templateName = 'Invite_Multiple_Template.csv';
        downloadURI(templateLocation, templateName);
    };

    const onSelectFile = (e) => {
        const file = e.target.files[0];
        if (file?.size && file?.name) {
            setSelectedFileName(file.name);
            Papa.parse(file, {
                header: true,
                skipEmptyLines: true,
                complete: (results) => {
                    setParsedData(
                        results.data.filter(({ name, email }) => !!(name && email)) || []
                    );
                },
                error: (error) => {
                    showToastError(`File parsing error. ${error}`);
                }
            });
        }
    };

    const onClickUploadFile = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'text/csv';
        input.onchange = onSelectFile;
        input.click();
    };

    const onSendInvites = async () => {
        if (parsedData?.length) {
            const { organizationId: orgId } = profile;
            const groupId = selectedGroup;
            const env = getCurrentEnv();
            await inviteReceivers({ orgId, receivers: parsedData, groupId, env });
        } else {
            showToastError(`Receiver list is empty.`);
        }
    };

    const onChangeGroup = (e) => {
        setSelectedGroup(e.target.value);
    };

    return (
        <div className="container-fluid my-2 p-3 rounded bg-white">
            <BackBtn onClick={onClickBack} />
            <div className="container-fluid">
                <div className="row my-4">
                    <div className="col-lg-3 p-0">
                        <div className="fw-700 my-2">Step 1</div>
                        <div className="my-2">Download This File</div>
                        <button
                            type="button"
                            className="btn btn-primary my-2 py-3 fw-700 w-100"
                            onClick={onClickDownloadTemplate}>
                            Download File
                            <img
                                src="/assets/vector/mr-download-light.svg"
                                alt=""
                                className="d-inline mx-2"
                            />
                        </button>
                    </div>
                </div>
                <div className="row my-4">
                    <div className="col-lg-3 p-0">
                        <div className="fw-700 my-2">Step 2</div>
                        <div className="my-2">Fill the required details to send the invite</div>
                    </div>
                </div>
                <div className="row my-4">
                    <div className="col-lg-3 p-0">
                        <div className="fw-700 my-2">Step 3</div>
                        <div className="my-2">Upload the updated File</div>
                        <button
                            type="button"
                            className="btn btn-primary my-2 py-3 fw-700 w-100"
                            onClick={onClickUploadFile}>
                            Upload File
                            <img
                                src="/assets/vector/mr-upload-light.svg"
                                alt=""
                                className="d-inline mx-2"
                            />
                        </button>
                        {selectedFileName && (
                            <div className="my-1">
                                Selected File:
                                <span> {selectedFileName}</span>
                            </div>
                        )}
                    </div>
                </div>
                <div className="row my-4">
                    <div className="col-lg-3 p-0">
                        <div className="fw-700 my-2">Step 4</div>
                        <div className="my-2">Select Group (Optional)</div>
                        <select onChange={onChangeGroup} className="form-control form-select" id="">
                            <option value="">Add or Select a Group</option>
                            {groupOptions.map(({ label, value }) => (
                                <option key={value} value={value}>
                                    {label}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-md-12 d-flex justify-content-lg-end">
                        <button
                            type="button"
                            className="btn btn-primary px-5 fw-500"
                            onClick={onSendInvites}>
                            Send Invites
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InviteMultiple;
