import React from 'react';
import { useLocation, Link } from 'react-router-dom';

const breadcrumbNameMap = {
    '/organizations/add/new': 'Add Organization',
    '/elements': 'Reward Element',
    '/elements/add/new': 'Add Element',
    '/activities': 'Reward Activities',
    '/activities/add/new': 'New Activity',
    '/activities/edit': 'Edit Activity',
    '/activities/ongoing': 'Ongoing Activity',
    '/activities/rewarded': 'Rewarded Activity',
    '/receivers': 'Reward Receivers',
    '/receivers/invite-multiple': 'Invite Multiple',
    '/receivers/groups': 'Groups',
    '/home': 'Home',
    '/home/users': 'Users',
    '/home/users/edit': 'Edit User',
    '/home/users/add/new': 'Add User',
    '/organizations': 'Organizations',
    '/users': 'Users',
    '/users/addUser': 'Add User',
    '/users/editUser': 'Edit User',
    '/issued': 'Issued Activities'
};

const Breadcrumbs = ({ hideDashboard, rootName }) => {
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter((x) => x);
    return (
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb fw-700">
                {!hideDashboard && (
                    <li className="breadcrumb-item">
                        <Link to="/" className="text-dark">
                            {rootName || 'Dashboard'}
                        </Link>
                    </li>
                )}
                {pathnames.map((value, index) => {
                    const last = index === pathnames.length - 1;
                    const to = `/${pathnames.slice(0, index + 1).join('/')}`;
                    if (breadcrumbNameMap[to]) {
                        return last ? (
                            <li key={to} className="breadcrumb-item" aria-current="page">
                                {breadcrumbNameMap[to] ?? value}
                            </li>
                        ) : (
                            <li key={to} className="breadcrumb-item">
                                <Link to={to} className="text-dark">
                                    {breadcrumbNameMap[to]}
                                </Link>
                            </li>
                        );
                    }
                    if (!breadcrumbNameMap[to] && last) {
                        return (
                            <li key={to} className="breadcrumb-item">
                                {value}
                            </li>
                        );
                    }
                })}
            </ol>
        </nav>
    );
};

export default Breadcrumbs;
