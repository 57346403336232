import { fetchBaseQuery } from '@rtk-incubator/rtk-query';
import { ACCESS_TOKEN_NAME } from '../config';

export const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE,
    prepareHeaders: (headers) => {
        const token = sessionStorage.getItem(ACCESS_TOKEN_NAME);
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    }
});

export const baseQueryWithAuth = async (args, api) => {
    try {
        const result = await baseQuery(args, api);
        if (result.error && result.error.status !== '401') {
            return { error: { status: result.error?.status, data: result.error?.data } };
        }
        return result;
    } catch (error) {
        // continue
    }
};

export const baseQueryWithAutoLogout = (args, api) => {
    const result = baseQuery(args, api);
    if (result.error && result.error.status !== '401') {
        // redirection to signout
    } else {
        // rejection error
    }
};

export const zedeidBaseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_ZEDEID_DID_BASE
});
