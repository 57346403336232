import { createApi } from '@rtk-incubator/rtk-query';
import { zedeidBaseQuery } from './baseQueries';

const zedeidService = createApi({
    reducerPath: 'zedeidService',
    baseQuery: zedeidBaseQuery,
    endpoints: (builder) => ({
        getHolderPublicKey: builder.query({
            query: ({ receiverDID }) => `/did/${receiverDID}/`
        })
    })
});

export default zedeidService;

export const { useGetHolderPublicKeyQuery } = zedeidService;
