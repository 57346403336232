import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Breadcrumbs from '../../../components/Breadcrumbs';
import Tabs from '../../../components/Tabs';

import ReceiversAll from './ReceiversAll';
import ReceiversGroup from './ReceiversGroups';
import GroupDetails from './GroupDetails';
import InviteMultiple from './InviteMultiple';
import GroupsAdd from './GroupsAdd';

const Receivers = () => {
    const { url, path } = useRouteMatch();

    const tabs = [
        {
            label: 'All',
            route: `${url}`
        },
        {
            label: 'Groups',
            route: `${url}/groups`,
            includeRoutes: [`${url}/groups/add`]
        }
    ];
    return (
        <div className="container-fluid px-0 py-3">
            <Switch>
                <Route path={`${path}/`} exact>
                    <Tabs items={tabs} />
                    <ReceiversAll />
                </Route>
                <Route path={`${path}/groups`} exact>
                    <Tabs items={tabs} />
                    <ReceiversGroup />
                </Route>
                <Route path={`${path}/groups/add`} exact>
                    <Tabs items={tabs} />
                    <GroupsAdd />
                </Route>
                <Route path={`${path}/groups/:groupName`} exact>
                    <Breadcrumbs rootName="home" hideDashboard />
                    <GroupDetails />
                </Route>
                <Route path={`${path}/invite-multiple`} exact>
                    <Breadcrumbs rootName="home" hideDashboard />
                    <InviteMultiple />
                </Route>
            </Switch>
        </div>
    );
};

export default Receivers;
