import React from 'react';
import { useHistory, useLocation } from 'react-router';
import './Tabs.styles.scss';

const Tabs = ({ items, onTabChange }) => {
    const history = useHistory();
    const { pathname } = useLocation();

    const onClickTab = (idx, item) => {
        if (item?.route) {
            history.push(item.route);
        }
        if (onTabChange) {
            onTabChange(idx, item);
        }
    };

    const getBorders = (idx) => {
        const length = items?.length;
        let border = '';
        if (length === 1) {
            border = 'rounded';
        }
        if (length > 1) {
            if (idx === 0) {
                border = 'rounded-left';
            }
            if (idx === length - 1) {
                border = 'rounded-right';
            }
            if (idx > 0 && idx < length - 1) {
                border = 'right-border left-border';
            }
        }
        if (length === 2) {
            if (idx === 0) {
                border = 'right-border';
            }
        }
        return border;
    };

    const hasMatchingIncludeRoutes = (item) => {
        let flag = false;
        if (item?.includeRoutes?.length) {
            const { includeRoutes } = item;
            flag = includeRoutes.includes(pathname);
        }
        return flag;
    };

    return (
        <div className="tabs-root rounded">
            {items.map((item, itemIdx) => (
                <div key={item.route} className={`tab-item-wrapper ${getBorders(itemIdx)}`}>
                    <div
                        aria-hidden="true"
                        className={`tab-item ${
                            pathname === item.route || hasMatchingIncludeRoutes(item)
                                ? 'active'
                                : ''
                        }`}
                        onClick={() => {
                            onClickTab(itemIdx, item);
                        }}>
                        <div className="label-wrapper">{item.label}</div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Tabs;
