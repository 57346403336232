export default {
    ROLE_SUPER_USER: 'SUPER_ADMIN',
    ROLE_ORG_ADMIN: 'ORG_ADMIN',
    ROLE_ORG_USER: 'ORG_USER',
    // Paginated table filter types
    FILTER_TYPE_TEXT: 'FILTER_TYPE_TEXT',
    FILTER_TYPE_OPTIONS: 'FILTER_TYPE_OPTIONS',
    FILTER_TYPE_DATE: 'FILTER_TYPE_DATE',
    // User states
    USER_INVITED: 'INVITED',
    USER_ACTIVE: 'ACTIVE',
    USER_DISABLED: 'DISABLED',
    // Element types
    ELEMENT_TYPE_CERTIFICATE: 'CERTIFICATE',
    ELEMENT_TYPE_BADGE: 'BADGE',
    // Rewardee States
    REWARDEE_TO_BE_INVITED: 'INVITE',
    REWARDEE_INVITED: 'INVITED',
    REWARDEE_REGISTERED: 'REGISTERED',
    // Activity States
    ACTIVITY_REWARDED: 'REWARDED',
    ACTIVITY_ON_GOING: 'ON_GOING',
    ACTIVITY_DESIGN: 'DESIGN',
    // APP environments
    ENV_PROD: 'PROD',
    ENV_STG: 'STG',
    ENV_DEV: 'DEV'
};
