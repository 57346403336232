import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import Divider from '../../components/Divider';
import EditableLogo from '../../components/EditableLogo';
import { useGetProfileQuery } from '../../services/authService';
import constants from '../../utils/constants';

import { useGetOrganizationQuery } from '../../services/orgService';

const Profile = () => {
    const { data: profile } = useGetProfileQuery();
    const { data: orgDetails } = useGetOrganizationQuery(
        { orgId: profile?.organizationId },
        { skip: !profile?.organizationId }
    );

    const adminInfo = React.useMemo(() => {
        if (orgDetails?.users) {
            const { users } = orgDetails;
            const admin = users.find((user) => user.role === constants.ROLE_ORG_ADMIN);
            if (admin) {
                return admin;
            }
        }
        return {};
    }, [orgDetails]);

    const history = useHistory();
    const { path, url } = useRouteMatch();

    const onProfileEdit = () => {
        history.push(`${path}/edit`);
    };

    return (
        <div className="container-fluid bg-white mt-4 rounded">
            <div className="row">
                <div className="col-lg-8">
                    <div className="container-fluid p-4">
                        <div className="row my-3">
                            <div className="col-md-2 fw-500">
                                <EditableLogo
                                    logo={
                                        orgDetails?.logo || '/assets/img/mr-badge-placeholder.png'
                                    }
                                />
                            </div>
                            <div className="col-md-10">
                                <div className="fw-700">{orgDetails?.name || ''}</div>
                                <div className="pt-3">{orgDetails?.description || ''}</div>
                            </div>
                        </div>
                        <Divider />
                        <div className="row my-3">
                            <div className="col-md-2 fw-500">Domain</div>
                            <div className="col-md-10">
                                <div className="d-flex">
                                    {orgDetails?.domain || ''}
                                    {orgDetails?.is_verified && (
                                        <div className="px-3">
                                            <img src="/assets/vector/mr-verified.svg" alt="" />
                                            <small className="px-2 text-primary fw-700">
                                                Verified
                                            </small>
                                        </div>
                                    )}
                                </div>
                                <Link
                                    to={orgDetails?.domain ? `${url}/verify-domain` : '#'}
                                    className="pt-3 text-primary">
                                    <small className="fw-700">Verify Domain</small>
                                </Link>
                            </div>
                        </div>
                        <Divider />
                        <div className="row my-3">
                            <div className="col-md-2 fw-500">Org Admin</div>
                            <div className="col-md-10">
                                <div>{`${adminInfo?.first_name || ''} ${
                                    adminInfo?.last_name || ''
                                }`}</div>
                                <div>{`${adminInfo?.email || ''}`}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="container-fluid p-4 d-flex justify-content-lg-end">
                        <button
                            type="button"
                            className="org-user-profile-action-btn"
                            onClick={onProfileEdit}>
                            <img src="/assets/vector/mr-btn-update.svg" alt="Edit" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profile;
