import React from 'react';
import dayjs from 'dayjs';
import { useHistory, useParams } from 'react-router';

import { useGetProfileQuery } from '../../../services/authService';
import { useGetElementQuery, useUpdateElementMutation } from '../../../services/elementsService';
import constants from '../../../utils/constants';
import ElementBadge from './ElementBadge';
import ElementCertificate from './ElementCertificate';
import { b64ToFile, fileToB64 } from '../../../utils/fileUtils';
import { showToastError } from '../../../utils/toastHelper';
import notifyError, { errors } from '../../../utils/rtkErrorHelper';

const UpdateElement = () => {
    const history = useHistory();
    const { elementId } = useParams();

    const {
        data: profile,
        error: getProfileError,
        isError: isGetProfileError
    } = useGetProfileQuery();
    const {
        data: element,
        error: getElementError,
        isError: isGetElementError
    } = useGetElementQuery(
        {
            elementId,
            orgId: profile?.organizationId
        },
        {
            skip: !profile?.organizationId
        }
    );
    const [
        updateElement,
        { isError: isUpdateElementError, error: updateElementError }
    ] = useUpdateElementMutation({
        orgId: profile?.organizationId
    });

    React.useEffect(() => {
        notifyError(isUpdateElementError, updateElementError, errors.ELEMENT_UPDATE);
        notifyError(isGetProfileError, getProfileError, errors.AUTH_GET_PROFILE);
        notifyError(isUpdateElementError, updateElementError, errors.ELEMENT_UPDATE);
    }, [
        isUpdateElementError,
        updateElementError,
        getProfileError,
        isGetProfileError,
        getElementError,
        isGetElementError
    ]);

    const parsedElement = React.useMemo(() => {
        if (element) {
            const { type } = element;
            let parsed = element;
            if (type === constants.ELEMENT_TYPE_CERTIFICATE) {
                const certificate_logo = b64ToFile(element?.certificate_logo, 'certificate_logo');
                const signature_one = b64ToFile(element?.signature_one, 'signature_one');
                const signature_two = b64ToFile(element?.signature_two, 'signature_two');
                parsed = {
                    ...element,
                    ...{
                        certificate_logo,
                        signature_one,
                        signature_two
                    }
                };
            }
            if (type === constants.ELEMENT_TYPE_BADGE) {
                const badge = b64ToFile(element?.badge, 'badge');
                const issuer_image = b64ToFile(element?.issuer_image, 'issuer_image');
                const receiver_image = b64ToFile(element?.receiver_image, 'receiver_image');
                parsed = {
                    ...element,
                    ...{
                        badge,
                        issuer_image,
                        receiver_image
                    }
                };
            }
            return parsed;
        }
        return {};
    }, [element]);

    const onSaveBadgeData = async (values) => {
        try {
            const badge = await fileToB64(values?.badge);
            const issuer_image = await fileToB64(values?.issuer_image);
            const receiver_image = await fileToB64(values?.receiver_image);
            const badgeData = {
                ...values,
                ...{
                    badge,
                    issuer_image,
                    receiver_image
                }
            };
            await updateElement({
                elementId,
                element: badgeData,
                orgId: profile?.organizationId
            });
            history.goBack();
        } catch (err) {
            showToastError(
                `Error occurred while trying to update badge data. ${err?.toString() || ''}`
            );
        }
    };

    const onSaveCertificateData = async (values) => {
        try {
            const certificate_logo = await fileToB64(values?.certificate_logo);
            const signature_one = await fileToB64(values?.signature_one);
            const signature_two = await fileToB64(values?.signature_two);
            const certificate = {
                ...values,
                ...{
                    certificate_logo,
                    signature_one,
                    signature_two,
                    date: dayjs(values.date).toISOString()
                }
            };
            await updateElement({
                elementId,
                element: certificate,
                orgId: profile?.organizationId
            });
            history.goBack();
        } catch (err) {
            showToastError(
                `Error occurred while trying to update certificate data. ${err?.toString() || ''}`
            );
        }
    };

    const onCancel = () => {
        history.goBack();
    };

    return (
        <>
            {element?.type === constants.ELEMENT_TYPE_BADGE && (
                <ElementBadge badge={parsedElement} onSave={onSaveBadgeData} onCancel={onCancel} />
            )}
            {element?.type === constants.ELEMENT_TYPE_CERTIFICATE && (
                <ElementCertificate
                    organizationId={profile?.organizationId}
                    certificate={parsedElement}
                    onSave={onSaveCertificateData}
                    onCancel={onCancel}
                />
            )}
        </>
    );
};

export default UpdateElement;
