import React from 'react';
import { useGetProfileQuery } from '../../../services/authService';
import { useGetTemplateElementsQuery } from '../../../services/elementsService';
import notifyError, { errors } from '../../../utils/rtkErrorHelper';

const TemplatesDropdown = ({ type, onSelect }) => {
    const {
        data: profile,
        error: getProfileError,
        isError: isGetProfileError
    } = useGetProfileQuery();

    const {
        data: templateElements,
        error: templateElementsRetreiveError,
        isError: isTemplateElementsRetreiveError
    } = useGetTemplateElementsQuery(
        { orgId: profile?.organizationId },
        { skip: !profile?.organizationId }
    );

    React.useEffect(() => {
        notifyError(isGetProfileError, getProfileError, errors.AUTH_GET_PROFILE);
        notifyError(
            isTemplateElementsRetreiveError,
            templateElementsRetreiveError,
            errors.ELEMENTS_TEMPLATE_RETRIEVE
        );
    }, [
        getProfileError,
        isGetProfileError,
        templateElementsRetreiveError,
        isTemplateElementsRetreiveError
    ]);

    const parsedElements = React.useMemo(() => {
        if (templateElements?.length) {
            return templateElements.filter((element) => element.type === type && !!element.status);
        }
        return [];
    }, [templateElements, type]);

    const onSelectTemplate = (e) => {
        const templateId = Number(e.target.value);
        const template = parsedElements?.find(({ id }) => id === templateId);
        if (template && onSelect) {
            onSelect(template);
        }
    };

    return (
        <select className="form-control form-control-sm form-select" onChange={onSelectTemplate}>
            <option value="">Select Template</option>
            {parsedElements.map(({ id, name }) => (
                <option key={id} value={id}>
                    {name || `${type} ${id}`}
                </option>
            ))}
        </select>
    );
};

export default TemplatesDropdown;
