import constants from './constants';

export const getAllUsers = () => [
    constants.ROLE_ORG_USER,
    constants.ROLE_SUPER_USER,
    constants.ROLE_ORG_ADMIN
];

export const getOrgRoles = () => [constants.ROLE_ORG_ADMIN, constants.ROLE_ORG_USER];

export const getOrgUserRole = () => [constants.ROLE_ORG_USER];

export const getOrgAdminUserRole = () => [constants.ROLE_ORG_ADMIN];

export const getSuperUserRole = () => [constants.ROLE_SUPER_USER];
