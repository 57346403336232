import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { modalTypes } from '../../../components/ModalContainer';
import PaginatedTable from '../../../components/PaginatedTable';
import Switch from '../../../components/Switch';
import { modalActions } from '../../../state/modal';
import constants from '../../../utils/constants';
import { getDisplayValueForRewardeeState, normaliseObjectArray } from '../../../utils/dataUtils';

import { useGetProfileQuery } from '../../../services/authService';
import { useGetGroupQuery } from '../../../services/receiverService';

const GroupDetails = () => {
    const history = useHistory();
    const { state } = useLocation();
    const dispatch = useDispatch();

    const { data: profile } = useGetProfileQuery();

    const { data: group } = useGetGroupQuery(
        {
            groupId: state?.groupId,
            orgId: profile?.organizationId
        },
        {
            skip: !(profile?.organizationId && state?.groupId)
        }
    );

    const [activeFilters, setActiveFilters] = React.useState([]);

    React.useEffect(() => {
        if (!state?.groupId) {
            history.push('/receivers/groups');
        }
    }, []);

    const onChangeRewardeeStatus = (rowData, val) => {};

    const renderActiveStatus = () => {
        const labelText = getDisplayValueForRewardeeState(constants.REWARDEE_REGISTERED);
        return (
            <div className="d-flex align-items-center">
                <img src="/assets/vector/mr-verified.svg" alt="" />
                <div className="px-2">{labelText}</div>
            </div>
        );
    };

    const renderInvitedStatus = () => (
        <div className="d-flex align-items-center">
            <img src="/assets/vector/mr-clock.svg" alt="" />
            <div className="px-2">Invited</div>
        </div>
    );

    const renderRewardeeStatus = (rowData) => {
        const { status } = rowData;
        switch (status) {
            case constants.REWARDEE_REGISTERED:
                return renderActiveStatus(rowData);
            case constants.REWARDEE_INVITED:
                return renderInvitedStatus();
            default:
                return '';
        }
    };

    const columns = ['name', renderRewardeeStatus, 'email'];

    const columnNames = ['Name', 'Status', 'Email'];

    const filters = [
        { type: constants.FILTER_TYPE_TEXT },
        {
            type: constants.FILTER_TYPE_OPTIONS,
            options: [
                { label: 'Active', value: 'Active' },
                { label: 'Invited', value: 'Invited' }
            ]
        },
        { type: constants.FILTER_TYPE_TEXT }
    ];

    const onDeleteRewardee = (rowData) => {};

    const renderDeleteIcon = () => <img src="/assets/vector/mr-btn-close.svg" alt="" />;

    const actions = [
        {
            name: renderDeleteIcon(),
            action: onDeleteRewardee,
            isIcon: true
        }
    ];

    const filterColumnRefs = ['name', 'status', 'email'];

    const parsedRewardees = React.useMemo(() => {
        let parsedData = {
            byId: {},
            allIds: []
        };
        if (group?.receivers?.length) {
            const { receivers } = group;
            let data = receivers;
            if (activeFilters?.some((filter) => filter && typeof filter === 'string')) {
                data = receivers.filter((dataItem) => {
                    const availableFilterQueryList = [];
                    const rowDataValueList = [];
                    activeFilters.forEach((filterValue, filterIdx) => {
                        if (filterValue) {
                            const dataField = dataItem[filterColumnRefs[filterIdx]]
                                ?.toString?.()
                                ?.toLowerCase?.();
                            availableFilterQueryList.push(
                                filterValue?.toString?.()?.toLowerCase?.()
                            );
                            rowDataValueList.push(dataField);
                        }
                    });
                    return availableFilterQueryList.every((query, queryIdx) =>
                        rowDataValueList[queryIdx]?.includes?.(query)
                    );
                });
            }
            parsedData = normaliseObjectArray(data);
        }
        return parsedData;
    }, [group, activeFilters]);

    const onSetFilters = (fltrs) => {
        setActiveFilters(fltrs ?? []);
    };

    const onClickInviteOne = () => {
        dispatch(
            modalActions.showModal({
                modalType: modalTypes.INVITE_RECEIVER_MODAL
            })
        );
    };

    const onClickInviteMultiple = () => {
        history.push('/receivers/invite-multiple');
    };

    const headerActions = () => (
        <div className="d-flex justify-content-end">
            <button type="button" className="btn btn-primary mx-2 w-100" onClick={onClickInviteOne}>
                Invite
            </button>
            <button
                type="button"
                className="btn btn-primary mx-2 w-100"
                onClick={onClickInviteMultiple}>
                Invite multiple
            </button>
        </div>
    );

    return (
        <div className="container-fluid py-4">
            <div className="row">
                <div className="col-12 p-3 bg-white rounded">
                    <PaginatedTable
                        rounded
                        columns={columns}
                        columnNames={columnNames}
                        headerActions={headerActions()}
                        actions={actions}
                        rows={parsedRewardees.allIds}
                        data={parsedRewardees.byId}
                        filters={filters}
                        onSetFilters={onSetFilters}
                    />
                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn btn-primary px-5 mt-2 mx-3">
                            Add from the Pool
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GroupDetails;
