import React from 'react';
import './EditableLogo.styles.scss';

const EditableLogo = ({
    logo = '',
    editable = false,
    deletable = false,
    onSelectImage,
    onDeleteImage,
    accept,
    fullSize = false
}) => {
    const onUploadImage = (e) => {
        e.stopPropagation();
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = accept;
        input.onchange = onSelectImage;
        input.click();
    };

    return (
        <div className={`logo-root ${fullSize ? 'full-size' : ''}`}>
            {logo && <img src={logo} alt="" className="logo-container" />}

            <div className="action-btn-container">
                {deletable && (
                    <button
                        type="button"
                        onClick={onDeleteImage}
                        className={`deletable-btn ${!logo ? 'd-none' : ''}`}>
                        -
                    </button>
                )}
                {editable && (
                    <button
                        type="button"
                        onClick={onUploadImage}
                        className={`editable-btn ${logo ? 'has-image' : ''}`}>
                        +
                    </button>
                )}
            </div>
        </div>
    );
};

export default EditableLogo;
