import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import Breadcrumbs from '../../../components/Breadcrumbs';
import PaginatedTable from '../../../components/PaginatedTable';
import Switch from '../../../components/Switch';
import constants from '../../../utils/constants';
import { getDisplayValueForElementType, normaliseObjectArray } from '../../../utils/dataUtils';

import { useGetProfileQuery } from '../../../services/authService';
import {
    useGetElementsQuery,
    useUpdateElementMutation,
    useDeleteElementMutation
} from '../../../services/elementsService';
import notifyError, { errors } from '../../../utils/rtkErrorHelper';
import Prompt from '../../../components/Prompt';

const columnNames = ['', 'Element Name', 'Type', 'Status'];

const filters = [
    {},
    {
        type: constants.FILTER_TYPE_TEXT
    },
    {
        type: constants.FILTER_TYPE_OPTIONS,
        options: [
            { label: 'Certificate', value: constants.ELEMENT_TYPE_CERTIFICATE },
            { label: 'Badge', value: constants.ELEMENT_TYPE_BADGE }
        ]
    },
    {
        type: constants.FILTER_TYPE_OPTIONS,
        options: [
            { label: 'Active', value: true },
            { label: 'Inactive', value: false }
        ]
    }
];

const Elements = () => {
    const {
        data: profile,
        error: getProfileError,
        isError: isGetProfileError
    } = useGetProfileQuery();
    const {
        data: elements,
        error: getElementsError,
        isError: isGetElementsError
    } = useGetElementsQuery(
        {
            orgId: profile?.organizationId
        },
        {
            skip: !profile?.organizationId
        }
    );
    const [
        updateElement,
        { isError: isUpdateElementError, error: updateElementError }
    ] = useUpdateElementMutation();

    const [
        deleteElement,
        { isError: isDeleteElementError, error: deleteElementError }
    ] = useDeleteElementMutation();

    const history = useHistory();
    const { path } = useRouteMatch();

    const [activeFilters, setActiveFilters] = React.useState([]);
    const [showDeletePrompt, setShowDeletePrompt] = React.useState(false);
    const [selectedElement, setSelectedElement] = React.useState(null);

    React.useEffect(() => {
        notifyError(isGetProfileError, getProfileError, errors.AUTH_GET_PROFILE);
        notifyError(isGetElementsError, getElementsError, errors.ELEMENTS_RETRIEVE);
        notifyError(isUpdateElementError, updateElementError, errors.ELEMENT_UPDATE);
        notifyError(isDeleteElementError, deleteElementError, errors.ELEMENT_DELETE);
    }, [
        getProfileError,
        isGetProfileError,
        getElementsError,
        isGetElementsError,
        isUpdateElementError,
        updateElementError,
        isDeleteElementError,
        deleteElementError
    ]);

    const onChangeSwitch = async (rowData, status) => {
        const orgId = profile?.organizationId;
        const { id: elementId } = rowData;
        const element = {
            ...rowData,
            ...{ status }
        };
        await updateElement({ orgId, element, elementId });
    };

    const renderElementImage = ({ type, certificate_logo: certificateLogo, badge }) => {
        let image = '';
        const badgePlaceholder = '/assets/vector/mr-badge.svg';
        const certificatePlaceholder = '/assets/vector/mr-certificate.svg';

        if (type === constants.ELEMENT_TYPE_BADGE) {
            image = badgePlaceholder;
            if (badge) {
                image = badge;
            }
        }
        if (type === constants.ELEMENT_TYPE_CERTIFICATE) {
            image = certificatePlaceholder;
            if (certificateLogo) {
                image = certificateLogo;
            }
        }
        if (image) {
            return <img src={image} className="org-user-elements-img" alt="Element" />;
        }
        return ' ';
    };

    const renderActiveStatus = (rowData) => {
        const labelText = rowData?.status ? 'Active' : 'Inactive';
        return (
            <div>
                <Switch
                    value={!!rowData?.status}
                    onChange={(val) => {
                        onChangeSwitch(rowData, val);
                    }}
                    label={labelText}
                />
            </div>
        );
    };

    const renderUpdateActionBtn = () => <img src="/assets/vector/mr-btn-update.svg" alt="Update" />;

    const renderDeleteActionBtn = () => <img src="/assets/vector/mr-btn-delete.svg" alt="Delete" />;

    const renderDisplayValueForElementType = ({ type }) => getDisplayValueForElementType(type);

    const renderIsTemplate = ({ is_template: isTemplate }) =>
        isTemplate && (
            <div className="h-50 d-flex justify-content-center">
                <img src="/assets/vector/mr-verified.svg" alt="Template" />
            </div>
        );

    const onUpdateRow = ({ id }) => {
        history.push(`/elements/${id}`);
    };

    const showDeleteConfirmation = (element) => {
        setShowDeletePrompt(true);
        setSelectedElement(element);
    };

    const onDeleteRow = async (password) => {
        const { id: elementId } = selectedElement;
        if (password) {
            const { organizationId: orgId } = profile;
            await deleteElement({
                orgId,
                elementId,
                password
            });
            setSelectedElement(null);
        }
    };

    const columns = [
        renderElementImage,
        'name',
        renderDisplayValueForElementType,
        renderActiveStatus
    ];

    const actions = [
        { name: renderUpdateActionBtn(), action: onUpdateRow, isIcon: true },
        {
            name: renderDeleteActionBtn(),
            action: showDeleteConfirmation,
            isIcon: true
        }
    ];

    const filterColumnRefs = ['', 'name', 'type', 'status'];

    const parsedElements = React.useMemo(() => {
        let parsedData = {
            byId: {},
            allIds: []
        };
        let templateElements = [];
        if (elements?.length) {
            templateElements = elements.filter(({ is_template: isTemplate }) => isTemplate);
        }
        if (templateElements?.length) {
            let data = templateElements;
            if (activeFilters?.some((filter) => filter && typeof filter === 'string')) {
                data = templateElements.filter((dataItem) => {
                    const availableFilterQueryList = [];
                    const rowDataValueList = [];
                    activeFilters.forEach((filterValue, filterIdx) => {
                        if (filterValue) {
                            const dataField = dataItem[filterColumnRefs[filterIdx]]
                                ?.toString?.()
                                ?.toLowerCase?.();
                            availableFilterQueryList.push(
                                filterValue?.toString?.()?.toLowerCase?.()
                            );
                            rowDataValueList.push(dataField);
                        }
                    });
                    return availableFilterQueryList.every((query, queryIdx) =>
                        rowDataValueList[queryIdx]?.includes?.(query)
                    );
                });
            }
            parsedData = normaliseObjectArray(data, true);
        }
        return parsedData;
    }, [elements, activeFilters]);

    const onSetFilters = (fltrs) => {
        setActiveFilters(fltrs ?? []);
    };

    const onClickAddElement = () => {
        history.push(`${path}/add/new`);
    };

    const onCloseDeletePrompt = () => {
        setShowDeletePrompt(false);
    };

    return (
        <div>
            <Breadcrumbs rootName="Home" hideDashboard />
            <div className="my-2">
                <div className="d-flex justify-content-end mb-4">
                    <button type="button" className="add-btn" onClick={onClickAddElement}>
                        ADD
                    </button>
                </div>
                <PaginatedTable
                    rounded
                    columns={columns}
                    columnNames={columnNames}
                    actions={actions}
                    headerActions={[]}
                    rows={parsedElements.allIds}
                    data={parsedElements.byId}
                    filters={filters}
                    onSetFilters={onSetFilters}
                    pagination={parsedElements?.allIds?.length > 4}
                    pageSize={4}
                />
            </div>
            <Prompt
                show={showDeletePrompt}
                onClose={onCloseDeletePrompt}
                onDone={onDeleteRow}
                title="Password Confirmation"
                message="Confirm deletion. Please enter your password."
                inputProps={{
                    type: 'password',
                    placeholder: 'Password'
                }}
            />
        </div>
    );
};

export default Elements;
