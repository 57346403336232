import React from 'react';
import * as yup from 'yup';
import $ from 'jquery';
import { Form, TextField, SubmitButton } from '../FormElements';
import './Prompt.styles.scss';

const validationSchema = yup.object().shape({
    promptText: yup.string().required('Password is required')
});

const Prompt = ({ show = false, onClose, onDone, title = '', message = '', inputProps }) => {
    const [formdata, setFormData] = React.useState({
        promptText: ''
    });
    const promptContainerRef = React.createRef();

    React.useEffect(() => {
        if (show) {
            $(promptContainerRef.current).removeClass('hidden').fadeIn(250);
        } else {
            $(promptContainerRef.current).fadeOut(250);
        }
    }, [show]);

    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    };

    const handleDone = ({ promptText }, { setSubmitting }) => {
        if (onDone) {
            onDone(promptText);
            setFormData({
                promptText: ''
            });
        }
        handleClose();
        setSubmitting(false);
    };

    return (
        <Form
            onSubmit={handleDone}
            initialValues={formdata}
            validationSchema={validationSchema}
            enableReinitialize>
            <div ref={promptContainerRef} className="prompt-container hidden">
                <div className="prompt">
                    {title && <div className="prompt-title">{title}</div>}
                    {message && <div className="prompt-message">{message}</div>}
                    <TextField {...inputProps} name="promptText" />
                    <div className="prompt-actions mt-4">
                        <SubmitButton
                            title="OK"
                            type="submit"
                            className="prompt-action-btn btn btn-primary"
                        />
                        <button
                            type="button"
                            className="prompt-action-btn btn btn-outline-secondary"
                            onClick={handleClose}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </Form>
    );
};

export default Prompt;
