import React from 'react';

export default function SuperUserDashboard() {
    return (
        <div>
            <div className="container-fluid">
                <div className="h2">Dashboard</div>
            </div>
        </div>
    );
}
