import React from 'react';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import { Form, SubmitButton, TextField } from '../../../components/FormElements';

import { useGetProfileQuery } from '../../../services/authService';
import { useAddGroupMutation } from '../../../services/receiverService';

import notifyError, { errors } from '../../../utils/rtkErrorHelper';

const groupDetailsValidationSchema = yup.object().shape({
    name: yup.string().required('Enter group name')
});

const ReceiversAdd = () => {
    const history = useHistory();
    const {
        data: profile,
        error: getProfileError,
        isError: isGetProfileError
    } = useGetProfileQuery();
    const [addGroup, { isError: isAddGroupError, error: addGroupError }] = useAddGroupMutation();

    React.useEffect(() => {
        notifyError(isGetProfileError, getProfileError, errors.AUTH_GET_PROFILE);
        notifyError(isAddGroupError, addGroupError, errors.GROUP_ADD);
    }, [getProfileError, isGetProfileError, isAddGroupError, addGroupError]);

    const [groupDetails, setGroupDetails] = React.useState({
        name: ''
    });

    const onAddGroup = async (group) => {
        const { organizationId: orgId } = profile;
        await addGroup({ group, orgId });
        history.push('/receivers/groups');
    };

    return (
        <div className="container-fluid py-5">
            <Form
                initialValues={groupDetails}
                onSubmit={onAddGroup}
                validationSchema={groupDetailsValidationSchema}
                enableReinitialize>
                <div className="row my-3">
                    <div className="col-md-4">
                        <TextField name="name" label="Group name" />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-md-4">
                        <SubmitButton title="Add Group" />
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default ReceiversAdd;
