import React from 'react';
import Template1 from './Template1';
import Template2 from './Template2';
import Template3 from './Template3';
import Template4 from './Template4';
import './TemplateCatalog.styles.scss';

export const templates = [
    { name: 'Default Arrangement', thumbnail: '/assets/vector/mr-badge.svg', template: Template1 },
    {
        name: 'Certificate of Mastery',
        thumbnail: '/assets/certificate/template-2/mr-template-2-logo-icon.png',
        template: Template2
    },
    {
        name: 'Certificate of Mastery 2',
        thumbnail: '/assets/certificate/template-3/mr-template-3-logo-icon.png',
        template: Template3
    },
    {
        name: 'Certificate of Mind Bait',
        thumbnail: '/assets/certificate/template-4/mr-template-4-logo-icon.png',
        template: Template4
    }
];

export const TemplateCatalog = ({
    organizationId,
    certificate,
    templateName = '',
    onChangeTemplate
}) => {
    var orgCertificate = {
        1: [1, 2],
        2: [3]
    };

    if (process.env.NODE_ENV === 'production') {
        orgCertificate = {
            1: [1, 2],
            4: [3]
        };
    }

    const [orgCertificateList, setOrgCertificateList] = React.useState([]);
    const [selectedTemplateIdx, setSelectedTemplateIdx] = React.useState(0);
    const [certificateData, setCertificateData] = React.useState(certificate);

    const onClickTemplate = (idx) => {
        setSelectedTemplateIdx(idx);
        if (onChangeTemplate) {
            onChangeTemplate(templates[idx]?.name || '');
        }
    };

    React.useEffect(() => {
        setCertificateData(certificate);
    }, [certificate]);

    React.useEffect(() => {
        setOrgCertificateList(
            Array.isArray(orgCertificate[organizationId]) ? orgCertificate[organizationId] : []
        );
    }, [organizationId]);

    React.useEffect(() => {
        const selectedTemplate = templates.find(({ name }) => name === templateName);
        if (templateName && selectedTemplate) {
            setSelectedTemplateIdx(templates.indexOf(selectedTemplate));
        }
    }, [templateName]);

    const { template: Template, name } = templates[selectedTemplateIdx];

    return (
        <div className="row">
            <div className="col-8 py-2">
                <Template certificate={certificateData} />
            </div>
            <div className="col-4">
                <div className="templates-container">
                    <div className="templates-content">
                        {templates.map(
                            (template, idx) =>
                                (idx === 0 || orgCertificateList.includes(idx)) && (
                                    <div key={template.name} className="template-details">
                                        <div
                                            aria-hidden="true"
                                            className="template-wrapper rounded border"
                                            onClick={() => {
                                                onClickTemplate(idx);
                                            }}>
                                            <img
                                                src={template?.thumbnail}
                                                alt=""
                                                className="template"
                                            />
                                            <div className="template-details-overlay rounded">
                                                <div className="template-number">
                                                    <div className="text-truncate">
                                                        {`Template ${idx + 1}`}
                                                    </div>
                                                </div>
                                                <div className="divider-line" />
                                                <div className="template-name">
                                                    <div className="text-truncate">
                                                        {template?.name ?? ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
