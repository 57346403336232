import React from 'react';
import { useHistory } from 'react-router';
import Breadcrumbs from '../../../components/Breadcrumbs';
import PaginatedTable from '../../../components/PaginatedTable';
import Switch from '../../../components/Switch';
import constants from '../../../utils/constants';
import { normaliseObjectArray } from '../../../utils/dataUtils';
import {
    useGetOrganizationsQuery,
    useUpdateOrganizationMutation,
    useDeleteOrganizationMutation
} from '../../../services/orgService';
import notifyError, { errors } from '../../../utils/rtkErrorHelper';
import Prompt from '../../../components/Prompt';

const Organizations = () => {
    const history = useHistory();
    const [activeFilters, setActiveFilters] = React.useState([]);
    const [showDeletePrompt, setShowDeletePrompt] = React.useState(false);
    const [showActiveStateChangePrompt, setShowActiveStateChangePrompt] = React.useState(false);
    const [selectedOrganization, setSelectedOrganization] = React.useState(null);
    const [selectedActiveState, setSelectedActiveState] = React.useState(null);
    const {
        data: organizations,
        isError: isGetOrganizationsError,
        error: getOrganizationsError
    } = useGetOrganizationsQuery();

    const [
        updateOrganization,
        { isError: isUpdateOrganizationError, error: updateOrganizationError }
    ] = useUpdateOrganizationMutation();

    const [
        deleteOrganization,
        { isError: isDeleteOrganizationError, error: deleteOrganizationError }
    ] = useDeleteOrganizationMutation();

    React.useEffect(() => {
        notifyError(isGetOrganizationsError, getOrganizationsError, errors.ORGANIZATIONS_RETRIEVE);
        notifyError(isUpdateOrganizationError, updateOrganizationError, errors.ORGANIZATION_UPDATE);
        notifyError(isDeleteOrganizationError, deleteOrganizationError, errors.ORGANIZATION_DELETE);
    }, [
        isGetOrganizationsError,
        getOrganizationsError,
        isUpdateOrganizationError,
        updateOrganizationError,
        isDeleteOrganizationError,
        deleteOrganizationError
    ]);

    const onChangeSwitch = async (orgId, val) => {
        setSelectedOrganization(orgId);
        setSelectedActiveState(val);
        setShowActiveStateChangePrompt(true);
    };

    const updateOrganizationActiveState = async (password) => {
        if (selectedActiveState != null) {
            const organization = {
                is_active: selectedActiveState,
                password
            };
            await updateOrganization({ organization, orgId: selectedOrganization });
            setSelectedOrganization(null);
            setSelectedActiveState(null);
        }
    };

    const renderActiveStatus = (rowData) => {
        const labelText = rowData?.is_active ? 'Active' : 'Inactive';
        return (
            <div>
                <Switch
                    value={!!rowData?.is_active}
                    onChange={(val) => {
                        onChangeSwitch(rowData?.id, val);
                    }}
                    label={labelText}
                    confirmAction
                />
            </div>
        );
    };

    const columns = [
        'name',
        renderActiveStatus,
        'primary_user',
        'primary_user_email',
        'primary_user_phone'
    ];

    const columnNames = [
        'Organization Name',
        'Status',
        'Primary User',
        'Primary User Email',
        'Primary User Phone'
    ];

    const filters = [
        { type: constants.FILTER_TYPE_TEXT },
        {
            type: constants.FILTER_TYPE_OPTIONS,
            options: [
                { label: 'Active', value: true },
                { label: 'Inactive', value: false }
            ]
        },
        { type: constants.FILTER_TYPE_TEXT },
        { type: constants.FILTER_TYPE_TEXT },
        { type: constants.FILTER_TYPE_TEXT }
    ];

    const filterColumnRefs = [
        'name',
        'is_active',
        'primary_user',
        'primary_user_email',
        'primary_user_phone'
    ];

    const organizationsWithAdminDetails = React.useMemo(() => {
        if (organizations?.length) {
            return organizations.map((org) => {
                const { users } = org;
                const admins = users
                    .filter(({ role, is_active }) => role === constants.ROLE_ORG_ADMIN && is_active)
                    .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
                const primaryUser = admins?.[0];

                return {
                    ...org,
                    ...{
                        primary_user: primaryUser?.username,
                        primary_user_email: primaryUser?.email,
                        primary_user_phone: primaryUser?.phone
                    }
                };
            });
        }
        return [];
    }, [organizations]);

    const parsedOrganizations = React.useMemo(() => {
        let parsedData = {
            byId: {},
            allIds: []
        };
        if (organizationsWithAdminDetails?.length) {
            let data = organizationsWithAdminDetails;
            if (activeFilters?.some((filter) => filter && typeof filter === 'string')) {
                data = organizationsWithAdminDetails.filter((dataItem) => {
                    const availableFilterQueryList = [];
                    const rowDataValueList = [];
                    activeFilters.forEach((filterValue, filterIdx) => {
                        if (filterValue) {
                            const dataField = dataItem[filterColumnRefs[filterIdx]]
                                ?.toString?.()
                                ?.toLowerCase?.();
                            availableFilterQueryList.push(
                                filterValue?.toString?.()?.toLowerCase?.()
                            );
                            rowDataValueList.push(dataField);
                        }
                    });
                    return availableFilterQueryList.every((query, queryIdx) =>
                        rowDataValueList[queryIdx]?.includes?.(query)
                    );
                });
            }
            parsedData = normaliseObjectArray(data);
        }
        return parsedData;
    }, [organizationsWithAdminDetails, activeFilters]);

    const onSetFilters = (fltrs) => {
        setActiveFilters(fltrs ?? []);
    };

    const onClickAddOrganization = () => {
        history.push('/organizations/add/new/');
    };

    const onClickOrganizationDetails = (org) => {
        const { id } = org;
        if (id) {
            history.push(`/organizations/${id}`);
        }
    };

    const renderUpdateActionBtn = () => <img src="/assets/vector/mr-btn-update.svg" alt="Update" />;

    const renderDeleteActionBtn = () => <img src="/assets/vector/mr-btn-delete.svg" alt="Delete" />;

    const onUpdateRow = ({ id }) => {
        history.push(`/organizations/${id}`);
    };

    const onShowDeleteOrganizationConfirmation = ({ id: organizationId }) => {
        setSelectedOrganization(organizationId);
        setShowDeletePrompt(true);
    };

    const onDeleteRow = async (password) => {
        const orgId = selectedOrganization;
        await deleteOrganization({ orgId, password });
    };

    const actions = [
        { name: renderUpdateActionBtn(), action: onUpdateRow, isIcon: true },
        {
            name: renderDeleteActionBtn(),
            action: onShowDeleteOrganizationConfirmation,
            isIcon: true
        }
    ];

    const onCloseDeleteOrganizationPrompt = () => {
        setShowDeletePrompt(false);
        setSelectedOrganization(null);
        setSelectedActiveState(null);
    };

    const onCloseOrganizationStateChangePrompt = () => {
        setShowActiveStateChangePrompt(false);
        setSelectedOrganization(null);
        setSelectedActiveState(null);
    };

    return (
        <div>
            <Breadcrumbs hideDashboard />
            <div className="d-flex justify-content-end mb-3">
                <button type="button" className="add-btn" onClick={onClickAddOrganization}>
                    ADD
                </button>
            </div>
            <PaginatedTable
                rounded
                columns={columns}
                columnNames={columnNames}
                rows={parsedOrganizations.allIds}
                data={parsedOrganizations.byId}
                filters={filters}
                onRowClick={onClickOrganizationDetails}
                onSetFilters={onSetFilters}
                actions={actions}
                headerActions={[]}
                pagination={parsedOrganizations?.allIds?.length > 4}
                pageSize={4}
            />
            <Prompt
                show={showDeletePrompt}
                onClose={onCloseDeleteOrganizationPrompt}
                onDone={onDeleteRow}
                title="Password Confirmation"
                message="Confirm deletion. Please enter your password."
                inputProps={{
                    type: 'password',
                    placeholder: 'Password'
                }}
            />
            <Prompt
                show={showActiveStateChangePrompt}
                onClose={onCloseOrganizationStateChangePrompt}
                onDone={updateOrganizationActiveState}
                title="Password Confirmation"
                message="Confirm deletion. Please enter your password."
                inputProps={{
                    type: 'password',
                    placeholder: 'Password'
                }}
            />
        </div>
    );
};

export default Organizations;
