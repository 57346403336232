import React from 'react';
import { useHistory, useLocation } from 'react-router';
import PaginatedTable from '../../../components/PaginatedTable';
import Switch from '../../../components/Switch';
import constants from '../../../utils/constants';
import { getDisplayValueForUserState, normaliseObjectArray } from '../../../utils/dataUtils';

import { useGetProfileQuery } from '../../../services/authService';
import { useGetOrgUsersQuery, useChangeUserStateMutation } from '../../../services/orgService';

import notifyError, { errors } from '../../../utils/rtkErrorHelper';
import Prompt from '../../../components/Prompt';

const Users = () => {
    const history = useHistory();
    const { pathname } = useLocation();

    const [activeFilters, setActiveFilters] = React.useState([]);
    const [showUserStateChangePrompt, setShowUserStateChangePrompt] = React.useState(false);
    const [selectedUserState, setSelectedUserState] = React.useState(null);
    const [selectedUser, setSelectedUser] = React.useState(null);

    const { data: profile, error: profileError, isError: isProfileError } = useGetProfileQuery();
    const { data: orgUsers, error: orgUserError, isError: isOrgUserError } = useGetOrgUsersQuery(
        { orgId: profile?.organizationId },
        { skip: !profile?.organizationId }
    );

    const [
        changeUserState,
        { error: changeUserStateError, isError: isChangeUserStateError }
    ] = useChangeUserStateMutation();

    React.useEffect(() => {
        notifyError(isProfileError, profileError, errors.AUTH_GET_PROFILE);
        notifyError(isOrgUserError, orgUserError, errors.ORGANIZATION_USER_RETRIVE);
        notifyError(isChangeUserStateError, changeUserStateError, errors.ORGANIZATION_USER_UPDATE);
    }, [
        isProfileError,
        profileError,
        isOrgUserError,
        orgUserError,
        isChangeUserStateError,
        changeUserStateError
    ]);

    const onChangeSwitch = async (userId, state) => {
        setSelectedUser(userId);
        setSelectedUserState(state);
        setShowUserStateChangePrompt(true);
    };

    const onChangeUserState = async (password) => {
        const orgId = profile?.organizationId;
        await changeUserState({
            orgId,
            state: selectedUserState,
            userId: selectedUser,
            password
        });
    };

    const renderActiveStatus = (rowData) => {
        const labelText = getDisplayValueForUserState(rowData?.state);
        if (rowData?.state === constants.USER_INVITED) {
            return (
                <div className="d-flex align-items-center">
                    <img src="/assets/vector/mr-clock.svg" alt="" />
                    <div className="px-2">Invited</div>
                </div>
            );
        }
        return (
            <div>
                <Switch
                    value={rowData?.state === constants.USER_ACTIVE}
                    onChange={(val, password) => {
                        let value = constants.USER_DISABLED;
                        if (val) {
                            value = constants.USER_ACTIVE;
                        }
                        onChangeSwitch(rowData?.id, value, password);
                    }}
                    label={labelText}
                />
            </div>
        );
    };

    const users = React.useMemo(() => {
        if (orgUsers?.allIds?.length) {
            const userList = orgUsers.allIds.map((userId) => {
                const user = orgUsers.byId[userId];
                const { first_name: fName, last_name: lName } = user;
                return {
                    ...user,
                    ...{
                        display_name: `${fName || ''} ${lName || ''}`
                    }
                };
            });
            return userList;
        }
        return [];
    }, [orgUsers]);

    const columns = ['username', renderActiveStatus, 'display_name', 'email', ''];

    const columnNames = ['Username', 'Status', 'Name', 'Email'];

    const filters = [
        { type: constants.FILTER_TYPE_TEXT },
        {
            type: constants.FILTER_TYPE_OPTIONS,
            options: [
                {
                    label: getDisplayValueForUserState(constants.USER_ACTIVE),
                    value: constants.USER_ACTIVE
                },
                {
                    label: getDisplayValueForUserState(constants.USER_DISABLED),
                    value: constants.USER_DISABLED
                },
                {
                    label: getDisplayValueForUserState(constants.USER_INVITED),
                    value: constants.USER_INVITED
                }
            ]
        },
        { type: constants.FILTER_TYPE_TEXT },
        { type: constants.FILTER_TYPE_TEXT }
    ];

    const filterColumnRefs = ['username', 'state', 'display_name', 'email'];

    const parsedUsers = React.useMemo(() => {
        let parsedData = {
            byId: {},
            allIds: []
        };
        if (users?.length) {
            let data = users;
            if (activeFilters?.some((filter) => filter && typeof filter === 'string')) {
                data = users.filter((dataItem) => {
                    const availableFilterQueryList = [];
                    const rowDataValueList = [];
                    activeFilters.forEach((filterValue, filterIdx) => {
                        if (filterValue) {
                            const dataField = dataItem[filterColumnRefs[filterIdx]]
                                ?.toString?.()
                                ?.toLowerCase?.();
                            availableFilterQueryList.push(
                                filterValue?.toString?.()?.toLowerCase?.()
                            );
                            rowDataValueList.push(dataField);
                        }
                    });
                    return availableFilterQueryList.every((query, queryIdx) =>
                        rowDataValueList[queryIdx]?.includes?.(query)
                    );
                });
            }
            parsedData = normaliseObjectArray(data);
        }
        return parsedData;
    }, [users, activeFilters]);

    const onSetFilters = (fltrs) => {
        setActiveFilters(fltrs ?? []);
    };

    const onClickAddUser = () => {
        history.push(`${pathname}/add/new`);
    };

    const onRowClick = ({ id: userId, username }) => {
        history.push(`${pathname}/${username}`, { userId });
    };

    const onCloseStateChangePrompt = () => {
        setShowUserStateChangePrompt(false);
        setSelectedUser(null);
        setSelectedUserState(null);
    };

    return (
        <div>
            <div className="d-flex justify-content-end my-3">
                <button onClick={onClickAddUser} type="button" className="add-btn">
                    Add
                </button>
            </div>
            <PaginatedTable
                rounded
                columns={columns}
                columnNames={columnNames}
                rows={parsedUsers.allIds}
                data={parsedUsers.byId}
                filters={filters}
                onSetFilters={onSetFilters}
                onRowClick={onRowClick}
            />
            <Prompt
                show={showUserStateChangePrompt}
                onClose={onCloseStateChangePrompt}
                onDone={onChangeUserState}
                title="Password Confirmation"
                message="Confirm deletion. Please enter your password."
                inputProps={{
                    type: 'password',
                    placeholder: 'Password'
                }}
            />
        </div>
    );
};

export default Users;
