import { createApi } from '@rtk-incubator/rtk-query';
import { normaliseObjectArray } from '../utils/dataUtils';
import { baseQueryWithAuth } from './baseQueries';

const orgService = createApi({
    reducerPath: 'orgService',
    baseQuery: baseQueryWithAuth,
    entityTypes: [
        'Organizations',
        'Organization',
        'OrganizationUsers',
        'OrganizationUser',
        'IssuerProfile',
        'MyOrgUserProfile'
    ],
    endpoints: (builder) => ({
        getIssuerProfile: builder.query({
            query: ({ orgId }) => `/issuer-profiles/${orgId}`,
            provides: ['IssuerProfile']
        }),
        getOrganizations: builder.query({
            query: () => `/organizations/`,
            provides: ['Organizations']
        }),
        getOrganization: builder.query({
            query: ({ orgId }) => `/organizations/${orgId}/`,
            provides: ['Organization']
        }),
        addOrganization: builder.mutation({
            query: ({ organization }) => ({
                url: '/organizations/',
                method: 'POST',
                body: organization
            }),
            invalidates: ['Organizations']
        }),
        verifyOrganizationDomain: builder.mutation({
            query: ({ orgId }) => ({
                url: `/organizations/${orgId}/verify-domain`,
                method: 'PUT'
            }),
            invalidates: ['Organization']
        }),
        generateOrganizationTXT: builder.mutation({
            query: ({ orgId, domain }) => ({
                url: `/organizations/${orgId}/verify-domain`,
                method: 'POST',
                body: { domain }
            }),
            invalidates: ['Organization']
        }),
        getOrgUsers: builder.query({
            query: ({ orgId }) => `/organizations/${orgId}/users`,
            transformResponse: (response) => normaliseObjectArray(response.users),
            provides: ['OrganizationUsers']
        }),
        getOrgUser: builder.query({
            query: ({ orgId, userId }) => `/organizations/${orgId}/users/${userId}`,
            provides: ['OrganizationUser']
        }),
        getMyOrgUserProfile: builder.query({
            query: () => '/auth/me/',
            provides: ['MyOrgUserProfile']
        }),
        searchOrgUserByUsername: builder.query({
            query: ({ orgId, username }) => `/organizations/${orgId}/users/?search=${username}`,
            transformResponse: (response) => normaliseObjectArray(response.users)
        }),
        inviteUserToOrg: builder.mutation({
            // inviteUserToOrg
            query: ({ user, orgId }) => ({
                url: `/organizations/${orgId}/invite/`,
                method: 'POST',
                body: user
            }),
            invalidates: ['Organization', 'OrganizationUsers']
        }),
        acceptUserInvite: builder.mutation({
            query: ({ token, credentials }) => ({
                url: `/organizations/accept-invite/${token}/`,
                method: 'POST',
                body: credentials
            }),
            invalidates: ['Organization', 'OrganizationUsers']
        }),
        updateOrgUser: builder.mutation({
            query: ({ user, userId, orgId }) => ({
                url: `/organizations/${orgId}/users/${userId}`,
                method: 'PUT',
                body: user
            }),
            invalidates: ['OrganizationUsers', 'OrganizationUser', 'MyOrgUserProfile']
        }),
        updateOrganization: builder.mutation({
            query: ({ organization, orgId }) => ({
                url: `/organizations/${orgId}`,
                method: 'PUT',
                body: organization
            }),
            invalidates: ['Organizations', 'Organization', 'IssuerProfile']
        }),
        makeUserInactive: builder.mutation({
            query: ({ userId, orgId, password }) => ({
                url: `/organizations/${orgId}/users/${userId}`,
                method: 'PUT',
                body: { is_active: false, password }
            }),
            invalidates: ['OrganizationUsers', 'OrganizationUser']
        }),
        changeUserPassword: builder.mutation({
            query: ({ password, confirm_password, userId, orgId }) => ({
                url: `/organizations/${orgId}/users/${userId}/change-password/`,
                method: 'PUT',
                body: { password, confirm_password }
            }),
            invalidates: ['OrganizationUsers', 'OrganizationUser']
        }),
        deleteOrganization: builder.mutation({
            query: ({ orgId, password }) => ({
                url: `/organizations/${orgId}/`,
                method: 'DELETE',
                body: { password }
            }),
            invalidates: ['Organizations']
        }),
        changeUserState: builder.mutation({
            query: ({ state, userId, orgId, password }) => ({
                url: `/organizations/${orgId}/users/${userId}`,
                method: 'PUT',
                body: { state, password }
            }),
            invalidates: ['OrganizationUsers', 'OrganizationUser']
        })
    })
});

export default orgService;

export const {
    useGetIssuerProfileQuery,
    useGetOrganizationsQuery,
    useAddOrganizationMutation,
    useGetOrganizationQuery,
    useGetOrgUsersQuery,
    useGetOrgUserQuery,
    useInviteUserToOrgMutation,
    useSearchOrgUserByUsernameQuery,
    useUpdateOrgUserMutation,
    useUpdateOrganizationMutation,
    useMakeUserInactiveMutation,
    useAcceptUserInviteMutation,
    useVerifyOrganizationDomainMutation,
    useGenerateOrganizationTXTMutation,
    useGetMyOrgUserProfileQuery,
    useChangeUserPasswordMutation,
    useDeleteOrganizationMutation,
    useChangeUserStateMutation
} = orgService;
