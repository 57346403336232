import React from 'react';
import './VerifiedTitle.styles.scss';

const VerifiedTitle = ({ title, isVerified = false }) => {
    return (
        <div className="title-container">
            <div className="h4 fw-700">{title || ''}</div>
            {isVerified && (
                <img src="/assets/vector/mr-verified.svg" alt="" className="verified-icon" />
            )}
        </div>
    );
};

export default VerifiedTitle;
