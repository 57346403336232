import React from 'react';
import { parseDate } from '../../../utils/dataUtils';
import QRCode from 'qrcode.react';
import './Template1.styles.scss';

const Template1 = ({ certificate }) => {
    const [logoURL, setLogoURL] = React.useState('');
    const [seal1URL, setSeal1URL] = React.useState('');
    const [seal2URL, setSeal2URL] = React.useState('');

    const generateDataURLFromFile = (file) => {
        if (file?.name && file?.size) {
            return URL.createObjectURL(file);
        }
        return null;
    };

    const revokeURL = (url) => {
        if (url) {
            URL.revokeObjectURL(url);
        }
    };

    React.useEffect(() => {
        revokeURL(logoURL);
        revokeURL(seal1URL);
        revokeURL(seal2URL);
        const newLogoURL = generateDataURLFromFile(certificate?.certificate_logo);
        const newSeal1URL = generateDataURLFromFile(certificate?.signature_one);
        const newSeal2URL = generateDataURLFromFile(certificate?.signature_two);
        setLogoURL(newLogoURL);
        setSeal1URL(newSeal1URL);
        setSeal2URL(newSeal2URL);
        return () => {
            revokeURL(newLogoURL);
            revokeURL(newSeal1URL);
            revokeURL(newSeal2URL);
        };
    }, [certificate]);

    return (
        <div className="template-1-certificate-container">
            <div className="certificate-root">
                <div className="certificate-content">
                    <img
                        src="/assets/certificate/template-1/mr-template-1-frame.png"
                        alt=""
                        className="certificate-frame"
                    />
                    <div className="certificate-badge-container">
                        <img
                            src={logoURL || '/assets/vector/mr-badge.svg'}
                            alt=""
                            className="certificate-badge"
                        />
                    </div>
                    <div className="certificate-title-container">
                        {certificate?.title || 'Certificate Title'}
                    </div>
                    <div className="certificate-recievers-info-container">
                        <div className="awarded-to-text">Awarded to</div>
                        <div className="recievers-name">Receiver&lsquo;s Name</div>
                    </div>
                    <div className="certificate-description-container">
                        {certificate?.description || ''}
                    </div>
                    {certificate?.custom_attributes?.length ? (
                        <div className="certificate-custom-attributes-container">
                            {certificate.custom_attributes.map((attribute, idx) => (
                                <div key={idx}>
                                    <div className="attribute-label">{attribute?.label || ''}</div>
                                    <div className="attribute-value">{attribute?.value || ''}</div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        ''
                    )}
                    <div className="certificate-date-container">
                        <div className="date">{parseDate(certificate?.date, 'DD/MM/YYYY')}</div>
                        <div className="hint-text">Date</div>
                    </div>
                    <div className="certificate-signature-pane">
                        <div className="signatures-container">
                            {certificate?.show_signature_one && (
                                <div className="signature">
                                    {!seal1URL && <div className="signature-text">Signature 1</div>}
                                    {seal1URL && (
                                        <div className="signature-image-container">
                                            <img
                                                src={seal1URL}
                                                alt="Signature"
                                                className="signature-image"
                                            />
                                        </div>
                                    )}
                                    <div className="signature-hint">
                                        {certificate?.signature_title_one || ''}
                                    </div>
                                </div>
                            )}
                            {certificate?.show_signature_two && (
                                <div className="signature">
                                    {!seal2URL && <div className="signature-text">Signature 2</div>}
                                    {seal2URL && (
                                        <div className="signature-image-container">
                                            <img
                                                src={seal2URL}
                                                alt="Signature"
                                                className="signature-image"
                                            />
                                        </div>
                                    )}
                                    <div className="signature-hint">
                                        {certificate?.signature_title_two || ''}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="certificate-footer">
                        {!!certificate?.display_qr && (
                            <div className="qr-code">
                                <QRCode value={window.location.origin} size={50} />
                            </div>
                        )}
                        <div className="org-info">
                            <div className="org-logo-container">
                                <img
                                    src="/assets/vector/mr-badge.svg"
                                    alt=""
                                    className="org-logo"
                                />
                            </div>
                            <div className="org-name">Org Name</div>
                            <div className="org-verified-container">
                                <img
                                    src="/assets/vector/mr-verified.svg"
                                    alt=""
                                    className="org-verified-icon"
                                />
                            </div>
                        </div>
                        <div className="org-web-url">www.organization.com</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Template1;
