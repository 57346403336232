import { useSelector } from 'react-redux';
import { ACCESS_TOKEN_NAME } from '../config';
import constants from '../utils/constants';

export const useLoginStatus = () => {
    const { isSignedIn } = useSelector((state) => state);
    if (isSignedIn) {
        return true;
    } else {
        if (sessionStorage.getItem(ACCESS_TOKEN_NAME)) {
            return true;
        }
        return false;
    }
};

export const useLoggedUserRole = () => {
    // TODO: implement user role retriving logic
    return constants.ROLE_ORG_ADMIN;
};

export const useLoggedUserProfile = () => {
    const { profile } = useSelector((state) => state?.auth);
    return profile;
};
