import { createApi } from '@rtk-incubator/rtk-query';
import { baseQueryWithAuth } from './baseQueries';

const receiverService = createApi({
    reducerPath: 'receiverService',
    baseQuery: baseQueryWithAuth,
    entityTypes: ['Receivers', 'Receiver', 'Groups', 'Group'],
    endpoints: (builder) => ({
        getReceiverInfo: builder.query({
            query: ({ orgId, receiverId, env }) =>
                `/organizations/${orgId}/receivers/${receiverId}?env=${env}`,
            provides: ['Receiver']
        }),
        getReceivers: builder.query({
            query: ({ orgId }) => `/organizations/${orgId}/receivers/`,
            provides: ['Receivers']
        }),
        getGroups: builder.query({
            query: ({ orgId }) => `/organizations/${orgId}/groups/`,
            provides: ['Groups']
        }),
        getGroup: builder.query({
            query: ({ orgId, groupId }) => `/organizations/${orgId}/groups/${groupId}`,
            provides: ['Group']
        }),
        filterReceiversByEmail: builder.query({
            query: ({ orgId, email }) => `/organizations/${orgId}/receivers/?email=${email}`,
            provides: ['Receivers']
        }),
        filterReceiversByName: builder.query({
            query: ({ orgId, name }) => `/organizations/${orgId}/receivers/?name=${name}`,
            provides: ['Receivers']
        }),
        filterReceiversByStatus: builder.query({
            query: ({ orgId, status }) => `/organizations/${orgId}/receivers/?status=${status}`,
            provides: ['Receivers']
        }),
        filterGroupsByEmail: builder.query({
            query: ({ orgId, email }) => `/organizations/${orgId}/groups/?email=${email}`,
            provides: ['Groups']
        }),
        filterGroupsByName: builder.query({
            query: ({ orgId, name }) => `/organizations/${orgId}/groups/?name=${name}`,
            provides: ['Groups']
        }),
        filterGroupsByStatus: builder.query({
            query: ({ orgId, status }) => `/organizations/${orgId}/groups/?status=${status}`,
            provides: ['Groups']
        }),
        addGroup: builder.mutation({
            query: ({ group, orgId }) => ({
                url: `/organizations/${orgId}/groups/`,
                method: 'POST',
                body: group
            }),
            invalidates: ['Groups']
        }),
        addReceiverToGroup: builder.mutation({
            query: ({ receiverId, orgId, groupId }) => ({
                url: `/organizations/${orgId}/groups/`,
                method: 'PUT',
                body: { add: true, receiverId, groupId }
            }),
            invalidates: ['Receivers', 'Groups', 'Group']
        }),
        removeReceiverFromGroup: builder.mutation({
            query: ({ receiverId, orgId, groupId }) => ({
                url: `/organizations/${orgId}/groups/`,
                method: 'PUT',
                body: { add: false, receiverId, groupId }
            }),
            invalidates: ['Receivers', 'Groups', 'Group']
        }),
        inviteReceivers: builder.mutation({
            query: ({ orgId, receivers, groupId, env }) => ({
                url: `/organizations/${orgId}/receivers/invites/`,
                method: 'POST',
                body: { receivers, groupId, env }
            }),
            invalidates: ['Receivers']
        }),
        inviteActivityReceivers: builder.mutation({
            query: ({ orgId, receivers, groupId, env, activityId }) => ({
                url: `/organizations/${orgId}/reward-activities/receivers/invites/`,
                method: 'POST',
                body: { receivers, groupId, env, activityId }
            }),
            invalidates: ['Receivers']
        })
    })
});

export default receiverService;

export const {
    useGetReceiverInfoQuery,
    useGetReceiversQuery,
    useGetGroupsQuery,
    useGetGroupQuery,
    useFilterReceiversByEmailQuery,
    useFilterReceiversByNameQuery,
    useFilterReceiversByStatusQuery,
    useFilterGroupsByNameQuery,
    useFilterGroupsByEmailQuery,
    useFilterGroupsByStatusQuery,
    useAddGroupMutation,
    useAddReceiverToGroupMutation,
    useRemoveReceiverFromGroupMutation,
    useInviteReceiversMutation,
    useInviteActivityReceiversMutation
} = receiverService;
