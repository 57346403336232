import { createSlice } from '@reduxjs/toolkit';

const initState = {
    modalTypes: [],
    modalProps: {},
    modalStates: {}
};

/* eslint-disable no-param-reassign */
// since immer enables direct state mutations
const modalSlice = createSlice({
    name: 'modal',
    initialState: initState,
    reducers: {
        showModal(state, { payload }) {
            state.modalTypes.push(payload.modalType);
            state.modalProps[payload.modalType] = payload.modalProps;
        },
        hideModal(state) {
            state.modalTypes.pop();
        },
        stateModel(state, { payload }) {
            state.modalStates[payload.type] = payload[payload.type];
        }
    }
});
/* eslint-disable no-param-reassign */

export const modalActions = modalSlice.actions;
export const modalReducer = modalSlice.reducer;
