import React from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import notifyError, { errors } from '../utils/rtkErrorHelper';
import { showToastSuccess } from '../utils/toastHelper';
import { Form, TextField, SubmitButton } from './FormElements';
import { modalActions } from '../state/modal';
import { getCurrentEnv } from '../utils/dataUtils';
import {
    useGetOrganizationQuery,
    useInviteUserToOrgMutation,
    useUpdateOrganizationMutation
} from '../services/orgService';
import _ from 'lodash';

const inviteDataValidationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required')
});

const InviteAdminModal = () => {
    const dispatch = useDispatch();
    const organizationId = window.location.pathname.split('/')[2];
    const [inviteData] = React.useState({
        name: '',
        email: ''
    });
    const [organizationData, setOrganizationData] = React.useState({
        name: '',
        domain: '',
        admin: '',
        adminEmail: ''
    });

    const {
        data: organization,
        isError: isGetOrganziationError,
        error: getOrganizationError
    } = useGetOrganizationQuery(
        {
            orgId: organizationId
        },
        { skip: !organizationId }
    );

    const [
        updateOrganization,
        { isError: isUpdateOrganizationError, error: updateOrganizationError }
    ] = useUpdateOrganizationMutation();

    React.useEffect(() => {
        notifyError(isGetOrganziationError, getOrganizationError, errors.ORGANIZATION_RETRIEVE);
        notifyError(isUpdateOrganizationError, updateOrganizationError, errors.ORGANIZATION_UPDATE);
    }, [
        isGetOrganziationError,
        getOrganizationError,
        isUpdateOrganizationError,
        updateOrganizationError
    ]);

    React.useEffect(() => {
        if (organization) {
            const parsedOrganization = { ...organization };
            setOrganizationData(parsedOrganization);
        }
    }, [organization]);

    const onInvite = async (values) => {
        const { name, domain, id: orgId } = organizationData;
        const { name: admin, email: adminEmail } = values;

        const organizationInfo = _.pickBy(
            {
                name,
                domain,
                admin,
                adminEmail,
                env: getCurrentEnv()
            },
            _.identity
        );

        const result = await updateOrganization({ organization: organizationInfo, orgId });

        if (result?.data) {
            if (result.data.action === 'Invited') {
                showToastSuccess('Invitation sent.');
                dispatch(modalActions.hideModal());
            }
        }
    };

    return (
        <div>
            <div className="h5 text-center bg-primary p-3">Add New Admin</div>
            <div className="py-4 px-3">
                <Form
                    initialValues={inviteData}
                    validationSchema={inviteDataValidationSchema}
                    onSubmit={onInvite}
                    enableReinitialize>
                    <div className="container-fluid">
                        <div className="col my-3">
                            <div className="col">Name</div>
                            <div className="col">
                                <TextField name="name" />
                            </div>
                        </div>
                        <div className="col my-3">
                            <div className="col">Email</div>
                            <div className="col">
                                <TextField name="email" />
                            </div>
                        </div>
                        <div className="row my-3 justify-content-center">
                            <SubmitButton title="Add New Admin" className="btn btn-primary px-5" />
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default InviteAdminModal;
