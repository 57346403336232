import dayjs from 'dayjs';
import constants from './constants';

export const normaliseObjectArray = (array, inverted = false, mixedIds = false) => {
    const result = { byId: {}, allIds: [] };
    const byId = array.reduce((_byId, item) => {
        const newById = { ..._byId };
        newById[item.id] = item;
        return newById;
    }, {});
    result.byId = byId;

    let allIds = Object.keys(byId).map((id) => parseInt(id));
    if (inverted) {
        allIds = Object.keys(byId)
            .map((id) => parseInt(id))
            .sort((a, b) => b - a);
    }
    if (mixedIds) {
        allIds = Object.keys(byId).map((id) => id);
    }
    result.allIds = allIds;
    return result;
};

export const parseDate = (date, format = 'DD.MM.YYYY', disableFallback) => {
    let formattedDate = new Date();
    if (date && dayjs(date).isValid()) {
        formattedDate = date;
    }
    if (disableFallback && !dayjs(date).isValid()) {
        return 'Invalid Date';
    }
    return dayjs(formattedDate).format(format);
};

export const downloadURI = (uri, name) => {
    const link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const getCurrentEnv = () => {
    const apiBase = process.env.REACT_APP_API_BASE;
    if (apiBase.includes('.dev.')) {
        return constants.ENV_DEV;
    }
    if (apiBase.includes('.stg.')) {
        return constants.ENV_STG;
    }
    if (apiBase.includes('localhost')) {
        return constants.ENV_DEV;
    }
    return constants.ENV_PROD;
};

export const getClaimsFromActivityElements = (
    activity,
    rewardElements = [],
    receiver = {},
    issuer = {}
) => {
    const { name } = activity;
    const claims = {
        certificate: '',
        rewardBadge: '',
        remarks: '',
        title: '',
        issuerName: '',
        issuerImage: '',
        issuerProfileURL: '',
        issuedDate: dayjs().format('YYYY-MM-DD'),
        holderName: '',
        holderImage: '',
        holderProfileURL: ''
    };
    if (receiver?.receivingDID) {
        claims.holderProfileURL = `${process.env.REACT_APP_ZEDEID_BASE}/${receiver.receivingDID}/rewards`;
    }
    if (receiver?.name) {
        claims.holderName = receiver.name;
    }
    if (receiver?.photo) {
        claims.holderImage = receiver.photo;
    }
    if (issuer?.name) {
        claims.issuerName = issuer.name;
    }
    if (issuer?.logo) {
        claims.issuerImage = issuer.logo;
    }
    if (issuer?.id) {
        claims.issuerProfileURL = `${window.location.protocol}//${window.location.host}/issuer-profile/${issuer.id}`;
    }
    if (name) {
        claims.title = name;
    }
    rewardElements.forEach((element) => {
        if (element?.type === constants.ELEMENT_TYPE_CERTIFICATE) {
            const { description } = element;
            claims.certificate = JSON.stringify(element);
            if (description) {
                claims.remarks = description;
            }
        }
    });
    rewardElements.forEach((element) => {
        if (element?.type === constants.ELEMENT_TYPE_BADGE) {
            const { description, badge } = element;
            claims.rewardBadge = badge;
            if (description) {
                claims.remarks = description;
            }
        }
    });
    return claims;
};

export const getDisplayValueForUserRole = (role) => {
    switch (role) {
        case constants.ROLE_ORG_USER:
            return 'Org User';
        case constants.ROLE_SUPER_USER:
            return 'Super Admin';
        case constants.ROLE_ORG_ADMIN:
            return 'Org Admin';
        default:
            return '';
    }
};

export const getDisplayValueForElementType = (elementType) => {
    switch (elementType) {
        case constants.ELEMENT_TYPE_CERTIFICATE:
            return 'Certificate';
        case constants.ELEMENT_TYPE_BADGE:
            return 'Badge';
        default:
            return elementType;
    }
};

export const getDisplayValueForUserState = (state) => {
    switch (state) {
        case constants.USER_ACTIVE:
            return 'Active';
        case constants.USER_DISABLED:
            return 'Disabled';
        case constants.USER_INVITED:
            return 'Invited';
        default:
            return state;
    }
};

export const getDisplayValueForRewardeeState = (state) => {
    switch (state) {
        case constants.REWARDEE_REGISTERED:
            return 'Registered';
        case constants.REWARDEE_INVITED:
            return 'Invited';
        default:
            return state;
    }
};
