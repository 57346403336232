import React from 'react';
import PaginatedTable from '../../components/PaginatedTable';
import Switch from '../../components/Switch';
import constants from '../../utils/constants';
import { normaliseObjectArray } from '../../utils/dataUtils';

const Users = () => {
    const [activeFilters, setActiveFilters] = React.useState([]);

    const onChangeSwitch = (id, val) => {
        // TODO: call user status update api here
    };

    const renderActiveStatus = (rowData) => {
        const labelText = rowData?.is_active ? 'Active' : 'Inactive';
        if (rowData?.state === constants.USER_INVITED) {
            return (
                <div className="d-flex align-items-center">
                    <img src="/assets/vector/mr-clock.svg" alt="" />
                    <div className="px-2">Invited</div>
                </div>
            );
        }
        return (
            <div>
                <Switch
                    value={!!rowData?.is_active}
                    onChange={(val) => {
                        onChangeSwitch(rowData?.id, val);
                    }}
                    label={labelText}
                />
            </div>
        );
    };

    const columns = [
        'username',
        renderActiveStatus,
        'organization',
        'display_name',
        'email',
        'phone'
    ];

    const columnNames = ['Username', 'Status', 'Organization', 'Name', 'Email', 'Phone'];

    const dummyData = [
        {
            id: 1,
            username: 'John Wick',
            is_active: true,
            organization: 'Org1',
            display_name: 'FnameLname',
            email: 'orguser@org.com',
            phone: '0711234567'
        },
        {
            id: 2,
            username: 'John Wick',
            is_active: true,
            organization: 'Org2',
            display_name: 'FnameLname',
            email: 'orguser@org.com',
            phone: '0711234567'
        },
        {
            id: 3,
            username: 'John Wick',
            is_active: false,
            organization: 'Org3',
            display_name: 'FnameLname',
            email: 'orguser@org.com',
            phone: '0711234567'
        },
        {
            id: 4,
            username: 'John Wick',
            is_active: true,
            organization: 'Org3',
            display_name: 'FnameLname',
            email: 'orguser@org.com',
            phone: '0711234567'
        }
    ];

    const filters = [
        { type: constants.FILTER_TYPE_TEXT },
        {
            type: constants.FILTER_TYPE_OPTIONS,
            options: [
                { label: 'Active', value: true },
                { label: 'Inactive', value: false },
                { label: 'Invited', value: constants.USER_INVITED }
            ]
        },
        { type: constants.FILTER_TYPE_TEXT },
        { type: constants.FILTER_TYPE_TEXT },
        { type: constants.FILTER_TYPE_TEXT }
    ];

    const filterColumnRefs = [
        'username',
        'status',
        'organization',
        'display_name',
        'email',
        'phone'
    ];

    const parsedUsers = React.useMemo(() => {
        let parsedData = {
            byId: {},
            allIds: []
        };
        if (dummyData?.length) {
            let data = dummyData;
            if (activeFilters?.length) {
                data = dummyData.filter((dataItem) => {
                    if (activeFilters?.length) {
                        return activeFilters.some((activeFilter, filterIdx) => {
                            const dataItemField = dataItem[filterColumnRefs[filterIdx]];
                            return dataItemField?.includes?.(activeFilter);
                        });
                    }
                    return false;
                });
            }
            parsedData = normaliseObjectArray(data);
        }
        return parsedData;
    }, [dummyData, activeFilters]);

    const onSetFilters = (fltrs) => {
        setActiveFilters(fltrs ?? []);
    };

    return (
        <div>
            <h3>Users</h3>
            <div className="d-flex justify-content-end mb-3">
                <button type="button" className="btn btn-secondary px-5">
                    ADD
                </button>
            </div>
            <PaginatedTable
                rounded
                columns={columns}
                columnNames={columnNames}
                rows={parsedUsers.allIds}
                data={parsedUsers.byId}
                filters={filters}
                onSetFilters={onSetFilters}
            />
        </div>
    );
};

export default Users;
