import React from 'react';

import { Redirect, Route } from 'react-router';
import { PrivateRoute } from './CustomRoutes';
import Dashboard from '../pages/DashboardMapper';
import Organizations from '../pages/Admin/Organizations/Organizations';
import OrganizationsAdd from '../pages/Admin/Organizations/OrganizationsAdd';
import OrganizationDetails from '../pages/Admin/Organizations/OrganizationDetails';
import Users from '../pages/Admin/Users';
import Elements from '../pages/OrgUser/Elements/Elements';
import AddElement from '../pages/OrgUser/Elements/AddElement';
import UpdateElement from '../pages/OrgUser/Elements/UpdateElement';
import Activities from '../pages/OrgUser/Activities/Activities';
import AddActivity from '../pages/OrgUser/Activities/AddActivity';
import OngoingActivity from '../pages/OrgUser/Activities/OngoingActivity';
import Receivers from '../pages/OrgUser/Receivers/Receivers';
import RewardedActivity from '../pages/OrgUser/Activities/RewardedActivity';
import EditActivity from '../pages/OrgUser/Activities/EditActivity';
import IssuedActivities from '../pages/OrgUser/Issued/IssuedActivities';

import { getAllUsers, getOrgRoles, getSuperUserRole } from '../utils/roleUtils';
import MyProfile from '../pages/MyProfile';

const LayoutRoutes = () => {
    const superuser = getSuperUserRole();
    const orgRoles = getOrgRoles();
    const allRoles = getAllUsers();
    return (
        <>
            <Route path="/" exact>
                <Redirect to="/home" />
            </Route>
            <PrivateRoute component={Dashboard} path="/home" roles={allRoles} />
            {/* activities */}
            <PrivateRoute component={Activities} path="/activities" roles={orgRoles} exact />
            <PrivateRoute
                component={AddActivity}
                path="/activities/add/new"
                roles={orgRoles}
                exact
            />
            <PrivateRoute
                component={EditActivity}
                path="/activities/edit/"
                roles={orgRoles}
                exact
            />
            <PrivateRoute
                component={OngoingActivity}
                path="/activities/ongoing/"
                roles={orgRoles}
                exact
            />
            <PrivateRoute
                component={RewardedActivity}
                path="/activities/rewarded/"
                roles={orgRoles}
                exact
            />
            {/* issued */}
            <PrivateRoute component={IssuedActivities} path="/issued" roles={orgRoles} exact />
            {/* elements */}
            <PrivateRoute component={Elements} path="/elements" roles={orgRoles} exact />
            <PrivateRoute component={AddElement} path="/elements/add/new" roles={orgRoles} exact />
            <PrivateRoute
                component={UpdateElement}
                path="/elements/:elementId/"
                roles={orgRoles}
                exact
            />
            {/* receivers */}
            <PrivateRoute component={Receivers} path="/receivers" roles={orgRoles} />
            {/* organizations */}
            <PrivateRoute component={Organizations} exact path="/organizations" roles={superuser} />
            <PrivateRoute
                component={OrganizationsAdd}
                exact
                path="/organizations/add/new/"
                roles={superuser}
            />
            <PrivateRoute
                component={OrganizationDetails}
                exact
                path="/organizations/:organizationId"
                roles={superuser}
            />
            {/* other */}
            <PrivateRoute component={Users} exact path="/users" roles={superuser} />
            <PrivateRoute component={MyProfile} exact path="/my-profile" roles={allRoles} />
        </>
    );
};

export default LayoutRoutes;
