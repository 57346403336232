import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import PaginatedTable from '../../../components/PaginatedTable';
import Breadcrumbs from '../../../components/Breadcrumbs';
import { normaliseObjectArray, parseDate } from '../../../utils/dataUtils';
import constants from '../../../utils/constants';

import { useGetProfileQuery } from '../../../services/authService';
import {
    useGetActivitiesQuery,
    useDeleteActivityMutation
} from '../../../services/activityService';
import notifyError, { errors } from '../../../utils/rtkErrorHelper';
import Prompt from '../../../components/Prompt';

const Activities = () => {
    const history = useHistory();
    const { path } = useRouteMatch();

    const [activeFilters, setActiveFilters] = React.useState([]);
    const [showDeletePrompt, setShowDeletePrompt] = React.useState(false);
    const [selectedActivity, setSelectedActivity] = React.useState(null);

    const {
        data: profile,
        error: getProfileError,
        isError: isGetProfileError
    } = useGetProfileQuery();
    const {
        data: activities,
        error: getActivitiesError,
        isError: isGetActivitiesError
    } = useGetActivitiesQuery(
        {
            orgId: profile?.organizationId
        },
        {
            skip: !profile?.organizationId
        }
    );
    const [
        deleteActivity,
        { isError: isDeleteActivityError, error: deleteActivityError }
    ] = useDeleteActivityMutation();

    React.useEffect(() => {
        notifyError(isDeleteActivityError, deleteActivityError, errors.ACTIVITIY_DELETE);
        notifyError(isGetProfileError, getProfileError, errors.AUTH_GET_PROFILE);
        notifyError(isGetActivitiesError, getActivitiesError, errors.ACTIVITIES_RETRIEVE);
    }, [
        isDeleteActivityError,
        deleteActivityError,
        getActivitiesError,
        isGetActivitiesError,
        getProfileError,
        isGetProfileError
    ]);

    const navigateOnClickActivity = ({ status, id: activityId }) => {
        if (status === constants.ACTIVITY_ON_GOING) {
            history.push('/activities/ongoing/', { activityId });
        }
        if (status === constants.ACTIVITY_REWARDED) {
            history.push('/activities/rewarded/', { activityId });
        }
    };

    const navigateOnActivityUpdate = ({ status, id: activityId }) => {
        if (status === constants.ACTIVITY_ON_GOING) {
            history.push('/activities/edit/', { activityId });
        }
    };

    const renderUpdateBtn = (rowData) => {
        const { status } = rowData;
        const isRewarded = status === constants.ACTIVITY_REWARDED;

        if (!isRewarded) {
            return (
                <button
                    type="button"
                    className="btn"
                    onClick={(e) => {
                        e.stopPropagation();
                        navigateOnActivityUpdate(rowData);
                    }}>
                    <img src="/assets/vector/mr-btn-update.svg" alt="Stroke Vector" />
                </button>
            );
        }
        return ' ';
    };

    const renderGenerateIssueBtn = (rowData) => {
        const { status } = rowData;
        const isRewarded = status === constants.ACTIVITY_REWARDED;

        if (!isRewarded) {
            return (
                <button
                    type="button"
                    className="btn btn-primary py-2 w-100"
                    onClick={(e) => {
                        e.stopPropagation();
                        navigateOnClickActivity(rowData);
                    }}>
                    Generate & Issue
                </button>
            );
        }
        return ' ';
    };

    const showDeleteConfirmation = (activity) => {
        setShowDeletePrompt(true);
        setSelectedActivity(activity);
    };

    const onDeleteActivity = async (password) => {
        const { id: activityId } = selectedActivity;
        if (password) {
            const { organizationId: orgId } = profile;
            await deleteActivity({
                orgId,
                activityId,
                password
            });
            setSelectedActivity(null);
        }
    };

    const renderDeleteBtn = (rowData) => {
        const { status } = rowData;
        const isRewarded = status === constants.ACTIVITY_REWARDED;
        if (!isRewarded) {
            return (
                <button
                    type="button"
                    className="btn"
                    onClick={(e) => {
                        e.stopPropagation();
                        showDeleteConfirmation(rowData);
                    }}>
                    <img src="/assets/vector/mr-btn-delete.svg" alt="Activity Rewards" />
                </button>
            );
        }
        return (
            <button
                type="button"
                className="btn fw-700 text-primary"
                onClick={() => navigateOnClickActivity(rowData)}>
                View
            </button>
        );
    };

    const renderCreatedDate = (rowData) => {
        const { createdAt: createdDate } = rowData;
        const date = parseDate(createdDate, 'DD.MM.YYYY', true);
        return date;
    };

    const renderActivityStatus = (rowData) => {
        const { status } = rowData;
        switch (status) {
            case constants.ACTIVITY_REWARDED:
                return 'Rewarded';
            case constants.ACTIVITY_DESIGN:
                return 'Design';
            case constants.ACTIVITY_ON_GOING:
                return 'On Going';
            default:
                return '';
        }
    };

    const columns = [
        'name',
        renderCreatedDate,
        renderActivityStatus,
        renderGenerateIssueBtn,
        renderUpdateBtn,
        renderDeleteBtn,
        ''
    ];

    const columnNames = ['Activity(Class/Course)', 'Created Date', 'Status', '', '', ''];

    const filters = [
        { type: constants.FILTER_TYPE_TEXT },
        { type: constants.FILTER_TYPE_DATE },
        {
            type: constants.FILTER_TYPE_OPTIONS,
            options: [
                { value: constants.ACTIVITY_REWARDED, label: 'Rewarded' },
                { value: constants.ACTIVITY_ON_GOING, label: 'On Going' },
                { value: constants.ACTIVITY_DESIGN, label: 'Design' }
            ]
        }
    ];

    const filterColumnRefs = ['name', 'start_date', 'status'];

    const parsedActivities = React.useMemo(() => {
        let parsedData = {
            byId: {},
            allIds: []
        };
        if (activities?.length) {
            let data = activities;
            if (activeFilters?.some((filter) => filter && typeof filter === 'string')) {
                data = activities.filter((dataItem) => {
                    const availableFilterQueryList = [];
                    const rowDataValueList = [];
                    activeFilters.forEach((filterValue, filterIdx) => {
                        if (filterValue) {
                            const dataField = dataItem[filterColumnRefs[filterIdx]]
                                ?.toString?.()
                                ?.toLowerCase?.();
                            availableFilterQueryList.push(
                                filterValue?.toString?.()?.toLowerCase?.()
                            );
                            rowDataValueList.push(dataField);
                        }
                    });
                    return availableFilterQueryList.every((query, queryIdx) =>
                        rowDataValueList[queryIdx]?.includes?.(query)
                    );
                });
            }
            parsedData = normaliseObjectArray(data, true);
        }
        return parsedData;
    }, [activities, activeFilters]);

    const onClickAddActivity = () => {
        history.push(`${path}/add/new`);
    };

    const onSetFilters = (fltrs) => {
        setActiveFilters(fltrs ?? []);
    };

    const onCloseDeletePrompt = () => {
        setShowDeletePrompt(false);
    };

    return (
        <div>
            <Breadcrumbs rootName="Home" hideDashboard />
            <div className="my-2">
                <div className="d-flex justify-content-end mb-4">
                    <button type="button" className="add-btn" onClick={onClickAddActivity}>
                        ADD
                    </button>
                </div>
                <PaginatedTable
                    rounded
                    columns={columns}
                    columnNames={columnNames}
                    rows={parsedActivities.allIds}
                    data={parsedActivities.byId}
                    filters={filters}
                    headerActions={[]}
                    activeFilters={activeFilters}
                    onSetFilters={onSetFilters}
                    pagination={parsedActivities?.allIds?.length > 4}
                    pageSize={4}
                />
                <Prompt
                    show={showDeletePrompt}
                    onClose={onCloseDeletePrompt}
                    onDone={onDeleteActivity}
                    title="Password Confirmation"
                    message="Confirm deletion. Please enter your password."
                    inputProps={{
                        type: 'password',
                        placeholder: 'Password'
                    }}
                />
            </div>
        </div>
    );
};

export default Activities;
