import React from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { useGetProfileQuery } from '../services/authService';
import { useInviteActivityReceiversMutation } from '../services/receiverService';
import notifyError, { errors } from '../utils/rtkErrorHelper';
import { showToastSuccess } from '../utils/toastHelper';
import { Form, TextField, SubmitButton } from './FormElements';
import { modalActions } from '../state/modal';
import { getCurrentEnv } from '../utils/dataUtils';
import { useLocation } from 'react-router-dom';

const inviteDataValidationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required')
});

const InviteReceiverActivityModal = () => {
    const dispatch = useDispatch();
    const { state: routerState } = useLocation();
    const [inviteData, setInviteData] = React.useState({
        name: '',
        email: ''
    });

    const { data: profile, isError: isProfileError, error: profileError } = useGetProfileQuery();

    const [
        inviteActivityReceivers,
        {
            error: inviteActivityReceiversError,
            isError: isInviteActivityReceiversError,
            isSuccess: isInviteActivityReceiversSuccess
        }
    ] = useInviteActivityReceiversMutation();

    React.useEffect(() => {
        notifyError(isProfileError, profileError, errors.AUTH_GET_PROFILE);
        notifyError(
            isInviteActivityReceiversError,
            inviteActivityReceiversError,
            errors.RECEIVERS_INVITE
        );
    }, [
        isProfileError,
        profileError,
        isInviteActivityReceiversError,
        inviteActivityReceiversError
    ]);

    React.useEffect(() => {
        if (isInviteActivityReceiversSuccess) {
            showToastSuccess('Invitation sent.');
            dispatch(modalActions.hideModal());
        }
    }, [isInviteActivityReceiversSuccess]);

    const onInvite = async (values) => {
        const { organizationId: orgId } = profile;
        const env = getCurrentEnv();
        const result = await inviteActivityReceivers({
            orgId,
            receivers: [values],
            env,
            activityId: routerState?.activityId
        });

        if (result) {
            if (!result.error) {
                dispatch(
                    modalActions.stateModel({
                        type: 'INVITED_USER_STATE',
                        INVITED_USER_STATE: values
                    })
                );
            }
        }
    };

    return (
        <div id="inviteReceiverModel">
            <div className="h5 text-center bg-primary p-3">Invite New Receiver</div>
            <div className="py-4 px-3">
                <Form
                    initialValues={inviteData}
                    validationSchema={inviteDataValidationSchema}
                    onSubmit={onInvite}
                    enableReinitialize>
                    <div className="form">
                        <div className="input-wrap">
                            <p className="title">Name</p>
                            <TextField name="name" />
                        </div>
                        <div className="input-wrap">
                            <p className="title">Email</p>
                            <TextField name="email" />
                        </div>
                        <div className="row my-3 justify-content-center">
                            <SubmitButton title="Send Invite" className="btn btn-primary px-5" />
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default InviteReceiverActivityModal;
