import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import PaginatedTable from '../../../components/PaginatedTable';
import constants from '../../../utils/constants';
import { normaliseObjectArray } from '../../../utils/dataUtils';
import { useGetProfileQuery } from '../../../services/authService';
import { useGetGroupsQuery } from '../../../services/receiverService';

const ReceiversGroups = () => {
    const history = useHistory();
    const { path } = useRouteMatch();

    const { data: profile } = useGetProfileQuery();
    const { data: groups } = useGetGroupsQuery(
        {
            orgId: profile?.organizationId
        },
        {
            skip: !profile?.organizationId
        }
    );

    const [activeFilters, setActiveFilters] = React.useState([]);

    const columns = ['name', 'count', ''];

    const columnNames = ['Name', 'Count'];

    const filters = [
        {
            type: constants.FILTER_TYPE_TEXT
        }
    ];

    const filterColumnRefs = ['name', 'count'];

    const groupsWithCounts = React.useMemo(() => {
        if (groups?.length) {
            return groups.map((group) => ({
                ...group?.group,
                ...{
                    count: group?.count?.toString() ?? '0'
                }
            }));
        }
        return [];
    }, [groups]);

    const parsedRewardees = React.useMemo(() => {
        let parsedData = {
            byId: {},
            allIds: []
        };
        if (groupsWithCounts?.length) {
            let data = groupsWithCounts;
            if (activeFilters?.some((filter) => filter && typeof filter === 'string')) {
                data = groupsWithCounts.filter((dataItem) => {
                    const availableFilterQueryList = [];
                    const rowDataValueList = [];
                    activeFilters.forEach((filterValue, filterIdx) => {
                        if (filterValue) {
                            const dataField = dataItem[filterColumnRefs[filterIdx]]
                                ?.toString?.()
                                ?.toLowerCase?.();
                            availableFilterQueryList.push(
                                filterValue?.toString?.()?.toLowerCase?.()
                            );
                            rowDataValueList.push(dataField);
                        }
                    });
                    return availableFilterQueryList.every((query, queryIdx) =>
                        rowDataValueList[queryIdx]?.includes?.(query)
                    );
                });
            }
            parsedData = normaliseObjectArray(data);
        }
        return parsedData;
    }, [groupsWithCounts, activeFilters]);

    const onSetFilters = (fltrs) => {
        setActiveFilters(fltrs ?? []);
    };

    const onClickAdd = () => {
        history.push(`${path}/add`);
    };

    const onClickGroup = (rowData) => {
        const { id: groupId, name } = rowData;
        history.push(`${path}/${name}`, { groupId });
    };

    return (
        <div className="py-4">
            <div className="row">
                <div className="col-md-9">
                    <PaginatedTable
                        rounded
                        columns={columns}
                        columnNames={columnNames}
                        rows={parsedRewardees.allIds}
                        data={parsedRewardees.byId}
                        filters={filters}
                        onRowClick={onClickGroup}
                        onSetFilters={onSetFilters}
                        headerActions={[]}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-1 offset-md-4">
                    <button type="button" className="add-btn" onClick={onClickAdd}>
                        +
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ReceiversGroups;
