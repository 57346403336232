import React from 'react';
import { Prompt, useHistory } from 'react-router';
import { Form, MultilineTextField, SubmitButton, TextField } from '../../components/FormElements';
import { BackBtn } from '../../components/Buttons';
import EditableLogo from '../../components/EditableLogo';
import { useGetProfileQuery } from '../../services/authService';
import { useGetOrganizationQuery, useUpdateOrganizationMutation } from '../../services/orgService';
import notifyError, { errors } from '../../utils/rtkErrorHelper';
import constants from '../../utils/constants';
import { showToastError } from '../../utils/toastHelper';
import { fileToB64 } from '../../utils/fileUtils';

const ProfileEdit = () => {
    const history = useHistory();
    const [hasUnsavedChanges, setHasUnsavedChanges] = React.useState(false);
    const [orgLogo, setOrgLogo] = React.useState('');
    const [orgDetails, setOrgDetails] = React.useState({
        logo: '',
        name: '',
        description: '',
        domain: '',
        admin: '',
        adminEmail: ''
    });

    const {
        data: profile,
        isError: isGetProfileError,
        error: getProfileError
    } = useGetProfileQuery();

    const {
        data: organization,
        isError: isGetOrganizationError,
        error: getOrganizationError
    } = useGetOrganizationQuery(
        { orgId: profile?.organizationId },
        { skip: !profile?.organizationId }
    );

    const [
        updateOrganization,
        { error: updateOrganizationError, isError: isUpdateOrganizationError }
    ] = useUpdateOrganizationMutation();

    const setOrganizationInfo = () => {
        const { users, logo } = organization;
        const admin = users?.find((user) => user?.role === constants.ROLE_ORG_ADMIN);
        if (admin) {
            const { username: username, email: adminEmail } = admin;
            const org = {
                ...organization,
                ...{
                    admin: username,
                    adminEmail
                }
            };
            setOrgLogo(logo);
            setOrgDetails(org);
        }
    };

    React.useEffect(() => {
        if (organization) {
            setOrganizationInfo();
        }
    }, [organization]);

    React.useEffect(() => {
        notifyError(isGetProfileError, getProfileError, errors.AUTH_GET_PROFILE);
        notifyError(isGetOrganizationError, getOrganizationError, errors.ORGANIZATION_RETRIEVE);
        notifyError(isUpdateOrganizationError, updateOrganizationError, errors.ORGANIZATION_UPDATE);
    }, [
        isGetProfileError,
        getProfileError,
        isGetOrganizationError,
        getOrganizationError,
        isUpdateOrganizationError,
        updateOrganizationError
    ]);

    const onClickEditProfile = () => {
        history.push('/home/profile');
    };

    const onSelectLogo = async (e) => {
        const file = e?.target?.files?.[0];
        try {
            const logo = await fileToB64(file);
            setOrgLogo(logo);
        } catch (e) {
            console.error(e);
            showToastError(`Cannot parse logo image. ${e}`);
        }
    };

    const onUpdateOrgInfo = async (values) => {
        setHasUnsavedChanges(false);
        const { organizationId: orgId } = profile;
        const payload = {
            ...values
        };
        delete payload?.is_active;
        delete payload?.admin;
        delete payload?.adminEmail;
        if (orgLogo) {
            payload.logo = orgLogo;
        }
        await updateOrganization({ organization: payload, orgId });
        history.push('/home/profile');
    };

    const onChangeName = (e) => {
        setOrganizationInfo((prevOrgInfo) => ({
            ...prevOrgInfo,
            name: e.target.value
        }));
        if (!hasUnsavedChanges) {
            setHasUnsavedChanges(true);
        }
    };

    const onChangeDescription = (e) => {
        setOrganizationInfo((prevOrgInfo) => ({
            ...prevOrgInfo,
            description: e.target.value
        }));
        if (!hasUnsavedChanges) {
            setHasUnsavedChanges(true);
        }
    };

    const onChangeAdminName = (e) => {
        setOrganizationInfo((prevOrgInfo) => ({
            ...prevOrgInfo,
            admin: e.target.value
        }));
        if (!hasUnsavedChanges) {
            setHasUnsavedChanges(true);
        }
    };

    const onChangeAdminEmail = (e) => {
        setOrganizationInfo((prevOrgInfo) => ({
            ...prevOrgInfo,
            adminEmail: e.target.value
        }));
        if (!hasUnsavedChanges) {
            setHasUnsavedChanges(true);
        }
    };

    const onChangeDomain = (e) => {
        setOrganizationInfo((prevOrgInfo) => ({
            ...prevOrgInfo,
            domain: e.target.value
        }));
        if (!hasUnsavedChanges) {
            setHasUnsavedChanges(true);
        }
    };

    return (
        <div className="container-fluid mt-4 bg-white rounded">
            <Prompt
                when={hasUnsavedChanges}
                message="If you leave without saving the changes, changes will be discarded"
            />
            <Form initialValues={orgDetails} onSubmit={onUpdateOrgInfo} enableReinitialize>
                <div className="row">
                    <div className="col-lg-7">
                        <div className="container-fluid p-4">
                            <BackBtn title="Edit Profile" onClick={onClickEditProfile} />
                            <div className="row my-4">
                                <div className="col-md-6">
                                    <TextField
                                        label="Organization Name"
                                        name="name"
                                        placeholder="Organization Name"
                                        onChange={onChangeName}
                                    />
                                </div>
                            </div>
                            <div className="row my-4">
                                <div className="col-md-6">
                                    <MultilineTextField
                                        label="Organization Description"
                                        name="description"
                                        placeholder="Organization Description"
                                        onChange={onChangeDescription}
                                    />
                                </div>
                            </div>
                            <div className="row my-4">
                                <div className="col-md-6">
                                    <TextField
                                        label="Organization Domain"
                                        name="domain"
                                        placeholder="Organization Domain"
                                        onChange={onChangeDomain}
                                    />
                                </div>
                            </div>
                            <div className="row my-4">
                                <div className="col-md-6">
                                    <TextField
                                        label="Organization Admin Name"
                                        name="admin"
                                        placeholder="Organization Admin Name"
                                        disabled
                                        onChange={onChangeAdminName}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <TextField
                                        label="Organization Admin Email"
                                        name="adminEmail"
                                        placeholder="Organization Admin Email"
                                        disabled
                                        onChange={onChangeAdminEmail}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 my-4">
                        <div className="fw-700">
                            Organization Logo
                            <EditableLogo logo={orgLogo} onSelectImage={onSelectLogo} editable />
                        </div>
                    </div>
                    <div className="col-lg-2 d-flex justify-content-end my-4">
                        <div className="d-block mx-2">
                            <SubmitButton
                                title="Save"
                                className="btn btn-primary fw-700 px-5 py-2"
                            />
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default ProfileEdit;
