import { createApi } from '@rtk-incubator/rtk-query';
import { baseQueryWithAuth } from './baseQueries';

const elementsService = createApi({
    reducerPath: 'elementsService',
    baseQuery: baseQueryWithAuth,
    entityTypes: ['Elements', 'Element', 'TemplateElements'],
    endpoints: (builder) => ({
        getElements: builder.query({
            query: ({ orgId }) => `/organizations/${orgId}/reward-elements/`,
            provides: ['Elements']
        }),
        getTemplateElements: builder.query({
            query: ({ orgId }) => `/organizations/${orgId}/reward-elements/?is_template=true`,
            provides: ['TemplateElements']
        }),
        getElement: builder.query({
            query: ({ orgId, elementId }) => `/organizations/${orgId}/reward-elements/${elementId}`,
            provides: ['Element']
        }),
        filterElementsByType: builder.query({
            query: ({ orgId, type }) => `/organizations/${orgId}/reward-elements/?type=${type}`,
            provides: ['Elements']
        }),
        filterElementsByStatus: builder.query({
            query: ({ orgId, status }) =>
                `/organizations/${orgId}/reward-elements/?status=${status}`,
            provides: ['Elements']
        }),
        addElement: builder.mutation({
            query: ({ element, orgId }) => ({
                url: `/organizations/${orgId}/reward-elements/`,
                method: 'POST',
                body: element
            }),
            invalidates: ['Elements', 'TemplateElements']
        }),
        updateElement: builder.mutation({
            query: ({ element, orgId, elementId }) => ({
                url: `/organizations/${orgId}/reward-elements/${elementId}`,
                method: 'PUT',
                body: element
            }),
            invalidates: ['Elements', 'Element', 'TemplateElements']
        }),
        deleteElement: builder.mutation({
            query: ({ orgId, elementId, password, passwordNotRequired }) => ({
                url: `/organizations/${orgId}/reward-elements/${elementId}`,
                method: 'DELETE',
                body: { password, passwordNotRequired }
            }),
            invalidates: ['Elements', 'TemplateElements']
        })
    })
});

export default elementsService;

export const {
    useGetElementsQuery,
    useGetElementQuery,
    useGetTemplateElementsQuery,
    useFilterElementsByTypeQuery,
    useFilterElementsByStatusQuery,
    useAddElementMutation,
    useUpdateElementMutation,
    useDeleteElementMutation
} = elementsService;
