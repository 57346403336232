import React from 'react';
import qs from 'qs';
import { useHistory, useLocation } from 'react-router';
import { Form, TextField, SubmitButton } from '../components/FormElements';
import notifyError, { errors } from '../utils/rtkErrorHelper';

import { useAcceptUserInviteMutation } from '../services/orgService';

const InviteeJoin = () => {
    const { search } = useLocation();
    const history = useHistory();

    const [inviteeInfo, setInviteeInfo] = React.useState({
        password: '',
        confirmPassword: ''
    });

    const [
        acceptInvitation,
        { isError: isAcceptInvitationError, error: acceptInvitationError }
    ] = useAcceptUserInviteMutation();

    const onSubmitCredentials = async ({ password, confirmPassword }) => {
        const credentials = {
            password,
            confirm_password: confirmPassword
        };

        const { token } = qs.parse(search, { ignoreQueryPrefix: true });
        if (token) {
            await acceptInvitation({ token, credentials });
            history.push('/signin');
        } else {
            notifyError(true, '', errors.ORGANIZATION_USER_INVITE_TOKEN_MISSING);
        }
    };

    React.useEffect(() => {
        notifyError(
            isAcceptInvitationError,
            acceptInvitationError,
            errors.ORGANIZATION_USER_ACCEPT_INVITE
        );
    }, [isAcceptInvitationError, acceptInvitationError]);

    return (
        <div className="vh-100 p-5">
            <Form initialValues={inviteeInfo} onSubmit={onSubmitCredentials} enableReinitialize>
                <div className="container-fluid">
                    <div className="row my-3">
                        <div className="col-md-4 offset-md-4">
                            <div className="h4">Accept Invitation</div>
                        </div>
                    </div>
                    <div className="row my-3">
                        <div className="col-md-4 offset-md-4">
                            <TextField
                                type="password"
                                name="password"
                                placeholder="Enter password"
                            />
                        </div>
                    </div>
                    <div className="row my-3">
                        <div className="col-md-4 offset-md-4">
                            <TextField
                                type="password"
                                name="confirmPassword"
                                placeholder="Re-type password"
                            />
                        </div>
                    </div>
                    <div className="row my-3">
                        <div className="col-md-4 offset-md-4">
                            <SubmitButton title="Accept Invitation" />
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default InviteeJoin;
