import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import qs from 'qs';
import { useHistory, useLocation, Link } from 'react-router-dom';

import { signIn } from '../api';
import authService from '../services/authService';

import { ACCESS_TOKEN_NAME, REFRESH_TOKEN_NAME } from '../config';
import { Form, TextField, SubmitButton, DisplayField } from '../components/FormElements';
import { authActions } from '../state/auth';
import { showToastError, showToastSuccess } from '../utils/toastHelper';
import { getCurrentEnv } from '../utils/dataUtils';
import notifyError, { errors } from '../utils/rtkErrorHelper';

const LoginFormSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is Required'),
    password: Yup.string().required('Password is Required'),
    passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')
});

const confirmPasswordFormSchema = Yup.object().shape({
    password: Yup.string().required('Password is Required'),
    passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')
});

export default function SignInPage() {
    const dispatch = useDispatch();
    const [rememberMe] = useState(false);
    const history = useHistory();

    const [formData] = useState({
        email: '',
        password: '',
        remember: false
    });

    const onSubmit = async (values) => {
        const { email, password, remember } = values;
        try {
            const response = await signIn(email, password, remember);
            if (response && response[ACCESS_TOKEN_NAME]) {
                sessionStorage.setItem(ACCESS_TOKEN_NAME, response[ACCESS_TOKEN_NAME]);
                if (rememberMe) {
                    localStorage.setItem(REFRESH_TOKEN_NAME, response[REFRESH_TOKEN_NAME]);
                } else {
                    sessionStorage.setItem(REFRESH_TOKEN_NAME, response.refresh);
                }
                history.push('/');
            }
        } catch (e) {
            showToastError(`Authentication failed. ${e?.response?.data?.message || ''}`);
            // continue regardless of error
        }
    };

    return (
        <div className="vh-100 d-flex flex-column align-items-center justify-content-center">
            <div className="container">
                <div className=" row justify-content-center">
                    <div className="col-lg-9">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="d-none d-md-flex col-md-4 py-2 bg-primary signin-form-banner">
                                    <div className="signin-form-logo-container">
                                        <img
                                            src="/assets/vector/mr-logo-white.svg"
                                            alt="Micro Rewards"
                                            className="signin-form-logo"
                                        />
                                    </div>
                                    <div className="helper-text-container">
                                        <div className="helper-text">Need Help?</div>
                                    </div>
                                </div>
                                <div className="col-md-8 py-5 px-5 sign-in-form-container bg-white">
                                    <div className="h3 pt-3 font-weight-bold text-center">
                                        Login
                                    </div>
                                    <div className="h6 py-3 fw-700 text-center text-primary">
                                        Welcome to Micro Rewards
                                    </div>
                                    <Form
                                        enableReinitialize
                                        initialValues={formData}
                                        validationSchema={LoginFormSchema}
                                        onSubmit={onSubmit}>
                                        <div className="input-row my-4">
                                            <TextField
                                                label="Username"
                                                name="email"
                                                id="email"
                                                placeholder="Enter your email"
                                            />
                                        </div>
                                        <div className="input-row my-3">
                                            <TextField
                                                label="Password"
                                                type="password"
                                                id="password"
                                                name="password"
                                                placeholder="Enter your password"
                                            />
                                        </div>
                                        <small className="d-flex justify-content-end mt-3 mb-5 fw-500">
                                            <Link to="/forgot-password">Forgot Password?</Link>
                                        </small>
                                        <DisplayField fieldName="status">
                                            {(status) => {
                                                if (!status) return <></>;
                                                return (
                                                    <div
                                                        className={`alert alert-${
                                                            status.type === 'error'
                                                                ? 'danger'
                                                                : 'success'
                                                        }`}
                                                        role="alert">
                                                        <p>{status.message}</p>
                                                    </div>
                                                );
                                            }}
                                        </DisplayField>
                                        <div className="d-flex justify-content-center my-4">
                                            <SubmitButton
                                                className="btn btn-primary w-100 font-weight-bold"
                                                title="Login"
                                            />
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function LogoutPage() {
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(authActions.removeUser());
        sessionStorage.removeItem(ACCESS_TOKEN_NAME);
        sessionStorage.removeItem(REFRESH_TOKEN_NAME);
        localStorage.removeItem(REFRESH_TOKEN_NAME);
        window.location.replace('/signin');
    }, [dispatch, history]);

    return <></>;
}

export function ForgotPasswordPage() {
    const [formData] = useState({
        email: ''
    });

    const [
        requestPasswordReset,
        { isError: isRequestPasswordResetError, error: requestPasswordResetError, isSuccess }
    ] = authService.useRequestPasswordResetMutation();

    React.useEffect(() => {
        notifyError(
            isRequestPasswordResetError,
            requestPasswordResetError,
            errors.AUTH_REQUEST_PW_RESET
        );
    }, [isRequestPasswordResetError, requestPasswordResetError]);

    React.useEffect(() => {
        if (isSuccess) {
            showToastSuccess('Please check the given email to reset your password.');
        }
    }, [isSuccess]);

    const onSubmit = async ({ email }) => {
        const env = getCurrentEnv();
        try {
            await requestPasswordReset({ email, env });
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <div className="vh-100 d-flex flex-column align-items-center justify-content-center">
            <div className="container">
                <div className=" row justify-content-center">
                    <div className="col-lg-9">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="d-none d-md-flex col-md-4 py-2 bg-primary signin-form-banner">
                                    <div className="signin-form-logo-container">
                                        <img
                                            src="/assets/vector/mr-logo-white.svg"
                                            alt="Micro Rewards"
                                            className="signin-form-logo"
                                        />
                                    </div>
                                    <div className="helper-text-container">
                                        <div className="helper-text">Need Help?</div>
                                    </div>
                                </div>
                                <div className="col-md-8 py-5 px-5 sign-in-form-container bg-white">
                                    <div className="h3 pt-3 font-weight-bold text-center">
                                        Forgot password
                                    </div>
                                    <div className="h6 py-3 fw-700 text-center text-primary">
                                        Enter email associated with this account and we&apos;ll send
                                        a password reset link.
                                    </div>
                                    <Form
                                        enableReinitialize
                                        initialValues={formData}
                                        onSubmit={onSubmit}>
                                        <div className="input-row">
                                            <TextField
                                                label="Email"
                                                name="email"
                                                placeholder="Email"
                                            />
                                        </div>
                                        <DisplayField fieldName="status">
                                            {(status) => {
                                                if (!status) return <></>;
                                                return (
                                                    <div
                                                        className={`alert alert-${
                                                            status.type === 'error'
                                                                ? 'danger'
                                                                : 'success'
                                                        }`}
                                                        role="alert">
                                                        <p>{status.message}</p>
                                                    </div>
                                                );
                                            }}
                                        </DisplayField>
                                        <SubmitButton
                                            className="btn btn-primary mt-4 w-100"
                                            title="Request Password Reset"
                                        />
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function RegisterPage() {
    const [formData] = useState({
        username: '',
        email: '',
        password: ''
    });
    const onSubmit = () => {
        // console.log("done");
    };
    return (
        <div className="form">
            <div className="heading">Create an Account</div>
            <div className="form-wrapper">
                <Form
                    enableReinitialize
                    initialValues={formData}
                    validationSchema={LoginFormSchema}
                    onSubmit={onSubmit}>
                    <div className="input-row">
                        <TextField label="Username" name="username" placeholder="Username" />
                    </div>
                    <div className="input-row">
                        <TextField label="Email" name="email" placeholder="Email" />
                    </div>
                    <div className="input-row">
                        <TextField
                            type="password"
                            label="Password"
                            name="password"
                            placeholder="Password"
                        />
                    </div>
                    <div className="input-row">
                        <TextField
                            type="password"
                            label="Confirm Password"
                            name="passwordConfirmation"
                            placeholder="Confirm Password"
                        />
                    </div>
                    <DisplayField fieldName="status">
                        {(status) => {
                            if (!status) return <></>;
                            return (
                                <div
                                    className={`alert alert-${
                                        status.type === 'error' ? 'danger' : 'success'
                                    }`}
                                    role="alert">
                                    <p>{status.message}</p>
                                </div>
                            );
                        }}
                    </DisplayField>
                    <SubmitButton className="btn btn-primary mt-4" title="Register" />
                </Form>
            </div>
        </div>
    );
}

export function ForgotLinkSent() {
    return (
        <div className="form">
            <div className="heading">Check your email!</div>
            <div className="sub-text">
                Follow the instructions in the email we just sent you to reset password.
            </div>
            <Link className="mt-4 w-100" to="/login">
                Back to Log in
            </Link>
        </div>
    );
}
export function ResetPasswordPage() {
    const [formData] = useState({
        password: '',
        confirmPassword: ''
    });
    const history = useHistory();
    const { search } = useLocation();

    const [
        resetPassword,
        { isError: isResetPasswordError, error: passwordResetError, isSuccess }
    ] = authService.usePasswordResetMutation();

    React.useEffect(() => {
        notifyError(isResetPasswordError, passwordResetError, errors.AUTH_REQUEST_PW_RESET);
    }, [isResetPasswordError, passwordResetError]);

    React.useEffect(() => {
        if (isSuccess) {
            showToastSuccess('Password reset successfull. You may login now');
            history.push('/signin');
        }
    }, [isSuccess]);

    const onSubmit = async ({ password, confirmPassword }) => {
        const { token } = qs.parse(search, { ignoreQueryPrefix: true });
        const payload = {
            token,
            new_password: password,
            confirm_password: confirmPassword
        };
        await resetPassword(payload);
    };
    return (
        <div className="vh-100 d-flex flex-column align-items-center justify-content-center">
            <div className="container">
                <div className=" row justify-content-center">
                    <div className="col-lg-9">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="d-none d-md-flex col-md-4 py-2 bg-primary signin-form-banner">
                                    <div className="signin-form-logo-container">
                                        <img
                                            src="/assets/vector/mr-logo-white.svg"
                                            alt="Micro Rewards"
                                            className="signin-form-logo"
                                        />
                                    </div>
                                    <div className="helper-text-container">
                                        <div className="helper-text">Need Help?</div>
                                    </div>
                                </div>
                                <div className="col-md-8 py-5 px-5 sign-in-form-container bg-white">
                                    <div className="h3 pt-3 font-weight-bold text-center">
                                        Reset password
                                    </div>
                                    <div className="h6 py-3 fw-700 text-center text-primary">
                                        Enter your new password.
                                    </div>
                                    <Form
                                        enableReinitialize
                                        initialValues={formData}
                                        validationSchema={confirmPasswordFormSchema}
                                        onSubmit={onSubmit}>
                                        <div className="input-row">
                                            <TextField
                                                label="Password"
                                                name="password"
                                                type="password"
                                            />
                                        </div>
                                        <div className="input-row">
                                            <TextField
                                                label="Confirm Password"
                                                name="confirmPassword"
                                                type="password"
                                            />
                                        </div>
                                        <DisplayField fieldName="status">
                                            {(status) => {
                                                if (!status) return <></>;
                                                return (
                                                    <div
                                                        className={`alert alert-${
                                                            status.type === 'error'
                                                                ? 'danger'
                                                                : 'success'
                                                        }`}
                                                        role="alert">
                                                        <p>{status.message}</p>
                                                    </div>
                                                );
                                            }}
                                        </DisplayField>
                                        <SubmitButton
                                            className="btn btn-primary mt-4"
                                            title="Save Password"
                                        />
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
