import React from 'react';
import './BackBtn.styles.scss';

const BackBtn = ({ title = '', onClick }) => (
    <button type="button" className="btn text-primary back-btn-root" onClick={onClick}>
        <img className="mr-2" src="/assets/vector/mr-btn-arrow-left.svg" alt="Edit" />
        {title}
    </button>
);

export default BackBtn;
