import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { authReducer } from './auth';
import { userReducer } from './user';
import { modalReducer } from './modal';

// rtk-query
import authService from '../services/authService';
import appService from '../services/appService';
import userService from '../services/userService';
import orgService from '../services/orgService';
import elementsService from '../services/elementsService';
import activityService from '../services/activityService';
import receiverService from '../services/receiverService';
import zedeidService from '../services/zedeidService';

export default configureStore({
    reducer: combineReducers({
        auth: authReducer,
        user: userReducer,
        modal: modalReducer,
        [authService.reducerPath]: authService.reducer,
        [appService.reducerPath]: appService.reducer,
        [userService.reducerPath]: userService.reducer,
        [orgService.reducerPath]: orgService.reducer,
        [elementsService.reducerPath]: elementsService.reducer,
        [activityService.reducerPath]: activityService.reducer,
        [receiverService.reducerPath]: receiverService.reducer,
        [zedeidService.reducerPath]: zedeidService.reducer
    }),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(authService.middleware)
            .concat(appService.middleware)
            .concat(userService.middleware)
            .concat(orgService.middleware)
            .concat(elementsService.middleware)
            .concat(activityService.middleware)
            .concat(receiverService.middleware)
            .concat(zedeidService.middleware)
});
