import React from 'react';
import dayjs from 'dayjs';
import Papa from 'papaparse';
import { Prompt, useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
    Form,
    TextField,
    DatePicker,
    SubmitButton,
    Checkbox
} from '../../../components/FormElements';
import Breadcrumbs from '../../../components/Breadcrumbs';
import constants from '../../../utils/constants';

import { useGetProfileQuery } from '../../../services/authService';
import { useAddActivityMutation } from '../../../services/activityService';
import notifyError, { errors } from '../../../utils/rtkErrorHelper';
import { useGetGroupsQuery, useGetReceiversQuery } from '../../../services/receiverService';
import { showToastError } from '../../../utils/toastHelper';
import ScrollTable from '../../../components/ScrollTable/ScrollTable';
import {
    downloadURI,
    getCurrentEnv,
    getDisplayValueForRewardeeState,
    normaliseObjectArray
} from '../../../utils/dataUtils';
import { modalActions } from '../../../state/modal';
import { modalTypes } from '../../../components/ModalContainer';
import Select from 'react-select';
import { useDeleteElementMutation } from '../../../services/elementsService';

const SelectStyles = {
    control: (base, state) => ({
        ...base,
        outline: 'none',
        boxShadow: state.isFocused ? '0 0 0 0.2rem rgb(36 195 181 / 25%)' : 'none',
        borderColor: state.isFocused ? '#7fe7de' : '#ced4da',
        '&:hover': {
            boxShadow: '0 0 0 0.2rem rgb(36 195 181 / 25%)',
            borderColor: '#7fe7de'
        }
    })
};

const AddActivity = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { modalStates } = useSelector((state) => state.modal);

    const SavedRef = React.useRef(false);

    const { state: routerState } = useLocation();
    const [selectedBadge, setSelectedBadge] = React.useState('');
    const [selectedCertificate, setSelectedCertificate] = React.useState('');

    const [selectedFileName, setSelectedFileName] = React.useState('');

    const [activeFilters, setActiveFilters] = React.useState([]);
    const [activityData, setActivityData] = React.useState({
        name: '',
        start_date: '',
        end_date: '',
        is_badge: false,
        is_certificate: false,
        add_group: false,
        add_individuals: false,
        invite_receivers: false,
        invite_individual_receiver: false
    });

    const [allReceivers, setAllReceivers] = React.useState([]);

    const {
        data: profile,
        error: getProfileError,
        isError: isGetProfileError
    } = useGetProfileQuery();
    const [
        addActivity,
        { isError: isAddActivityError, error: addActivityError }
    ] = useAddActivityMutation(
        {
            orgId: profile?.organizationId
        },
        {
            skip: !profile?.organizationId
        }
    );
    const {
        data: receivers,
        error: getReceiversError,
        isError: isGetReceiversError
    } = useGetReceiversQuery(
        { orgId: profile?.organizationId },
        { skip: !profile?.organizationId }
    );
    const { data: groups, error: getGroupsError, isError: isGetGroupsError } = useGetGroupsQuery(
        { orgId: profile?.organizationId },
        { skip: !profile?.organizationId }
    );

    const [
        deleteElement,
        { isError: isDeleteElementError, error: deleteElementError }
    ] = useDeleteElementMutation();

    /* add single invited user to the list of receivers */
    React.useEffect(() => {
        if (modalStates['INVITED_USER_STATE']) {
            const data = modalStates['INVITED_USER_STATE'];
            const interval = setInterval(() => {
                const selectedReceiver = receivers?.find(({ email }) => email === data?.email);

                if (selectedReceiver) {
                    onAddIndividualReceiver(selectedReceiver);
                    clearInterval(interval);

                    dispatch(
                        modalActions.stateModel({
                            type: 'INVITED_USER_STATE',
                            INVITED_USER_STATE: null
                        })
                    );
                }
            }, 500);
        }
    }, [modalStates, receivers]);

    React.useEffect(() => {
        notifyError(isAddActivityError, addActivityError, errors.ACTIVITIY_ADD);
        notifyError(isGetProfileError, getProfileError, errors.AUTH_GET_PROFILE);
        notifyError(isGetReceiversError, getReceiversError, errors.RECEIVERS_RETRIEVE);
        notifyError(isDeleteElementError, deleteElementError, errors.ELEMENT_DELETE);
        notifyError(isGetGroupsError, getGroupsError, errors.GROUPS_RETRIEVE);
    }, [
        isAddActivityError,
        addActivityError,
        getProfileError,
        isGetProfileError,
        getReceiversError,
        isGetReceiversError,
        getGroupsError,
        isGetGroupsError,
        isDeleteElementError,
        deleteElementError
    ]);

    React.useEffect(() => {
        if (routerState?.badge) {
            const { badge } = routerState;
            setSelectedBadge(badge);
        }
        if (routerState?.certificate) {
            const { certificate } = routerState;
            setSelectedCertificate(certificate);
        }
        if (routerState?.formData) {
            const { formData } = routerState;
            setActivityData(formData);
        }
        if (routerState?.allReceivers?.length) {
            const { allReceivers: allReceiverList } = routerState;
            setAllReceivers(allReceiverList);
        }
    }, [routerState]);

    const renderActiveStatus = () => {
        const labelText = getDisplayValueForRewardeeState(constants.REWARDEE_REGISTERED);
        return (
            <div className="d-flex align-items-center">
                <img src="/assets/vector/mr-verified.svg" alt="" />
                <div className="px-2">{labelText}</div>
            </div>
        );
    };

    const renderInvitedStatus = () => (
        <div className="d-flex align-items-center">
            <img src="/assets/vector/mr-clock.svg" alt="" />
            <div className="px-2">Invited</div>
        </div>
    );

    const renderToBeInvitedStatus = () => (
        <div className="d-flex align-items-center">
            <img src="/assets/vector/mr-clock.svg" alt="" />
            <div className="px-2">To be Invited</div>
        </div>
    );

    const renderRewardeeStatus = (rowData) => {
        const { status } = rowData;
        switch (status) {
            case constants.REWARDEE_REGISTERED:
                return renderActiveStatus(rowData);
            case constants.REWARDEE_INVITED:
                return renderInvitedStatus();
            case constants.REWARDEE_TO_BE_INVITED:
                return renderToBeInvitedStatus();
            default:
                return '';
        }
    };

    const columns = ['name', renderRewardeeStatus, 'email'];

    const columnNames = ['Name', 'Status', 'Email'];

    const filters = [
        { type: constants.FILTER_TYPE_TEXT },
        { type: constants.FILTER_TYPE_TEXT },
        { type: constants.FILTER_TYPE_TEXT }
    ];

    const removeReceiverByID = ({ id: selectedReceiverId }) => {
        const index = allReceivers.findIndex(
            ({ id: availableReceiverId }) => availableReceiverId === selectedReceiverId
        );
        if (index >= 0) {
            const allReceiversList = allReceivers.slice();
            allReceiversList.splice(index, 1);
            setAllReceivers(allReceiversList);
        }
    };

    const actions = [
        {
            name: '🗙',
            action: removeReceiverByID,
            isIcon: true
        }
    ];

    const filterColumnRefs = ['name', 'status', 'email'];

    const parsedRewardees = React.useMemo(() => {
        let parsedData = {
            byId: {},
            allIds: []
        };

        if (allReceivers?.length) {
            let data = allReceivers;
            if (activeFilters?.some((filter) => filter && typeof filter === 'string')) {
                data = allReceivers.filter((dataItem) => {
                    const availableFilterQueryList = [];
                    const rowDataValueList = [];
                    activeFilters.forEach((filterValue, filterIdx) => {
                        if (filterValue) {
                            const dataField = dataItem[filterColumnRefs[filterIdx]]
                                ?.toString?.()
                                ?.toLowerCase?.();
                            availableFilterQueryList.push(
                                filterValue?.toString?.()?.toLowerCase?.()
                            );
                            rowDataValueList.push(dataField);
                        }
                    });
                    return availableFilterQueryList.every((query, queryIdx) =>
                        rowDataValueList[queryIdx]?.includes?.(query)
                    );
                });
            }
            parsedData = normaliseObjectArray(data, false, true);
        }
        return parsedData;
    }, [allReceivers, activeFilters]);

    const onAddIndividualReceiver = (receiver) => {
        const containsReceiver = allReceivers.some(
            ({ id }) => id?.toString() === receiver?.id?.toString()
        );
        if (!containsReceiver) {
            const receiverList = allReceivers.slice();
            receiverList.push(receiver);
            setAllReceivers(receiverList);
        }
    };

    const onSelectIndividualReceiverOption = (e) => {
        const receiverId = e.value;
        const selectedReceiver = receivers?.find(({ id }) => id === Number(receiverId));
        if (selectedReceiver) {
            onAddIndividualReceiver(selectedReceiver);
        }
    };

    const onAddGroupReceivers = ({ receivers: groupReceivers }) => {
        const allReceiversList = allReceivers.slice();
        const newReceivers = [];
        groupReceivers.forEach((receiver) => {
            const { id: addingReceiverId } = receiver;
            const containsReceiver = allReceiversList.some(
                ({ id: availableReceiverId }) => addingReceiverId === availableReceiverId
            );
            if (!containsReceiver) {
                newReceivers.push(receiver);
            }
        });
        setAllReceivers(allReceiversList.concat(newReceivers));
    };

    const onSelectGroupOption = (e) => {
        const groupId = e.value;
        const selectedGroupObj = groups?.find(({ group }) => group?.id === Number(groupId));
        if (selectedGroupObj?.group) {
            const { group: selectedGroup } = selectedGroupObj;
            onAddGroupReceivers(selectedGroup);
        }
    };

    const onAddReceiversFromCSV = (csvReceivers = []) => {
        const parsedCSVReceivers = csvReceivers.map((csvReceiver, idx) => ({
            ...csvReceiver,
            ...{
                id: `csv_receiver_${idx + 1}`,
                isCSVReceiver: true,
                status: constants.REWARDEE_TO_BE_INVITED
            }
        }));
        onAddGroupReceivers({ receivers: parsedCSVReceivers });
    };

    const seperateInviteesFromReceivers = () => {
        const seperated = {
            invitees: [],
            receivers: []
        };
        const invitees = allReceivers.filter((receiver) => !!receiver?.isCSVReceiver);
        seperated.invitees = invitees.map((invitee) => {
            const cleanedInvitee = { ...invitee };
            delete cleanedInvitee?.id;
            delete cleanedInvitee?.isCSVReceiver;
            return cleanedInvitee;
        });
        seperated.receivers = allReceivers
            .filter((receiver) => !receiver?.isCSVReceiver)
            .map(({ id }) => id);
        return seperated;
    };

    const onSetFilters = (fltrs) => {
        setActiveFilters(fltrs ?? []);
    };

    const setRewardElements = (rewardElements = []) => {
        const elements = rewardElements.slice();
        if (selectedBadge != null && selectedBadge !== undefined && elements.length < 2) {
            elements.push(selectedBadge);
        }
        if (
            selectedCertificate != null &&
            selectedCertificate !== undefined &&
            elements.length < 2
        ) {
            elements.push(selectedCertificate);
        }
        return elements;
    };

    const onSaveActivity = async (values) => {
        const { name, start_date: start, end_date: end } = values;
        const env = getCurrentEnv();
        const { invitees, receivers: receiversList } = seperateInviteesFromReceivers();

        const activity = {
            name,
            start_date: dayjs(start).toISOString(),
            end_date: dayjs(end).toISOString(),
            reward_elements: setRewardElements(),
            receivers: receiversList,
            invitees,
            env,
            activityUserId: profile?.id
        };

        await addActivity({
            activity,
            orgId: profile?.organizationId
        });

        SavedRef.current = true;
        history.push({ pathname: '/activities' });
    };

    const onChangeName = (e) => {
        setActivityData({
            ...activityData,
            ...{ name: e.target.value }
        });
    };
    const onChangeStartDate = (val) => {
        setActivityData({
            ...activityData,
            ...{ start_date: val }
        });
    };
    const onChangeEndDate = (val) => {
        setActivityData({
            ...activityData,
            ...{ end_date: val }
        });
    };
    const onChangeIsBadge = (val) => {
        setActivityData({
            ...activityData,
            ...{ is_badge: val }
        });
    };
    const onChangeIsCertificate = (val) => {
        setActivityData({
            ...activityData,
            ...{ is_certificate: val }
        });
    };

    const onCreateBadge = () => {
        const payload = {
            ...routerState,
            formData: activityData,
            allReceivers,
            type: constants.ELEMENT_TYPE_BADGE,
            referrerPath: '/activities/add/new',
            overrideActiveRoute: '/activities'
        };
        history.push('/elements/add/new', payload);
    };

    const onCreateCertificate = () => {
        const payload = {
            ...routerState,
            formData: activityData,
            allReceivers,
            type: constants.ELEMENT_TYPE_CERTIFICATE,
            referrerPath: '/activities/add/new',
            overrideActiveRoute: '/activities'
        };
        history.push('/elements/add/new', payload);
    };

    const onEditElement = (type) => {
        let elementId = '';
        if (type === constants.ELEMENT_TYPE_BADGE) {
            elementId = routerState?.badge;
        }
        if (type === constants.ELEMENT_TYPE_CERTIFICATE) {
            elementId = routerState?.certificate;
        }
        if (elementId) {
            history.push(`/elements/${elementId}`, {
                overrideActiveRoute: '/activities'
            });
        } else {
            showToastError('Unable to find added reward element information.');
        }
    };

    const onDeleteElement = async (type) => {
        let elementId = '';
        if (type === constants.ELEMENT_TYPE_BADGE) {
            elementId = routerState?.badge;
        }
        if (type === constants.ELEMENT_TYPE_CERTIFICATE) {
            elementId = routerState?.certificate;
        }
        if (elementId) {
            const confirm = window.confirm('Confirm element deletion.');
            if (confirm) {
                const { organizationId: orgId } = profile;
                let payload = {
                    ...routerState,
                    formData: activityData,
                    allReceivers,
                    referrerPath: '/activities/add/new',
                    overrideActiveRoute: '/activities'
                };

                await deleteElement({
                    orgId,
                    elementId,
                    passwordNotRequired: true
                });

                if (type === constants.ELEMENT_TYPE_BADGE) {
                    payload['type'] = constants.ELEMENT_TYPE_BADGE;
                    payload['badge'] = null;
                }
                if (type === constants.ELEMENT_TYPE_CERTIFICATE) {
                    payload['type'] = constants.ELEMENT_TYPE_CERTIFICATE;
                    payload['certificate'] = null;
                }
                history.push('/activities/add/new', payload);
            }
        } else {
            showToastError('Unable to find added reward element information.');
        }
    };

    const onClickCancel = () => {
        history.push('/activities');
    };

    const onSelectFile = (e) => {
        const file = e.target.files[0];
        if (file?.size && file?.name) {
            setSelectedFileName(file.name);
            Papa.parse(file, {
                header: true,
                skipEmptyLines: true,
                complete: (results) => {
                    const csvReceivers =
                        results.data.filter(({ name, email }) => !!(name && email)) || [];
                    onAddReceiversFromCSV(csvReceivers);
                },
                error: (error) => {
                    showToastError(`File parsing error. ${error}`);
                }
            });
        }
    };

    const onClickUploadFile = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'text/csv';
        input.onchange = onSelectFile;
        input.click();
    };

    const onClickInviteOne = () => {
        dispatch(
            modalActions.showModal({
                modalType: modalTypes.INVITE_ACTIVITY_RECEIVER_MODAL
            })
        );
    };

    const onClickDownloadTemplate = () => {
        const templateLocation = '/shared/Invite_Multiple_Template.csv';
        const templateName = 'Invite_Multiple_Template.csv';
        downloadURI(templateLocation, templateName);
    };

    const handleOnNavigation = (location, action) => {
        if (
            location.pathname.includes('/activities/add/new') ||
            location.pathname.startsWith('/elements') ||
            SavedRef.current
        ) {
            return true;
        }

        if (
            !!routerState?.badge ||
            !!routerState?.certificate ||
            parsedRewardees.allIds.length > 0
        ) {
            return 'You have unsaved changes, are you sure you want to leave?';
        }
    };

    return (
        <div className="container-fluid p-0">
            <Breadcrumbs rootName="Home" hideDashboard />
            <Form initialValues={activityData} onSubmit={onSaveActivity} enableReinitialize>
                <div className="p-3 bg-white rounded">
                    <div className="container-fluid p-0">
                        <div className="col mb-3">
                            <div className="col">
                                <div className="container-fluid p-0">
                                    <p className="fw-700 text-primary">Activity</p>
                                    <div className="row my-3">
                                        <div className="col-md-6 mb-3">
                                            <TextField
                                                name="name"
                                                label="Activity Name"
                                                placeholder="Activity Name"
                                                onChange={onChangeName}
                                            />
                                        </div>
                                        <div className="row pl-3 mb-3">
                                            <div className="col-md-6">
                                                <DatePicker
                                                    name="start_date"
                                                    label="Starting Date"
                                                    placeholder="Add starting date"
                                                    onChange={onChangeStartDate}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <DatePicker
                                                    name="end_date"
                                                    label="End Date"
                                                    placeholder="Add ending date"
                                                    onChange={onChangeEndDate}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <p className="fw-700 text-primary">
                                        Reward Elements
                                    </p>
                                    <div className="row my-1">
                                        <div className="col-md-2 my-auto fw-500">
                                            <div className="row">
                                                <Checkbox
                                                    name="is_badge"
                                                    onChange={onChangeIsBadge}
                                                />
                                                Badge
                                            </div>
                                        </div>
                                        {
                                            routerState?.badge ?
                                                <div className="row my-2 w-75">
                                                    <div className="col my-3">
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary w-100"
                                                            onClick={() => {
                                                                onEditElement(
                                                                    constants.ELEMENT_TYPE_BADGE
                                                                );
                                                            }}
                                                            disabled={
                                                                !activityData?.is_badge
                                                            }
                                                        >
                                                            Edit Badge
                                                        </button>
                                                    </div>
                                                    <div className="col my-3">
                                                        <button
                                                            type="button"
                                                            className="btn btn-outline-primary w-100"
                                                            onClick={() => {
                                                                onDeleteElement(
                                                                    constants.ELEMENT_TYPE_BADGE
                                                                );
                                                            }}
                                                            disabled={
                                                                !activityData?.is_badge
                                                            }
                                                        >
                                                            Delete Badge
                                                        </button>
                                                    </div>
                                                </div>
                                                :
                                                <div className="row my-2 w-75">
                                                    <div className="col my-3">
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary w-100 py-3"
                                                            onClick={onCreateBadge}
                                                            disabled={
                                                                !activityData?.is_badge
                                                            }
                                                        >
                                                            Create Badge
                                                        </button>
                                                    </div>
                                                    <div className="col my-3"></div>
                                                </div>
                                        }
                                    </div>
                                    <div className="row my-1">
                                        <div className="col-md-2 my-auto fw-500">
                                            <div className="row">
                                                <Checkbox
                                                    name="is_certificate"
                                                    onChange={onChangeIsCertificate}
                                                />
                                                Certificate
                                            </div>
                                        </div>
                                        {
                                            routerState?.certificate ?
                                                <div className="row my-2 w-75" >
                                                    <div className="col my-3">
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary w-100"
                                                            onClick={() => {
                                                                onEditElement(
                                                                    constants.ELEMENT_TYPE_CERTIFICATE
                                                                );
                                                            }}
                                                            disabled={
                                                                !activityData?.is_certificate
                                                            }
                                                        >
                                                            Edit Certificate
                                                        </button>
                                                    </div>
                                                    <div className="col my-3">
                                                        <button
                                                            type="button"
                                                            className="btn btn-outline-primary w-100"
                                                            onClick={() => {
                                                                onDeleteElement(
                                                                    constants.ELEMENT_TYPE_CERTIFICATE
                                                                );
                                                            }}
                                                            disabled={
                                                                !activityData?.is_certificate
                                                            }
                                                        >
                                                            Delete Certificate
                                                        </button>
                                                    </div>
                                                </div>
                                                :
                                                <div className="row my-2 w-75">
                                                    <div className="col my-3">
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary w-100 py-3"
                                                            onClick={
                                                                onCreateCertificate
                                                            }
                                                            disabled={
                                                                !activityData?.is_certificate
                                                            }
                                                        >
                                                            Create Certificate
                                                        </button>
                                                    </div>
                                                    <div className="col my-3"></div>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div> */}
                                    {/* <div className="col">
                                <div className="container-fluid p-0">
                                    <p className="fw-700 text-primary">
                                        Reward Receivers
                                    </p>
                                    <div className="row my-3 mb-4">
                                        <div className="col-md-6 my-3 mb-4">
                                            <h6 className="sm-font-size">Add a group from organization</h6>
                                            <div>
                                                <Select
                                                    value={""}
                                                    placeholder="Select Group"
                                                    onChange={
                                                        onSelectGroupOption
                                                    }
                                                    styles={SelectStyles}
                                                    options={groups?.map(({ group }) => {
                                                        return {
                                                            value: group?.id,
                                                            label: group?.name
                                                        }
                                                    })} />
                                            </div>
                                        </div>
                                        <div className="col-md-6 my-3 mb-4">
                                            <h6 className="sm-font-size">Add individuals from organization</h6>
                                            <div>
                                                <Select
                                                    value={""}
                                                    placeholder="Select Individual"
                                                    onChange={
                                                        onSelectIndividualReceiverOption
                                                    }
                                                    styles={SelectStyles}
                                                    options={receivers?.map(receiver => {
                                                        return {
                                                            value: receiver?.id,
                                                            label: receiver?.name
                                                        }
                                                    })} />
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <div className="row my-3">
                                        <div className="col-md-6 mb-4">
                                            <h6 className="sm-font-size">Invite multiple receivers</h6>
                                            <button
                                                type="button"
                                                className="btn btn-primary py-2 fw-700 w-100 mb-3"
                                                onClick={onClickDownloadTemplate}
                                            >
                                                Download File
                                                <img
                                                    src="/assets/vector/mr-upload-light.svg"
                                                    alt=""
                                                    className="d-inline mx-2"
                                                />
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-primary py-2 fw-700 w-100"
                                                onClick={onClickUploadFile}
                                            >
                                                Upload File
                                                <img
                                                    src="/assets/vector/mr-upload-light.svg"
                                                    alt=""
                                                    className="d-inline mx-2"
                                                />
                                            </button>
                                            {selectedFileName && (
                                                <div className="my-1">
                                                    Selected File:
                                                    <span>
                                                        {selectedFileName}
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-6 mb-4">
                                            <h6 className="sm-font-size">Invite a single receiver</h6>
                                            <button
                                                type="button"
                                                className="btn btn-primary py-2 fw-700 w-100"
                                                onClick={onClickInviteOne}
                                            >
                                                Invite
                                            </button>
                                        </div>
                                    </div> */}
                                    {/* <div className="row my-3">
                                        <div className="container-fluid">
                                            <ScrollTable
                                                rounded
                                                columns={columns}
                                                columnNames={columnNames}
                                                data={parsedRewardees.byId}
                                                rows={parsedRewardees.allIds}
                                                filters={filters}
                                                actions={actions}
                                                onSetFilters={onSetFilters}
                                                pageSize={5}
                                                headerActions={[]}
                                            />
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="row my-3">
                            <div className="col-md-3 offset-md-6">
                                <SubmitButton
                                    title="Save"
                                    className="btn btn-primary fw-700 py-2 w-100"
                                />
                            </div>
                            <div className="col-md-3">
                                <button
                                    type="button"
                                    className="btn text-primary border fw-700 py-2 w-100"
                                    onClick={onClickCancel}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>

            <Prompt message={handleOnNavigation} />
        </div>
    );
};

export default AddActivity;
