import { createApi } from '@rtk-incubator/rtk-query';
import { normaliseObjectArray } from '../utils/dataUtils';
import { baseQueryWithAuth } from './baseQueries';

const userService = createApi({
    reducerPath: 'userService',
    baseQuery: baseQueryWithAuth,
    entityTypes: ['Users'],
    endpoints: (builder) => ({
        getUsers: builder.query({
            query: () => '/users',
            provides: ['Users'],
            transformResponse: (response) => normaliseObjectArray(response)
        }),
        getUser: builder.query({
            query: ({ userId }) => `/users/${userId}`,
            provides: ['Users']
        }),
        addUser: builder.mutation({
            query: ({ username, password }) => ({
                url: '/users',
                method: 'POST',
                body: {
                    username,
                    password
                }
            }),
            invalidates: ['Users']
        }),
        updateUser: builder.mutation({
            query: ({ user, userId }) => ({
                url: `/users/${userId}`,
                method: 'PUT',
                body: {
                    user
                }
            }),
            invalidates: ['Users']
        })
    })
});

export default userService;

export const { useGetUsersQuery, useGetUserQuery } = userService;
