import React from 'react';
import './Switch.styles.scss';

const Switch = ({ value = false, onChange, label = '', confirmAction = false }) => {
    const [isChecked, setIsChecked] = React.useState(false);
    const switchRef = React.createRef();

    React.useEffect(() => {
        setIsChecked(!!value);
    }, [value]);

    const handleChange = (e) => {
        // setIsChecked(e.target.checked);
    };

    const toggleChecked = (e) => {
        e.stopPropagation();
        onChange(!isChecked);
    };

    return (
        <div className="slider-wrapper">
            <div className="switch">
                <span>
                    <input
                        type="checkbox"
                        ref={switchRef}
                        checked={isChecked}
                        onChange={handleChange}
                    />
                    <button className="slider" type="button" onClick={toggleChecked} />
                </span>
                <label>{label}</label>
            </div>
        </div>
    );
};

export default Switch;
