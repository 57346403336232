import React from 'react';
import dayjs from 'dayjs';
import { useHistory, useLocation } from 'react-router';
import * as Yup from 'yup';
import constants from '../../../utils/constants';

import ElementBadge from './ElementBadge';
import ElementCertificate from './ElementCertificate';

import { Form, SubmitButton, Select } from '../../../components/FormElements';
import { BackBtn } from '../../../components/Buttons';

import { useGetProfileQuery } from '../../../services/authService';
import { useAddElementMutation } from '../../../services/elementsService';
import { fileToB64 } from '../../../utils/fileUtils';
import { showToastError } from '../../../utils/toastHelper';
import notifyError, { errors } from '../../../utils/rtkErrorHelper';
import { useFormikContext } from 'formik';

const elementSelectionValidationSchema = Yup.object().shape({
    elementType: Yup.string().required('Please select element type')
});

const elementOptions = [
    { value: constants.ELEMENT_TYPE_CERTIFICATE, label: 'Certificate' },
    { value: constants.ELEMENT_TYPE_BADGE, label: 'Badge' }
];

const AddElement = () => {
    const history = useHistory();

    const { state: routerState } = useLocation();
    const {
        data: profile,
        error: getProfileError,
        isError: isGetProfileError
    } = useGetProfileQuery();
    const [
        addElement,
        { isError: isAddElementError, error: addElementError }
    ] = useAddElementMutation();

    React.useEffect(() => {
        notifyError(isGetProfileError, getProfileError, errors.AUTH_GET_PROFILE);
        notifyError(isAddElementError, addElementError, errors.ELEMENT_ADD);
    }, [getProfileError, isGetProfileError, isAddElementError, addElementError]);

    const [isReferedByActivities, setIsReferedByActivities] = React.useState(false);
    const [elementType, setElementType] = React.useState(undefined);
    const [elementTypeConfirmed, setElementTypeConfirmed] = React.useState(false);

    const [elementData, setElementData] = React.useState({
        elementType: ''
    });

    React.useEffect(() => {
        if (routerState && routerState?.type) {
            setIsReferedByActivities(true);
            setElementType(routerState.type);
            setElementTypeConfirmed(true);
        }
    }, [routerState]);

    const onClickNext = ({ elementType: type }, { setSubmitting }) => {
        if (type) {
            setSubmitting(false);
            setElementType(type);
            setElementTypeConfirmed(true);
        }
    };

    const onSaveAsElement = (data) => {};

    const onCancel = () => {
        history.goBack();
    };

    const onClickBack = () => {
        history.goBack();
    };

    const onAddElement = async (element) => {
        const payload = {
            element,
            orgId: profile?.organizationId
        };
        const response = await addElement(payload);

        if (response?.error) {
            return showToastError(response?.error.message);
        }

        if (isReferedByActivities) {
            // push to edit-activity with state
            if (element?.type === constants.ELEMENT_TYPE_BADGE) {
                const badge = response.data.id;
                history.push(routerState?.referrerPath, {
                    ...routerState,
                    ...{ badge }
                });
            }
            if (element?.type === constants.ELEMENT_TYPE_CERTIFICATE) {
                const certificate = response.data.id;
                history.push(routerState?.referrerPath, {
                    ...routerState,
                    ...{ certificate }
                });
            }
        } else {
            history.push('/elements');
        }
    };

    const onSaveBadgeData = async (values) => {
        const badge = await fileToB64(values?.badge);
        const issuer_image = await fileToB64(values?.issuer_image);
        const receiver_image = await fileToB64(values?.receiver_image);
        const badgeData = {
            ...values,
            ...{
                badge,
                issuer_image,
                receiver_image
            }
        };
        await onAddElement(badgeData);
    };

    const onSaveCertificateData = async (values) => {
        try {
            const certificate_logo = await fileToB64(values?.certificate_logo);
            const signature_one = await fileToB64(values?.signature_one);
            const signature_two = await fileToB64(values?.signature_two);
            const certificate = {
                ...values,
                ...{
                    certificate_logo,
                    signature_one,
                    signature_two,
                    date: dayjs(values.date).toISOString()
                }
            };
            await onAddElement(certificate);
        } catch (err) {
            showToastError(
                `Error occurred while trying to save certificate data. ${err?.toString() || ''}`
            );
        }
    };

    return (
        <div className="container-fluid px-2">
            {!elementTypeConfirmed && (
                <div className="mt-2 p-3 bg-white rounded">
                    <BackBtn onClick={onClickBack} />
                    <Form
                        initialValues={elementData}
                        onSubmit={onClickNext}
                        validationSchema={elementSelectionValidationSchema}
                        enableReinitialize>
                        <div className="row py-5">
                            <div className="col-md-6">
                                <Select
                                    name="elementType"
                                    options={elementOptions}
                                    label="Choose Element Type"
                                />
                            </div>
                        </div>
                        <SubmitButton
                            title="Next"
                            className="btn btn-primary org-user-elements-add-element-next-btn"
                        />
                    </Form>
                </div>
            )}
            {elementTypeConfirmed && elementType === constants.ELEMENT_TYPE_BADGE && (
                <ElementBadge
                    onSave={onSaveBadgeData}
                    onSaveAs={onSaveAsElement}
                    onCancel={onCancel}
                />
            )}
            {elementTypeConfirmed && elementType === constants.ELEMENT_TYPE_CERTIFICATE && (
                <ElementCertificate
                    organizationId={profile?.organizationId}
                    onSave={onSaveCertificateData}
                    onSaveAs={onSaveAsElement}
                    onCancel={onCancel}
                />
            )}
        </div>
    );
};

export default AddElement;
