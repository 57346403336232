import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import EditableLogo from '../../../components/EditableLogo';
import Divider from '../../../components/Divider';
import { BackBtn } from '../../../components/Buttons';
import constants from '../../../utils/constants';
import { getDisplayValueForUserRole } from '../../../utils/dataUtils';
import notifyError, { errors } from '../../../utils/rtkErrorHelper';

import { useGetProfileQuery } from '../../../services/authService';
import { useGetOrgUserQuery, useMakeUserInactiveMutation } from '../../../services/orgService';
import Prompt from '../../../components/Prompt';

const UserDetails = () => {
    const history = useHistory();
    const params = useParams();
    const { state: routerState } = useLocation();

    const [userAvatar, setUserAvatar] = React.useState(null);
    const [showUserDeletePrompt, setShowUserDeletePrompt] = React.useState(false);

    const [
        makeUserInactive,
        { error: makeUserInactiveError, isError: isMakeUserInactiveError }
    ] = useMakeUserInactiveMutation();

    const {
        data: profile,
        error: getProfileError,
        isError: isGetProfileError
    } = useGetProfileQuery();

    const { data: userdata } = useGetOrgUserQuery(
        { userId: routerState?.userId, orgId: profile?.organizationId },
        { skip: !(profile?.organizationId && routerState?.userId) }
    );

    const parseUserData = () => {
        if (userdata) {
            const { avatar } = userdata;
            setUserAvatar(avatar);
        }
    };

    React.useEffect(() => {
        notifyError(isGetProfileError, getProfileError, errors.AUTH_GET_PROFILE);
        notifyError(
            isMakeUserInactiveError,
            makeUserInactiveError,
            errors.ORGANIZATION_USER_UPDATE
        );
    }, [getProfileError, isGetProfileError, isMakeUserInactiveError, makeUserInactiveError]);

    React.useEffect(() => {
        if (!routerState?.userId) {
            history.push('/home/users');
        }
    }, [routerState]);

    React.useEffect(() => {
        parseUserData();
    }, [userdata]);

    const isAdminAndMyProfile = React.useMemo(() => {
        let flag = false;
        if (profile?.id === Number(routerState?.userId)) {
            if (profile?.role === constants.ROLE_ORG_USER) {
                flag = true;
            }
        }

        if (profile?.id !== Number(routerState?.userId)) {
            flag = true;
        }

        return flag;
    }, [profile, routerState]);

    const onClickBack = () => {
        history.push('/home/users');
    };

    const onClickEdit = () => {
        const { id: userId } = userdata;
        history.push(`/home/users/edit/${params.username}`, { userId });
    };

    const showDeleteConfirmation = () => {
        setShowUserDeletePrompt(true);
    };

    const onClickDelete = async (password) => {
        const orgId = profile?.organizationId;
        const { id: userId } = userdata;
        await makeUserInactive({ orgId, userId, password });
    };

    const onCloseDeletePrompt = () => {
        setShowUserDeletePrompt(false);
    };

    return (
        <div>
            <div className="container-fluid py-4 px-5 mt-4 bg-white rounded">
                <div className="row mb-4 px-2 justify-content-between">
                    <BackBtn title="User Profile" onClick={onClickBack} />
                    <div className="d-flex">
                        <button
                            type="button"
                            className="org-user-profile-action-btn mx-1"
                            onClick={onClickEdit}>
                            <img src="/assets/vector/mr-btn-update.svg" alt="Edit" />
                        </button>
                        {isAdminAndMyProfile && (
                            <button
                                type="button"
                                className="org-user-profile-action-btn mx-1"
                                onClick={showDeleteConfirmation}>
                                <img src="/assets/vector/mr-btn-delete.svg" alt="Delete" />
                            </button>
                        )}
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-lg-2">
                        <EditableLogo
                            logo={userAvatar || '/assets/img/mr-avatar-placeholder-2.png'}
                        />
                    </div>
                    <div className="col-lg-6">
                        <div className="container-fluid">
                            <div className="row mb-4">
                                <div className="col-md-5 fw-500">First Name</div>
                                <div className="col-md-7">
                                    <div className="text-truncate">{userdata?.first_name}</div>
                                </div>
                            </div>
                            <Divider />
                            <div className="row my-4">
                                <div className="col-md-5 fw-500">Last Name</div>
                                <div className="col-md-7">
                                    <div className="text-truncate">{userdata?.last_name}</div>
                                </div>
                            </div>
                            <Divider />
                            <div className="row my-4">
                                <div className="col-md-5 fw-500">Username</div>
                                <div className="col-md-7">
                                    <div className="text-truncate">{userdata?.username}</div>
                                </div>
                            </div>
                            <Divider />
                            <div className="row my-4">
                                <div className="col-md-5 fw-500">Email</div>
                                <div className="col-md-7">
                                    <div className="text-truncate">{userdata?.email}</div>
                                </div>
                            </div>
                            <Divider />
                            {isAdminAndMyProfile && (
                                <>
                                    <div className="row my-4">
                                        <div className="col-md-5 fw-500">Role</div>
                                        <div className="col-md-7">
                                            <div className="text-truncate">
                                                {getDisplayValueForUserRole(userdata?.role)}
                                            </div>
                                        </div>
                                    </div>
                                    <Divider />
                                </>
                            )}
                            <div className="row my-4">
                                <div className="col-md-5 fw-500">Status</div>
                                <div className="col-md-7">
                                    <div className="text-truncate text-capitalize">
                                        {userdata?.is_active ? 'Active' : 'Inactive'}
                                    </div>
                                </div>
                            </div>
                            <Divider />
                        </div>
                    </div>
                </div>
            </div>
            <Prompt
                show={showUserDeletePrompt}
                onClose={onCloseDeletePrompt}
                onDone={onClickDelete}
                title="Password Confirmation"
                message="Confirm deletion. Please enter your password."
                inputProps={{
                    type: 'password',
                    placeholder: 'Password'
                }}
            />
        </div>
    );
};

export default UserDetails;
