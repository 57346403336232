import { createApi } from '@rtk-incubator/rtk-query';
import { baseQueryWithAuth } from './baseQueries';

const activityService = createApi({
    reducerPath: 'activityService',
    baseQuery: baseQueryWithAuth,
    entityTypes: ['Activities', 'Activity', 'ActivityRewardees'],
    endpoints: (builder) => ({
        getActivities: builder.query({
            query: ({ orgId }) => `/organizations/${orgId}/reward-activities/`,
            provides: ['Activities']
        }),
        getActivity: builder.query({
            query: ({ orgId, activityId }) =>
                `/organizations/${orgId}/reward-activities/${activityId}`,
            provides: ['Activity']
        }),
        addActivity: builder.mutation({
            query: ({ activity, orgId }) => ({
                url: `/organizations/${orgId}/reward-activities/`,
                method: 'POST',
                body: activity
            }),
            invalidates: ['Activities']
        }),
        updateActivity: builder.mutation({
            query: ({ activity, orgId, activityId }) => ({
                url: `/organizations/${orgId}/reward-activities/${activityId}`,
                method: 'PUT',
                body: activity
            }),
            invalidates: ['Activities', 'Activity', 'ActivityRewardees']
        }),
        resetActivityElements: builder.mutation({
            query: ({ orgId, activityId, elementId }) => ({
                url: `/organizations/${orgId}/reward-activities/${activityId}/reset-elements/${elementId}/`,
                method: 'PUT'
            }),
            invalidates: ['Activity', 'Activities']
        }),
        deleteActivity: builder.mutation({
            query: ({ orgId, activityId, password }) => ({
                url: `/organizations/${orgId}/reward-activities/${activityId}`,
                method: 'DELETE',
                body: { password }
            }),
            invalidates: ['Activities']
        }),
        getRewardees: builder.query({
            query: ({ orgId, activityId }) =>
                `/organizations/${orgId}/reward-activities/${activityId}/rewardees/`,
            provides: ['ActivityRewardees']
        }),
        getIssuedRewards: builder.query({
            query: ({ orgId }) => `/organizations/${orgId}/reward-activities/rewards/issued`
        }),
        addGroupRewardees: builder.mutation({
            query: ({ orgId, activityId, groupId }) => ({
                url: `/organizations/${orgId}/reward-activities/${activityId}/rewardees/`,
                method: 'POST',
                body: { groupId }
            }),
            invalidates: ['ActivityRewardees']
        }),
        addIndividualRewardee: builder.mutation({
            query: ({ orgId, activityId, receivers }) => ({
                url: `/organizations/${orgId}/reward-activities/${activityId}/rewardees/`,
                method: 'POST',
                body: { receivers }
            }),
            invalidates: ['ActivityRewardees']
        }),
        removeIndividualRewardee: builder.mutation({
            query: ({ orgId, activityId, receivers }) => ({
                url: `/organizations/${orgId}/reward-activities/${activityId}/rewardees/`,
                method: 'DELETE',
                body: { receivers }
            }),
            invalidates: ['ActivityRewardees']
        }),
        generateRewardsForRewardee: builder.mutation({
            query: ({ orgId, activityId, payload }) => {
                return {
                    url: `/organizations/${orgId}/reward-activities/${activityId}/rewards/`,
                    method: 'POST',
                    body: payload
                };
            },
            invalidates: ['ActivityRewardees']
        }),
        resetGeneratedRewards: builder.mutation({
            query: ({ orgId, activityId }) => ({
                url: `/organizations/${orgId}/reward-activities/${activityId}/rewards/`,
                method: 'PUT',
                body: { reset: true }
            }),
            invalidates: ['ActivityRewardees']
        }),
        shareGeneratedRewards: builder.mutation({
            query: ({ orgId, activityId }) => ({
                url: `/organizations/${orgId}/reward-activities/${activityId}/rewards/`,
                method: 'PUT'
            }),
            invalidates: ['ActivityRewardees']
        }),
        shareSelectedRewardAgain: builder.mutation({
            query: ({ orgId, activityId, receiverId, body }) => ({
                url: `/organizations/${orgId}/reward-activities/${activityId}/rewards/${receiverId}`,
                method: 'PUT',
                body
            }),
            invalidates: ['ActivityRewardees']
        })
    })
});

export default activityService;

export const {
    useGetActivitiesQuery,
    useGetActivityQuery,
    useAddActivityMutation,
    useUpdateActivityMutation,
    useResetActivityElementsMutation,
    useDeleteActivityMutation,
    useGetRewardeesQuery,
    useGetIssuedRewardsQuery,
    useAddGroupRewardeesMutation,
    useAddIndividualRewardeeMutation,
    useRemoveIndividualRewardeeMutation,
    useGenerateRewardsForRewardeeMutation,
    useResetGeneratedRewardsMutation,
    useShareGeneratedRewardsMutation,
    useShareSelectedRewardAgainMutation
} = activityService;
