import React from 'react';
import dayjs from 'dayjs';
import _ from 'lodash';
import ReactDatePicker from 'react-datepicker';
import constants from '../../utils/constants';
import './PaginatedTableHeader.styles.scss';

const PaginatedTableHeader = ({ columnNames, filters, actions, onSetFilters, rounded = false }) => {
    const [activeFilters, setActiveFilters] = React.useState([]);
    const onChangeFilterData = (value, filterIdx) => {
        const newFilters = activeFilters.slice();
        if (filters.length) {
            filters.forEach((filter, idx) => {
                if (idx === filterIdx) {
                    newFilters[idx] = value?.toString();
                }
                if (!filter) {
                    newFilters[idx] = '';
                }
            });
        }
        setActiveFilters(newFilters);
        if (actions && onSetFilters) {
            onSetFilters(newFilters);
        }
    };

    const setFilters = (e) => {
        e.stopPropagation();
        if (onSetFilters && typeof onSetFilters === 'function') {
            onSetFilters(activeFilters);
        }
    };

    const renderFilterOrColumnName = (filterIdx) => {
        const filter = filters?.[filterIdx];
        if (filter?.type === constants.FILTER_TYPE_TEXT) {
            return (
                <div className="input-group">
                    <div className="input-group-prepend">
                        <div className="filter-icon-container">
                            <div className="filter-icon-search" />
                        </div>
                    </div>
                    <input
                        className="form-control form-control-sm border-0 fw-700 text-truncate"
                        type="text"
                        placeholder={`Search ${columnNames[filterIdx]}` || ''}
                        onChange={(e) => {
                            const { value } = e.target;
                            onChangeFilterData(value, filterIdx);
                        }}
                    />
                </div>
            );
        }
        if (filter?.type === constants.FILTER_TYPE_OPTIONS) {
            return (
                <select
                    className="form-control form-control-sm mr-5 border-0 fw-700 text-truncate"
                    onChange={(e) => {
                        const { value } = e.target;
                        onChangeFilterData(value, filterIdx);
                    }}>
                    <option value="">
                        {columnNames[filterIdx] ? `${columnNames[filterIdx]}: All` : 'Clear'}
                    </option>
                    {filter?.options &&
                        filter.options.map((option) => (
                            <option key={`${filterIdx}-${option.value}`} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                </select>
            );
        }
        if (filter?.type === constants.FILTER_TYPE_DATE) {
            return (
                <div className="input-group">
                    <ReactDatePicker
                        className="form-control form-control-sm border-0 fw-700"
                        placeholderText={`Search ${columnNames[filterIdx]}` || ''}
                        value={activeFilters[filterIdx]}
                        onChange={(value) => {
                            const dateStr = dayjs(value).format('YYYY-MM-DD');
                            onChangeFilterData(dateStr, filterIdx);
                        }}
                    />
                    <div className="input-group-prepend">
                        {!activeFilters[filterIdx] && (
                            <div className="filter-icon-container">
                                <div className="filter-icon-calendar" />
                            </div>
                        )}
                        {activeFilters[filterIdx] && (
                            <div className="filter-icon-container">
                                <button
                                    type="button"
                                    className="filter-icon-close"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onChangeFilterData('', filterIdx);
                                    }}>
                                    ✕
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            );
        }
        const columnNameContent = columnNames[filterIdx];
        return _.isFunction(columnNameContent) ? columnNameContent() : columnNameContent;
    };

    return (
        <thead className="paginated-table-head">
            <tr>
                {columnNames.map((cn, idx) => (
                    <th key={idx} className="align-middle">
                        <div
                            className={`bg-white content ${
                                rounded && idx === 0 ? 'rounded-top-left rounded-bottom-left' : ''
                            }`}>
                            {renderFilterOrColumnName(idx) || ''}
                        </div>
                    </th>
                ))}
                {!actions && filters && filters.length > 0 && (
                    <th className="align-middle">
                        <div
                            className={`bg-white content ${
                                rounded ? 'rounded-top-right rounded-bottom-right' : ''
                            }`}>
                            <button
                                onClick={setFilters}
                                type="button"
                                className="btn btn-primary px-5 fw-700">
                                Search
                            </button>
                        </div>
                    </th>
                )}
                {actions && (
                    <th className="align-middle">
                        <div
                            className={`bg-white content ${
                                rounded ? 'rounded-top-right rounded-bottom-right' : ''
                            }`}>
                            {actions}
                        </div>
                    </th>
                )}
            </tr>
        </thead>
    );
};

export default PaginatedTableHeader;
