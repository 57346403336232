import React from 'react';
import { Route, Switch } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import Tabs from '../../components/Tabs';
import AddUser from './Users/AddUser';
import EditUser from './Users/EditUser';

import Profile from './Profile';
import Users from './Users/Users';
import UserDetails from './Users/UserDetails';
import VerifyDomain from './VerifyDomain';
import ProfileEdit from './ProfileEdit';

export default function OrgAdminDashboard() {
    const { url } = useRouteMatch();

    const tabs = [
        {
            label: 'Dashboard',
            route: `${url}`
        },
        {
            label: 'Profile',
            route: `${url}/profile`,
            includeRoutes: [`${url}/profile/edit`]
        },
        {
            label: 'Users',
            route: `${url}/users`
        }
    ];

    return (
        <div className="overflow-auto">
            <Tabs items={tabs} />
            <Switch>
                <Route component={Profile} path={`${url}/profile`} exact />
                <Route component={ProfileEdit} path={`${url}/profile/edit`} exact />
                <Route component={VerifyDomain} path={`${url}/profile/verify-domain`} exact />
                <Route component={Users} path={`${url}/users`} exact />
                <Route component={AddUser} path={`${url}/users/add/new`} exact />
                <Route component={UserDetails} path={`${url}/users/:username`} exact />
                <Route component={EditUser} path={`${url}/users/edit/:username`} exact />
            </Switch>
        </div>
    );
}
