import { createApi } from '@rtk-incubator/rtk-query';
import { baseQueryWithAutoLogout } from './baseQueries';

const appService = createApi({
    reducerPath: 'appService',
    baseQuery: baseQueryWithAutoLogout,
    endpoints: (builder) => ({
        signIn: builder.mutation({
            query: ({ username, password }) => ({
                url: '/login',
                method: 'POST',
                body: {
                    username,
                    password
                }
            })
        }),
        signUp: builder.mutation({
            query: ({ user }) => ({
                url: '/register',
                method: 'POST',
                body: {
                    user
                }
            })
        }),
        getUsers: builder.query({
            query: () => '/api/users'
        })
    })
});

export default appService;
