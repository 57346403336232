import React from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { useGetProfileQuery } from '../services/authService';
import { useInviteReceiversMutation } from '../services/receiverService';
import notifyError, { errors } from '../utils/rtkErrorHelper';
import { showToastSuccess } from '../utils/toastHelper';
import { Form, TextField, SubmitButton } from './FormElements';
import { modalActions } from '../state/modal';
import { getCurrentEnv } from '../utils/dataUtils';

const inviteDataValidationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required')
});

const InviteRecieverModal = () => {
    const dispatch = useDispatch();
    const [inviteData, setInviteData] = React.useState({
        name: '',
        email: ''
    });

    const { data: profile, isError: isProfileError, error: profileError } = useGetProfileQuery();

    const [
        inviteReceivers,
        {
            error: inviteReceiversError,
            isError: isInviteReceiversError,
            isSuccess: isInviteReceiversSuccess
        }
    ] = useInviteReceiversMutation();

    React.useEffect(() => {
        notifyError(isProfileError, profileError, errors.AUTH_GET_PROFILE);
        notifyError(isInviteReceiversError, inviteReceiversError, errors.RECEIVERS_INVITE);
    }, [isProfileError, profileError, isInviteReceiversError, inviteReceiversError]);

    React.useEffect(() => {
        if (isInviteReceiversSuccess) {
            showToastSuccess('Invitation sent.');
            dispatch(modalActions.hideModal());
        }
    }, [isInviteReceiversSuccess]);

    const onInvite = async (values) => {
        const { organizationId: orgId } = profile;
        const env = getCurrentEnv();
        await inviteReceivers({ orgId, receivers: [values], env });

        dispatch(
            modalActions.stateModel({
                type: 'INVITED_USER_STATE',
                INVITED_USER_STATE: values
            })
        );
    };

    return (
        <div id="inviteReceiverModel">
            <div className="h5 text-center bg-primary p-3">Invite New Receiver</div>
            <div className="py-4 px-3">
                <Form
                    initialValues={inviteData}
                    validationSchema={inviteDataValidationSchema}
                    onSubmit={onInvite}
                    enableReinitialize>
                    <div className="form">
                        <div className="input-wrap">
                            <p className="title">Name</p>
                            <TextField name="name" />
                        </div>
                        <div className="input-wrap">
                            <p className="title">Email</p>
                            <TextField name="email" />
                        </div>
                        <div className="row my-3 justify-content-center">
                            <SubmitButton title="Send Invite" className="btn btn-primary px-5" />
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default InviteRecieverModal;
