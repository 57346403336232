import React, { useState } from 'react';
import * as _ from 'lodash';
import PaginatedTableHeader from '../PaginatedTableHeader/PaginatedTableHeader';

import './PaginatedTable.styles.scss';
import { Pagination } from '@mui/material';

export default function PaginatedTable({
    columns,
    columnNames,
    activeFilters,
    filters,
    rows,
    data,
    actions,
    headerActions,
    pagination = false,
    pageSize = 5,
    selectedRows = [],
    setSelectedRows,
    selectableRows = false,
    onRowClick,
    onSetFilters,
    rounded = false
}) {
    const [currentPage, setCurrentPage] = useState(1);
    const [selectAll, setSelectAll] = useState(false);

    const paginatedRows = pagination
        ? rows.slice((currentPage - 1) * pageSize, currentPage * pageSize)
        : rows;

    const totalPages = Math.ceil(rows && rows.length / pageSize);

    const getOriginalId = (rowIdx) => (currentPage - 1) * pageSize + rowIdx;

    const isRowSelected = (rowIdx) => {
        const originalIdx = getOriginalId(rowIdx);
        return selectedRows ? selectedRows.includes(originalIdx) : false;
    };

    const toggleSelectRow = (rowIdx) => {
        setSelectAll(false);
        const originalIdx = getOriginalId(rowIdx);
        const selectedRowsCopy = selectedRows.slice();
        if (isRowSelected(rowIdx)) {
            const index = selectedRowsCopy.indexOf(originalIdx);
            selectedRowsCopy.splice(index, 1);
        } else {
            selectedRowsCopy.push(originalIdx);
        }
        if (setSelectedRows) {
            setSelectedRows(selectedRowsCopy);
        }
    };

    const toggleSelectAll = () => {
        if (selectAll) {
            // deselect all
            if (setSelectedRows) {
                setSelectedRows([]);
            }
        } else {
            // select all
            if (setSelectedRows) {
                setSelectedRows(Array.from(Array(pageSize).keys()).map((i) => getOriginalId(i)));
            }
        }
        setSelectAll(!selectAll);
    };

    const resetSelectAll = () => {
        if (setSelectedRows) {
            setSelectedRows([]);
        }
        setSelectAll(false);
    };

    const getEmptyMarker = () => <div style={{ textAlign: 'center', width: '100%' }} />;

    const renderRoundedBorders = (rowIdx, colIdx) => {
        const classes = [];
        if (rounded) {
            if (rowIdx === 0) {
                if (colIdx === 0) {
                    classes.push('rounded-top-left');
                }
                if (!actions && colIdx === columns.length - 1) {
                    classes.push('rounded-top-right');
                }
            }
            if (rowIdx === rows.length - 1) {
                if (colIdx === 0) {
                    classes.push('rounded-bottom-left');
                }
                if (!actions && colIdx === columns.length - 1) {
                    classes.push('rounded-bottom-right');
                }
            }
        }
        return classes.join(' ');
    };

    const renderRoundedActionBorders = (rowIdx) => {
        const classes = [];
        if (rounded) {
            if (rowIdx === 0) {
                classes.push('rounded-top-right');
            }
            if (rowIdx === rows.length - 1) {
                classes.push('rounded-bottom-right');
            }
        }
        return classes.join(' ');
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    return (
        <div className="paginated-table">
            <div className="table-responsive">
                <table className="table table-borderless">
                    {columnNames || actions || filters ? (
                        <PaginatedTableHeader
                            columnNames={columnNames}
                            actions={headerActions}
                            filters={filters}
                            activeFilters={activeFilters}
                            onSetFilters={onSetFilters}
                            rounded={rounded}
                        />
                    ) : (
                        ''
                    )}
                    <tbody>
                        {paginatedRows &&
                            paginatedRows.map((rowId, rowIdx) => (
                                <tr
                                    key={rowId}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        if (onRowClick) {
                                            onRowClick(data[rowId]);
                                        }
                                    }}>
                                    {selectableRows && (
                                        <td>
                                            <div className="custom-checkbox">
                                                <div className="checkbox">
                                                    <input
                                                        type="checkbox"
                                                        id={`table_checkbox${rowId}`}
                                                        checked={isRowSelected(rowIdx)}
                                                        onChange={() => {
                                                            toggleSelectRow(rowIdx);
                                                        }}
                                                    />
                                                    <label htmlFor={`table_checkbox${rowId}`}>
                                                        {' '}
                                                    </label>
                                                </div>
                                            </div>
                                        </td>
                                    )}
                                    {columns.map((c, idx) => {
                                        return (
                                            <td className="fw-500 align-middle" key={idx}>
                                                <div
                                                    className={`bg-white content ${renderRoundedBorders(
                                                        rowIdx,
                                                        idx
                                                    )}`}>
                                                    {_.isFunction(c)
                                                        ? c(data[rowId]) || getEmptyMarker()
                                                        : _.get(data[rowId], c, null) ||
                                                          getEmptyMarker()}
                                                </div>
                                            </td>
                                        );
                                    })}
                                    {actions && actions.length > 0 && (
                                        <td className="align-middle">
                                            <div
                                                className={`bg-white content text-truncate p-0 d-block ${renderRoundedActionBorders(
                                                    rowIdx
                                                )}`}>
                                                {actions.map((action, idx) => (
                                                    <button
                                                        type="button"
                                                        key={idx}
                                                        className={`btn ${
                                                            action?.isIcon
                                                                ? 'h-100'
                                                                : 'btn-sm btn-outline-primary btn-block'
                                                        }`}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            action.action(data[rowId]);
                                                        }}>
                                                        {action.name}
                                                    </button>
                                                ))}
                                            </div>
                                        </td>
                                    )}
                                </tr>
                            ))}
                    </tbody>
                </table>
                {pagination && rows?.length > 0 && (
                    <div className="pagination-wrap">
                        <Pagination
                            page={currentPage}
                            onChange={handlePageChange}
                            count={Math.ceil(rows.length / pageSize)}
                            variant="outlined"
                            shape="rounded"
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
