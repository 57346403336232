import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';

import { loadAllUsers } from '../state/user';

import LayoutRoutes from '../routes/LayoutRoutes';
import Sidebar from '../components/Sidebar';
import VerifiedTitle from '../components/VerifiedTitle';

import notifyError, { errors } from '../utils/rtkErrorHelper';
import { useGetOrganizationQuery, useGetMyOrgUserProfileQuery } from '../services/orgService';
import { getDisplayValueForUserRole } from '../utils/dataUtils';

const Layout = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const {
        data: profile,
        isError: isGetProfileError,
        error: getProfileError
    } = useGetMyOrgUserProfileQuery();

    const {
        data: organization,
        isError: isGetOrganizationError,
        error: getOrganizationError
    } = useGetOrganizationQuery(
        { orgId: profile?.organizationId },
        { skip: !profile?.organizationId }
    );

    React.useEffect(() => {
        notifyError(isGetProfileError, getProfileError, errors.AUTH_GET_PROFILE);
        notifyError(isGetOrganizationError, getOrganizationError, errors.ORGANIZATION_RETRIEVE);
    }, [isGetProfileError, getProfileError, isGetOrganizationError, getOrganizationError]);

    useEffect(() => {
        dispatch(loadAllUsers());
    }, [dispatch]);

    const userInfo = React.useMemo(() => {
        const details = {
            name: '',
            role: '',
            avatar: ''
        };
        if (profile) {
            let name = '';
            const { first_name: fname, last_name: lname, role, avatar } = profile;

            if (fname) {
                name = name.concat(fname);
            }
            if (lname) {
                name = name.concat(` ${lname}`);
            }
            details.name = name;
            details.role = getDisplayValueForUserRole(role);
            details.avatar = avatar;
        }
        return details;
    }, [profile]);

    const orgInfo = React.useMemo(() => {
        const details = {
            name: '',
            isVerified: false
        };

        if (organization) {
            const { name, is_verified: isVerified } = organization;
            details.name = name || '';
            details.isVerified = !!isVerified;
        }
        return details;
    }, [organization]);

    const onClickUserInfo = () => {
        history.push('/my-profile');
    };

    return (
        <div>
            <div className="container-fluid">
                <div className="row">
                    <Sidebar />
                    <main role="main" className="col-md-9 ml-sm-auto col-lg-10 layout-main">
                        <nav className="navbar sticky-top flex-md-nowrap p-0 pb-1 align-items-end">
                            <div className="order-2 order-sm-1">
                                <VerifiedTitle
                                    title={orgInfo.name}
                                    isVerified={orgInfo.isVerified}
                                />
                            </div>
                            <ul className="navbar-nav px-3 ml-auto order-1 order-sm-2">
                                <li className="nav-item text-nowrap">
                                    <button
                                        type="button"
                                        className="layout-navitem-notifications-button">
                                        {/* <div className="dot" /> */}
                                    </button>
                                    <div
                                        onClick={onClickUserInfo}
                                        className="layout-navitem-user-info">
                                        <img
                                            src={
                                                userInfo.avatar ||
                                                '/assets/img/mr-avatar-placeholder-2.png'
                                            }
                                            alt=""
                                            className="user-info-avatar"
                                        />
                                        <div className="user-info-text">
                                            <div className="username text-truncate">
                                                {userInfo.name} 👋
                                            </div>
                                            <div className="type text-truncate">
                                                {userInfo.role}
                                            </div>
                                        </div>
                                        {/* <img
                                            src="/assets/vector/mr-chevron-down.svg"
                                            alt=""
                                            className="chevron-down"
                                        /> */}
                                    </div>
                                </li>
                            </ul>
                        </nav>
                        <div className="border-bottom mb-4" />
                        <LayoutRoutes />
                    </main>
                </div>
            </div>
        </div>
    );
};

export default Layout;
