import React from 'react';
import dayjs from 'dayjs';
import * as Yup from 'yup';
import { Prompt } from 'react-router';
import {
    TextField,
    Select,
    SubmitButton,
    FileField,
    Form,
    MultilineTextField,
    Checkbox
} from '../../../components/FormElements';
import Breadcrumbs from '../../../components/Breadcrumbs';
import constants from '../../../utils/constants';
import { getDisplayValueForElementType } from '../../../utils/dataUtils';
import TemplatesDropdown from './TemplatesDropdown';
import { b64ToFile } from '../../../utils/fileUtils';

const SUPPORTED_FORMATS = ['image/svg+xml'];
const validationSchema = Yup.object().shape({
    name: Yup.string().required('Badge name is required'),
    description: Yup.string().required('Badge description is required'),
    badge: Yup.mixed().test(
        'fileFormat',
        'Unsupported Format only SVGs are allowed',
        (value) => value && SUPPORTED_FORMATS.includes(value.type)
    )
});

const maxCustomAttributes = 10;

const elementTypeOptions = [
    {
        value: constants.ELEMENT_TYPE_BADGE,
        label: getDisplayValueForElementType(constants.ELEMENT_TYPE_BADGE)
    },
    {
        value: constants.ELEMENT_TYPE_CERTIFICATE,
        label: getDisplayValueForElementType(constants.ELEMENT_TYPE_CERTIFICATE)
    }
];

const ElementBadge = ({ onSave, onCancel, badge }) => {
    const [editingCustomAttributeIdx, setEditingCustomAttributeIdx] = React.useState(null);
    const [selectedLogo, setSelectedLogo] = React.useState('');
    const [hasChanges, setHasChanges] = React.useState(false);
    const [badgeData, setBadgeData] = React.useState({
        name: '',
        type: constants.ELEMENT_TYPE_BADGE,
        badge: '',
        description: '',
        receiver_image: '',
        issuer_image: '',
        is_template: false,
        custom_attributes: [
            {
                label: 'Custom Attribute 1',
                value: 'Custom value 1'
            },
            {
                label: 'Custom Attribute 2',
                value: 'Custom value 2'
            }
        ]
    });

    React.useEffect(() => {
        return () => {
            URL.revokeObjectURL(selectedLogo);
        };
    }, []);

    const setBadge = (data) => {
        const badgeInformation = {
            ...data,
            date: dayjs(data?.date).toDate()
        };
        setBadgeData(badgeInformation);
        if (data?.badge?.size) {
            setSelectedLogo(URL.createObjectURL(data?.badge));
        }
    };

    React.useEffect(() => {
        if (badge && typeof badge === 'object') {
            setBadge(badge);
        }
    }, [badge]);

    const handleOnFormChangesMade = () => {
        if (!hasChanges) {
            setHasChanges(true);
        }
    };

    const onAddCustomAttribute = () => {
        let attributeNumber = 0;
        if (badgeData?.custom_attributes?.length > 0) {
            attributeNumber = badgeData?.custom_attributes?.length;
        }
        const newAttribute = {
            label: `Custom Attribute ${attributeNumber + 1}`,
            value: `Custom value ${attributeNumber + 1}`
        };
        if (attributeNumber <= maxCustomAttributes - 1) {
            const newCustomAttributes = badgeData?.custom_attributes?.slice() ?? [];
            newCustomAttributes.push(newAttribute);
            setBadgeData({
                ...badgeData,
                ...{ custom_attributes: newCustomAttributes }
            });
        }
        handleOnFormChangesMade();
    };

    const removeCustomAttribute = (index) => {
        const customAttributesCopy = badgeData?.custom_attributes?.slice() ?? [];
        customAttributesCopy.splice(index, 1);
        setBadgeData({
            ...badgeData,
            ...{ custom_attributes: customAttributesCopy }
        });
        handleOnFormChangesMade();
    };

    const onChangeAttributeLabel = (attributeIdx, e) => {
        const { custom_attributes } = badgeData;
        const attribute = { ...custom_attributes[attributeIdx] };
        attribute.label = e.target.value || 'Custom Attribute 1';
        const changedAttributes = custom_attributes?.slice();
        changedAttributes[attributeIdx] = attribute;
        setBadgeData({
            ...badgeData,
            ...{ custom_attributes: changedAttributes }
        });
    };

    const onChangeAttributeValue = (attributeIdx, e) => {
        const { custom_attributes } = badgeData;
        const attribute = { ...custom_attributes[attributeIdx] };
        attribute.value = e.target.value;
        const changedAttributes = custom_attributes?.slice();
        changedAttributes[attributeIdx] = attribute;
        setBadgeData({
            ...badgeData,
            ...{ custom_attributes: changedAttributes }
        });
    };

    const onSelectBadge = (e) => {
        handleOnFormChangesMade();
        const { name, size, type } = e.target.files[0];
        if (name && size && type === 'image/svg+xml') {
            if (selectedLogo) {
                URL.revokeObjectURL(selectedLogo);
            }
            setSelectedLogo(URL.createObjectURL(e.target.files[0]));
        }
    };

    const onSelectTemplate = (data) => {
        const parsedData = {
            is_template: false
        };
        const badgeImage = b64ToFile(data?.badge, 'badge');
        const issuerImage = b64ToFile(data?.issuer_image, 'issuer_image');
        const receiverImage = b64ToFile(data?.receiver_image, 'receiver_image');
        if (badgeImage) {
            parsedData.badge = badgeImage;
        }
        if (issuerImage) {
            parsedData.issuer_image = issuerImage;
        }
        if (receiverImage) {
            parsedData.receiver_image = receiverImage;
        }
        const cleanedBadgeInformation = {
            ...data,
            ...parsedData
        };
        // remove badge name due to key errors in db;
        delete cleanedBadgeInformation?.name;
        setBadge(cleanedBadgeInformation);
    };

    const handleOnSave = (values) => {
        setHasChanges(false);
        onSave(values);
    };

    const handleOnEditCustomAttributeLabel = (idx) => {
        if (editingCustomAttributeIdx === idx) {
            setEditingCustomAttributeIdx(null);
        } else {
            setEditingCustomAttributeIdx(idx);
        }
    };

    return (
        <>
            <Prompt
                when={hasChanges}
                message="You have unsaved changes. Leaving this page will discard them. Are you sure?"
            />
            {!badge && (
                <div className="container-fluid my-1">
                    <div className="row">
                        <div className="col-md-4">
                            <Breadcrumbs hideDashboard />
                        </div>
                        <div className="col-md-4 offset-md-4 d-flex align-items-center">
                            <TemplatesDropdown
                                type={constants.ELEMENT_TYPE_BADGE}
                                onSelect={onSelectTemplate}
                            />
                        </div>
                    </div>
                </div>
            )}
            <div className="container-fluid bg-white rounded">
                <Form
                    validationSchema={validationSchema}
                    initialValues={badgeData}
                    onSubmit={handleOnSave}
                    enableReinitialize>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="container-fluid">
                                <div className="row my-3">
                                    <div className="col-12">
                                        <TextField
                                            name="name"
                                            label="Element Name"
                                            placeholder="Element name"
                                            onChange={handleOnFormChangesMade}
                                        />
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <div className="col-md-6">
                                        <Select
                                            label="Badge Type"
                                            name="type"
                                            placeholder="Choose element type"
                                            options={elementTypeOptions}
                                            disabled
                                            onChange={handleOnFormChangesMade}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <FileField
                                            label="Badge (SVG)"
                                            name="badge"
                                            accept="image/svg+xml"
                                            placeholder="Upload File"
                                            onChange={onSelectBadge}
                                        />
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <div className="col-12">
                                        <MultilineTextField
                                            name="description"
                                            label="Description"
                                            placeholder="Element description"
                                            onChange={handleOnFormChangesMade}
                                        />
                                    </div>
                                </div>
                                <div className="row my-3">
                                    {/* <div className="col-md-6">
                                    <FileField
                                        label="Receiver's Image"
                                        name="receiver_image"
                                        placeholder="Upload File"
                                    />
                                </div> */}
                                    <div className="col-md-6">
                                        <FileField
                                            label="Issuer's Image"
                                            name="issuer_image"
                                            placeholder="Upload File"
                                            onChange={handleOnFormChangesMade}
                                        />
                                    </div>
                                </div>
                                {badgeData?.custom_attributes?.map((attribute, idx) => (
                                    <div className="row my-3" key={idx}>
                                        <div className="col-12 my-2">
                                            <div className="d-flex align-items-center">
                                                {editingCustomAttributeIdx === idx ? (
                                                    <TextField
                                                        name={`custom_attributes[${idx}].label`}
                                                        placeholder={`Custom Label ${idx + 1}`}
                                                        onChange={(e) =>
                                                            onChangeAttributeLabel(idx, e)
                                                        }
                                                    />
                                                ) : (
                                                    <label
                                                        className="fw-700 input-text-sm mb-0"
                                                        htmlFor={attribute.label}>
                                                        {attribute?.label}
                                                    </label>
                                                )}
                                                <button
                                                    type="button"
                                                    className="org-user-element-icon-sm"
                                                    onClick={() => {
                                                        handleOnEditCustomAttributeLabel(idx);
                                                    }}>
                                                    <img
                                                        src="/assets/vector/mr-update-primary.svg"
                                                        alt=""
                                                        width="100%"
                                                        height="100%"
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-md-10">
                                            <TextField
                                                name={`custom_attributes[${idx}].value`}
                                                placeholder={`Custom Value ${idx + 1}`}
                                                onChange={(e) => onChangeAttributeValue(idx, e)}
                                            />
                                        </div>
                                        <div className="col-md-1 p-0 d-flex align-items-end justify-content-center">
                                            <button type="button" className="btn p-0">
                                                <img
                                                    src="/assets/vector/mr-btn-update.svg"
                                                    alt="Update"
                                                />
                                            </button>
                                        </div>
                                        <div className="col-md-1 p-0 d-flex align-items-end justify-content-center">
                                            <button
                                                type="button"
                                                className="btn p-0"
                                                onClick={() => {
                                                    removeCustomAttribute(idx);
                                                }}>
                                                <img
                                                    src="/assets/vector/mr-btn-delete.svg"
                                                    alt="Delete"
                                                />
                                            </button>
                                        </div>
                                    </div>
                                ))}
                                <div className="row my-3 px-3">
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-border-rounded-xl px-5 py-2"
                                        onClick={onAddCustomAttribute}>
                                        Add Attribute
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 py-3">
                            <div className="d-block">
                                <small className="fw-700">Preview</small>
                            </div>
                            {!selectedLogo && (
                                <img
                                    src="/assets/vector/mr-badge.svg"
                                    alt=""
                                    className="p-3 w-100 border org-user-element-badge-preview-container"
                                />
                            )}
                            {selectedLogo && (
                                <img src={selectedLogo} alt="" className="m-3 w-100" />
                            )}
                        </div>
                        <div className="col-lg-3 d-flex align-items-end p-3">
                            <div className="container-fluid px-0">
                                <div className="row my-3">
                                    <div className="col-12">
                                        <Checkbox
                                            name="is_template"
                                            label="Save as template"
                                            onChange={handleOnFormChangesMade}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <SubmitButton
                                            title="Save"
                                            className="btn btn-primary w-100 py-2"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <button
                                            type="button"
                                            className="btn text-primary border w-100 py-2"
                                            onClick={onCancel}>
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        </>
    );
};

export default ElementBadge;
