import React from 'react';
import { useHistory } from 'react-router';
import * as Yup from 'yup';
import { Form, Select, SubmitButton, TextField } from '../../../components/FormElements';
import { BackBtn } from '../../../components/Buttons';
import EditableLogo from '../../../components/EditableLogo';
import constants from '../../../utils/constants';
import { getCurrentEnv, getDisplayValueForUserRole } from '../../../utils/dataUtils';
import { useGetProfileQuery } from '../../../services/authService';
import { useInviteUserToOrgMutation } from '../../../services/orgService';
import notifyError, { errors } from '../../../utils/rtkErrorHelper';
import { fileToB64 } from '../../../utils/fileUtils';
import { showToastError } from '../../../utils/toastHelper';

const userValidationSchema = Yup.object().shape({
    username: Yup.string().required('Enter username'),
    role: Yup.string().required('Select role'),
    email: Yup.string().email('Invalid email address').required('Enter user email')
});

const roleOptions = [
    {
        label: getDisplayValueForUserRole(constants.ROLE_ORG_ADMIN),
        value: constants.ROLE_ORG_ADMIN
    },
    {
        label: getDisplayValueForUserRole(constants.ROLE_ORG_USER),
        value: constants.ROLE_ORG_USER
    }
];

const AddUser = () => {
    const {
        data: profile,
        error: getProfileError,
        isError: isGetProfileError
    } = useGetProfileQuery();

    const [
        inviteUser,
        { error: inviteUserError, isError: isInviteUserError }
    ] = useInviteUserToOrgMutation();

    React.useEffect(() => {
        notifyError(isGetProfileError, getProfileError, errors.AUTH_GET_PROFILE);
        notifyError(isInviteUserError, inviteUserError, errors.ORGANIZATION_USER_INVITE);
    }, [getProfileError, isGetProfileError, inviteUserError, isInviteUserError]);

    const [userAvatar, setUserAvatar] = React.useState(null);

    const [userdata, setUserdata] = React.useState({
        username: 'Username',
        role: constants.ROLE_ORG_USER,
        email: 'jwick@sampleorg.com',
        phone: ''
    });

    const history = useHistory();
    const onSubmitUser = async (user) => {
        const { organizationId: orgId } = profile;
        const env = getCurrentEnv();
        const parsedUser = {
            ...user,
            ...{ avatar: userAvatar, env }
        };
        await inviteUser({ user: parsedUser, orgId });
        history.push('/home/users');
    };

    const onClickBack = () => {
        history.push('/home/users');
    };

    const onSetAvatar = async (e) => {
        const avatarFile = e?.target?.files[0];
        if (avatarFile) {
            try {
                const b64Avatar = await fileToB64(avatarFile);
                setUserAvatar(b64Avatar);
            } catch (error) {
                showToastError(`Unable to parse avatar. ${error}`);
            }
        }
    };

    const onDeleteAvatar = () => {
        setUserAvatar(null);
    };

    return (
        <div className="container-fluid mt-4 bg-white rounded">
            <Form
                validationSchema={userValidationSchema}
                initialValues={userdata}
                onSubmit={onSubmitUser}
                enableReinitialize>
                <div className="px-2 mx-3 py-3">
                    <BackBtn title="Add User" onClick={onClickBack} />
                </div>
                <div className="px-2">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="container-fluid">
                                <div className="row justify-content-center py-3">
                                    <div className="col-md-12">
                                        <TextField
                                            required
                                            label="Username"
                                            name="username"
                                            placeholder="Username"
                                        />
                                    </div>
                                </div>
                                <div className="row justify-content-center py-3">
                                    <div className="col-md-12">
                                        <Select
                                            required
                                            label="Role"
                                            name="role"
                                            options={roleOptions}
                                        />
                                    </div>
                                </div>
                                <div className="row justify-content-center py-3">
                                    <div className="col-md-12">
                                        <TextField
                                            required
                                            label="Email"
                                            name="email"
                                            placeholder="abc@mail.com"
                                        />
                                    </div>
                                </div>
                                <div className="row justify-content-center py-3">
                                    <div className="col-md-12">
                                        <TextField
                                            label="Phone"
                                            name="phone"
                                            placeholder="07XXXXXXXX"
                                        />
                                    </div>
                                </div>
                                <div className="row justify-content-center py-3">
                                    <div className="col-md-12">
                                        <SubmitButton
                                            title="Invite User"
                                            className="btn btn-primary fw-700 px-5 py-2"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 d-flex justify-content-center">
                            <div className="d-block">
                                <div className="fw-700">
                                    Profile Picture
                                    <EditableLogo
                                        logo={
                                            userAvatar || '/assets/img/mr-avatar-placeholder-2.png'
                                        }
                                        onSelectImage={onSetAvatar}
                                        onDeleteImage={onDeleteAvatar}
                                        editable
                                        deletable
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default AddUser;
