import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import PaginatedTable from '../../../components/PaginatedTable';
import Breadcrumbs from '../../../components/Breadcrumbs';
import { normaliseObjectArray, parseDate } from '../../../utils/dataUtils';
import constants from '../../../utils/constants';

import { useGetProfileQuery } from '../../../services/authService';
import { useGetIssuedRewardsQuery } from '../../../services/activityService';
import notifyError, { errors } from '../../../utils/rtkErrorHelper';

const IssuedActivities = () => {
    const history = useHistory();
    const { path } = useRouteMatch();

    const [activeFilters, setActiveFilters] = React.useState([]);

    const {
        data: profile,
        error: getProfileError,
        isError: isGetProfileError
    } = useGetProfileQuery();
    const {
        data: rewards,
        error: getIssuedRewardsError,
        isError: isGetIssuedRewardsError
    } = useGetIssuedRewardsQuery(
        {
            orgId: profile?.organizationId
        },
        {
            skip: !profile?.organizationId
        }
    );

    React.useEffect(() => {
        notifyError(isGetProfileError, getProfileError, errors.AUTH_GET_PROFILE);
        notifyError(isGetIssuedRewardsError, getIssuedRewardsError, errors.ISSUED_REWARDS_RETRIEVE);
    }, [getIssuedRewardsError, isGetIssuedRewardsError, getProfileError, isGetProfileError]);

    const renderActivityStatus = (rowData) => {
        const { activityStatus } = rowData;
        switch (activityStatus) {
            case constants.ACTIVITY_REWARDED:
                return 'Rewarded';
            case constants.ACTIVITY_DESIGN:
                return 'Design';
            case constants.ACTIVITY_ON_GOING:
                return 'On Going';
            default:
                return '';
        }
    };

    const renderIssuedDate = (rowData) => {
        const { issuedDate: issuedDate } = rowData;
        const date = parseDate(issuedDate, 'DD.MM.YYYY', true);
        return date;
    };

    const columns = ['holderName', renderIssuedDate, renderActivityStatus, ''];

    const columnNames = ['Holder Name', 'Issued Date', 'Activity Status'];

    const filters = [
        { type: constants.FILTER_TYPE_TEXT },
        { type: constants.FILTER_TYPE_TEXT },
        {
            type: constants.FILTER_TYPE_OPTIONS,
            options: [
                { value: constants.ACTIVITY_REWARDED, label: 'Rewarded' },
                { value: constants.ACTIVITY_ON_GOING, label: 'On Going' },
                { value: constants.ACTIVITY_DESIGN, label: 'Design' }
            ]
        }
    ];

    const filterColumnRefs = ['holderName', 'issuedDate', 'activityStatus'];

    const parsedIssuedRewards = React.useMemo(() => {
        let parsedData = {
            byId: {},
            allIds: []
        };
        if (rewards?.length) {
            let data = rewards;
            if (activeFilters?.some((filter) => filter && typeof filter === 'string')) {
                data = rewards.filter((dataItem) => {
                    const availableFilterQueryList = [];
                    const rowDataValueList = [];
                    activeFilters.forEach((filterValue, filterIdx) => {
                        if (filterValue) {
                            const dataField = dataItem[filterColumnRefs[filterIdx]]
                                ?.toString?.()
                                ?.toLowerCase?.();
                            availableFilterQueryList.push(
                                filterValue?.toString?.()?.toLowerCase?.()
                            );
                            rowDataValueList.push(dataField);
                        }
                    });
                    return availableFilterQueryList.every((query, queryIdx) =>
                        rowDataValueList[queryIdx]?.includes?.(query)
                    );
                });
            }
            parsedData = normaliseObjectArray(data, true);
        }
        return parsedData;
    }, [rewards, activeFilters]);

    const onSetFilters = (fltrs) => {
        setActiveFilters(fltrs ?? []);
    };

    const navigateOnClickActivity = ({ activityStatus, activityId }) => {
        if (activityStatus === constants.ACTIVITY_ON_GOING) {
            history.push('/activities/ongoing/', { activityId });
        }
        if (activityStatus === constants.ACTIVITY_REWARDED) {
            history.push('/activities/rewarded/', { activityId });
        }
    };

    return (
        <div>
            <Breadcrumbs rootName="Home" hideDashboard />
            <div className="my-2">
                <PaginatedTable
                    rounded
                    columns={columns}
                    columnNames={columnNames}
                    rows={parsedIssuedRewards.allIds}
                    data={parsedIssuedRewards.byId}
                    onRowClick={navigateOnClickActivity}
                    filters={filters}
                    headerActions={[]}
                    activeFilters={activeFilters}
                    onSetFilters={onSetFilters}
                    pagination={parsedIssuedRewards?.allIds?.length > 4}
                    pageSize={4}
                />
            </div>
        </div>
    );
};

export default IssuedActivities;
