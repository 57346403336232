import React from 'react';
import * as Yup from 'yup';
import { useHistory, useLocation, Prompt } from 'react-router';
import {
    Form,
    TextField,
    Select,
    PasswordFeild,
    SubmitButton
} from '../../../components/FormElements';
import PasswordConfirmationPrompt from '../../../components/Prompt';
import { BackBtn } from '../../../components/Buttons';
import EditableLogo from '../../../components/EditableLogo';
import constants from '../../../utils/constants';
import { getDisplayValueForUserRole } from '../../../utils/dataUtils';

import { useGetProfileQuery } from '../../../services/authService';
import {
    useGetOrgUserQuery,
    useUpdateOrgUserMutation,
    useChangeUserPasswordMutation,
    useMakeUserInactiveMutation
} from '../../../services/orgService';

import notifyError, { errors } from '../../../utils/rtkErrorHelper';
import { fileToB64 } from '../../../utils/fileUtils';
import { showToastError, showToastSuccess } from '../../../utils/toastHelper';

const userValidationSchema = Yup.object().shape({
    newPassword: Yup.string(),
    confirmNewPassword: Yup.string().oneOf([Yup.ref('newPassword')], 'Passwords must match')
});

const statusOptions = [
    { value: constants.USER_INVITED, label: 'Invited' },
    { value: constants.USER_ACTIVE, label: 'Active' },
    { value: constants.USER_DISABLED, label: 'Disabled' }
];

const roleOptions = [
    {
        label: getDisplayValueForUserRole(constants.ROLE_ORG_ADMIN),
        value: constants.ROLE_ORG_ADMIN
    },
    {
        label: getDisplayValueForUserRole(constants.ROLE_ORG_USER),
        value: constants.ROLE_ORG_USER
    }
];

const EditUser = () => {
    const history = useHistory();
    const { state: routerState } = useLocation();

    const [userAvatar, setUserAvatar] = React.useState(null);
    const [hasUnsavedChanges, setHasUnsavedChanges] = React.useState(false);
    const [user, setUser] = React.useState({
        first_name: '',
        last_name: '',
        username: '',
        role: '',
        status: '',
        email: '',
        phone: '',
        newPassword: '',
        confirmNewPassword: ''
    });
    const [showUserDeletePrompt, setShowUserDeletePrompt] = React.useState(false);

    const [
        makeUserInactive,
        { error: makeUserInactiveError, isError: isMakeUserInactiveError }
    ] = useMakeUserInactiveMutation();

    const {
        data: profile,
        error: getProfileError,
        isError: isGetProfileError
    } = useGetProfileQuery();

    const { data: userdata, isError: isGetUserError, error: getUserError } = useGetOrgUserQuery(
        { userId: routerState?.userId, orgId: profile?.organizationId },
        { skip: !(profile?.organizationId && routerState?.userId) }
    );

    const [
        updateOrgUser,
        { error: updateUserError, isError: isUpdateUserError }
    ] = useUpdateOrgUserMutation();

    const [
        changeUserPassword,
        {
            error: changeUserPasswordError,
            isError: isChangeUserPasswordError,
            isSuccess: isChangeUserPasswordSuccess
        }
    ] = useChangeUserPasswordMutation();

    const parseUserData = () => {
        if (userdata) {
            const { avatar } = userdata;
            setUser((prevState) => ({ ...prevState, ...userdata }));
            setUserAvatar(avatar);
        }
    };

    React.useEffect(() => {
        notifyError(isGetProfileError, getProfileError, errors.AUTH_GET_PROFILE);
        notifyError(isUpdateUserError, updateUserError, errors.ORGANIZATION_USER_UPDATE);
        notifyError(isGetUserError, getUserError, errors.ORGANIZATION_USER_UPDATE);
        notifyError(
            isChangeUserPasswordError,
            changeUserPasswordError,
            errors.ORGANIZATION_USER_PASSWORD_CHANGE
        );
        notifyError(
            isMakeUserInactiveError,
            makeUserInactiveError,
            errors.ORGANIZATION_USER_UPDATE
        );
    }, [
        getProfileError,
        isGetProfileError,
        updateUserError,
        isUpdateUserError,
        isGetUserError,
        getUserError,
        isChangeUserPasswordError,
        changeUserPasswordError,
        isMakeUserInactiveError,
        makeUserInactiveError
    ]);

    React.useEffect(() => {
        if (!routerState?.userId) {
            history.push('/home/users');
        }
    }, [routerState]);

    React.useEffect(() => {
        parseUserData();
    }, [userdata]);

    React.useEffect(() => {
        if (isChangeUserPasswordSuccess) {
            showToastSuccess('Organization user password changed successfully');
        }
    }, [isChangeUserPasswordSuccess]);

    const isAdminAndMyProfile = React.useMemo(() => {
        let flag = false;
        if (profile?.id === Number(routerState?.userId)) {
            if (profile?.role === constants.ROLE_ORG_USER) {
                flag = true;
            }
        }

        if (profile?.id !== Number(routerState?.userId)) {
            flag = true;
        }

        return flag;
    }, [profile, routerState]);

    const onClickBack = () => {
        history.goBack();
    };

    const onClickEdit = async (updatedUser) => {
        const userData = { ...updatedUser };
        delete userData?.is_active;

        if (hasUnsavedChanges) {
            setHasUnsavedChanges(false);
        }
        const { id: userId, username } = userdata;
        const { organizationId: orgId } = profile;
        const parsedUser = {
            ...userData,
            ...{ avatar: userAvatar }
        };
        delete parsedUser.is_active;
        await updateOrgUser({ user: parsedUser, userId, orgId });
        history.push(`/home/users/${username}`, { userId });
    };

    const onSetAvatar = async (e) => {
        const avatarFile = e?.target?.files[0];
        if (avatarFile) {
            try {
                const b64Avatar = await fileToB64(avatarFile);
                setUserAvatar(b64Avatar);
            } catch (error) {
                showToastError(`Unable to parse avatar. ${error}`);
            }
        }
    };

    const onDeleteAvatar = () => {
        setUserAvatar(null);
    };

    const onChangeFirstName = (e) => {
        setUser({
            ...user,
            ...{ first_name: e.target.value }
        });

        if (!hasUnsavedChanges) {
            setHasUnsavedChanges(true);
        }
    };
    const onChangeLastName = (e) => {
        setUser({
            ...user,
            ...{ last_name: e.target.value }
        });

        if (!hasUnsavedChanges) {
            setHasUnsavedChanges(true);
        }
    };
    const onChangeUsername = (e) => {
        setUser({
            ...user,
            ...{ username: e.target.value }
        });

        if (!hasUnsavedChanges) {
            setHasUnsavedChanges(true);
        }
    };
    const onChangeRole = (e) => {
        setUser({
            ...user,
            ...{ role: e.target.value }
        });

        if (!hasUnsavedChanges) {
            setHasUnsavedChanges(true);
        }
    };
    const onChangeStatus = (e) => {
        setUser({
            ...user,
            ...{ status: e.target.value }
        });

        if (!hasUnsavedChanges) {
            setHasUnsavedChanges(true);
        }
    };
    const onChangeEmail = (e) => {
        setUser({
            ...user,
            ...{ email: e.target.value }
        });

        if (!hasUnsavedChanges) {
            setHasUnsavedChanges(true);
        }
    };
    const onChangePhone = (e) => {
        setUser({
            ...user,
            ...{ phone: e.target.value }
        });

        if (!hasUnsavedChanges) {
            setHasUnsavedChanges(true);
        }
    };

    const onChangePassword = (e) => {
        setUser({
            ...user,
            ...{ newPassword: e.target.value }
        });

        if (!hasUnsavedChanges) {
            setHasUnsavedChanges(true);
        }
    };

    const onChangeConfirmPassword = (e) => {
        setUser({
            ...user,
            ...{ confirmNewPassword: e.target.value }
        });

        if (!hasUnsavedChanges) {
            setHasUnsavedChanges(true);
        }
    };

    const onClickResetPassword = async () => {
        const { newPassword, confirmNewPassword } = user;

        if (newPassword && confirmNewPassword) {
            const payload = {
                orgId: profile?.organizationId,
                userId: profile?.id,
                password: newPassword,
                confirm_password: confirmNewPassword
            };
            if (hasUnsavedChanges) {
                setHasUnsavedChanges(false);
            }
            await changeUserPassword(payload);
        } else {
            window.alert('Both password and confirmation required.');
        }
    };

    const showDeleteConfirmation = () => {
        setShowUserDeletePrompt(true);
    };

    const onClickDelete = async (password) => {
        const orgId = profile?.organizationId;
        const { id: userId } = userdata;
        await makeUserInactive({ orgId, userId, password });
    };

    const onCloseDeletePrompt = () => {
        setShowUserDeletePrompt(false);
    };

    return (
        <div>
            <Prompt
                when={hasUnsavedChanges}
                message="You have unsaved changes. Leaving this page will discard them. Are you sure?"
            />
            <div className="container-fluid py-3 px-5 mt-4 bg-white rounded">
                <Form
                    initialValues={user}
                    validationSchema={userValidationSchema}
                    onSubmit={onClickEdit}
                    enableReinitialize>
                    <div className="row mb-4 px-2 justify-content-between">
                        <BackBtn title="User Profile" onClick={onClickBack} />
                        <div className="d-block">
                            <SubmitButton
                                title="Save"
                                className="btn btn-primary px-5 py-2 mx-1 fw-700"
                            />
                            <button
                                type="button"
                                className="btn text-primary border px-5 mx-1 py-2 fw-700"
                                onClick={onClickBack}>
                                Cancel
                            </button>
                            <button
                                type="button"
                                className="org-user-profile-action-btn mx-1"
                                onClick={showDeleteConfirmation}>
                                <img src="/assets/vector/mr-btn-delete.svg" alt="Delete" />
                            </button>
                        </div>
                    </div>
                    <div className="row my-4">
                        <div className="col-lg-6">
                            <div className="container-fluid">
                                <div className="row mb-3">
                                    <div className="col-md-6">
                                        <TextField
                                            name="first_name"
                                            label="First name"
                                            onChange={onChangeFirstName}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <TextField
                                            name="last_name"
                                            label="Last name"
                                            onChange={onChangeLastName}
                                        />
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <div className="col-12">
                                        <TextField
                                            name="username"
                                            label="Username"
                                            onChange={onChangeUsername}
                                            disabled
                                        />
                                    </div>
                                </div>
                                {isAdminAndMyProfile && (
                                    <div className="row my-3">
                                        <div className="col-12">
                                            <Select
                                                name="role"
                                                label="Role"
                                                onChange={onChangeRole}
                                                options={roleOptions}
                                            />
                                        </div>
                                    </div>
                                )}
                                <div className="row my-3">
                                    <div className="col-12">
                                        <Select
                                            options={statusOptions}
                                            name="status"
                                            label="Status"
                                            onChange={onChangeStatus}
                                        />
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <div className="col-12">
                                        <TextField
                                            name="email"
                                            label="Email"
                                            onChange={onChangeEmail}
                                        />
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <div className="col-12">
                                        <TextField
                                            name="phone"
                                            label="Phone"
                                            onChange={onChangePhone}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="container-fluid">
                                <div className="row mb-3">
                                    <div className="col-3">
                                        <small className="fw-700">Profile Picture</small>
                                        <EditableLogo
                                            logo={
                                                userAvatar ||
                                                '/assets/img/mr-avatar-placeholder-2.png'
                                            }
                                            onSelectImage={onSetAvatar}
                                            onDeleteImage={onDeleteAvatar}
                                            editable
                                            deletable
                                        />
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <div className="col-12">
                                        <PasswordFeild
                                            name="newPassword"
                                            label="Password"
                                            onChange={onChangePassword}
                                        />
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <div className="col-12">
                                        <PasswordFeild
                                            name="confirmNewPassword"
                                            label="Confirm Password"
                                            onChange={onChangeConfirmPassword}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <div className="col-12">
                                        <button
                                            type="button"
                                            className="btn btn-primary py-2 fw-700"
                                            onClick={onClickResetPassword}>
                                            Change Password
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
            <PasswordConfirmationPrompt
                show={showUserDeletePrompt}
                onClose={onCloseDeletePrompt}
                onDone={onClickDelete}
                title="Password Confirmation"
                message="Confirm deletion. Please enter your password."
                inputProps={{
                    type: 'password',
                    placeholder: 'Password'
                }}
            />
        </div>
    );
};

export default EditUser;
