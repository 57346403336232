import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { modalActions } from '../../../state/modal';
import PaginatedTable from '../../../components/PaginatedTable';
import { modalTypes } from '../../../components/ModalContainer';
import constants from '../../../utils/constants';
import { getDisplayValueForRewardeeState, normaliseObjectArray } from '../../../utils/dataUtils';

import { useGetProfileQuery } from '../../../services/authService';
import {
    useGetReceiversQuery,
    useGetGroupsQuery,
    useAddReceiverToGroupMutation,
    useRemoveReceiverFromGroupMutation
} from '../../../services/receiverService';

import notifyError, { errors } from '../../../utils/rtkErrorHelper';

const ReceiversAll = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const {
        data: profile,
        error: getProfileError,
        isError: isGetProfileError
    } = useGetProfileQuery();
    const {
        data: receivers,
        error: getReceiversError,
        isError: isGetReceiversError
    } = useGetReceiversQuery(
        {
            orgId: profile?.organizationId
        },
        {
            skip: !profile?.organizationId
        }
    );
    const { data: groups, error: getGroupsError, isError: isGetGroupsError } = useGetGroupsQuery(
        {
            orgId: profile?.organizationId
        },
        {
            skip: !profile?.organizationId
        }
    );
    const [
        addReceiverToGroup,
        { isError: isAddReceiverToGroupError, error: addReceiverToGroupError }
    ] = useAddReceiverToGroupMutation();
    const [
        removeReceiverFromGroup,
        { isError: isRemoveReceiverFromGroupError, error: removeReceiverFromGroupError }
    ] = useRemoveReceiverFromGroupMutation();

    React.useEffect(() => {
        notifyError(isGetProfileError, getProfileError, errors.AUTH_GET_PROFILE);
        notifyError(isGetReceiversError, getReceiversError, errors.RECEIVERS_RETRIEVE);
        notifyError(isGetGroupsError, getGroupsError, errors.GROUPS_RETRIEVE);
        notifyError(
            isAddReceiverToGroupError,
            addReceiverToGroupError,
            errors.RECEIVER_ADD_TO_GROUP
        );
        notifyError(
            isRemoveReceiverFromGroupError,
            removeReceiverFromGroupError,
            errors.RECEIVER_REMOVE_FROM_GROUP
        );
    }, [
        getProfileError,
        isGetProfileError,
        getReceiversError,
        isGetReceiversError,
        getGroupsError,
        isGetGroupsError,
        isAddReceiverToGroupError,
        addReceiverToGroupError,
        isRemoveReceiverFromGroupError,
        removeReceiverFromGroupError
    ]);

    const [activeFilters, setActiveFilters] = React.useState([]);

    const isRewardeeInGroup = (rewardee, groupId) => {
        const { groups: rewardeeGroups } = rewardee;
        return [...rewardeeGroups].some((grp) => grp?.id === Number(groupId));
    };

    const onChangeRewardeeGroup = async (value, rewardee) => {
        if (value) {
            const orgId = profile?.organizationId;
            const groupId = value;
            const { id: receiverId } = rewardee;
            const payload = {
                receiverId,
                orgId,
                groupId
            };
            if (isRewardeeInGroup(rewardee, value)) {
                await removeReceiverFromGroup(payload);
            } else {
                await addReceiverToGroup(payload);
            }
        }
    };

    const renderActiveStatus = () => {
        const labelText = getDisplayValueForRewardeeState(constants.REWARDEE_REGISTERED);
        return (
            <div className="d-flex align-items-center">
                <img src="/assets/vector/mr-verified.svg" alt="" />
                <div className="px-2">{labelText}</div>
            </div>
        );
    };

    const renderInvitedStatus = () => (
        <div className="d-flex align-items-center">
            <img src="/assets/vector/mr-clock.svg" alt="" />
            <div className="px-2">Invited</div>
        </div>
    );

    const renderRewardeeStatus = (rowData) => {
        const { status } = rowData;
        switch (status) {
            case constants.REWARDEE_REGISTERED:
                return renderActiveStatus(rowData);
            case constants.REWARDEE_INVITED:
                return renderInvitedStatus();
            default:
                return '';
        }
    };

    const renderAddToGroupAction = (rowData) => (
        <select
            value={rowData?.group || ''}
            onChange={(e) => onChangeRewardeeGroup(e.target.value, rowData)}
            className="form-control form-control-sm border-0 fw-700 text-primary">
            <option value="">Add To Group</option>
            {groups?.map?.((grp) => (
                <option
                    key={grp?.group?.id}
                    className={isRewardeeInGroup(rowData, grp?.group?.id) ? 'text-danger' : ''}
                    value={grp?.group?.id}>
                    {grp?.group?.name}
                </option>
            ))}
        </select>
    );

    const columns = ['name', renderRewardeeStatus, 'email', renderAddToGroupAction];

    const columnNames = ['Name', 'Status', 'Email'];

    const filters = [
        { type: constants.FILTER_TYPE_TEXT },
        {
            type: constants.FILTER_TYPE_OPTIONS,
            options: [
                {
                    label: getDisplayValueForRewardeeState(constants.REWARDEE_REGISTERED),
                    value: constants.REWARDEE_REGISTERED
                },
                {
                    label: getDisplayValueForRewardeeState(constants.REWARDEE_INVITED),
                    value: constants.REWARDEE_INVITED
                }
            ]
        },
        { type: constants.FILTER_TYPE_TEXT }
    ];

    const filterColumnRefs = ['name', 'status', 'email'];

    const parsedRewardees = React.useMemo(() => {
        let parsedData = {
            byId: {},
            allIds: []
        };
        if (receivers?.length) {
            let data = receivers;

            if (activeFilters?.some((filter) => filter && typeof filter === 'string')) {
                data = receivers.filter((dataItem) => {
                    const availableFilterQueryList = [];
                    const rowDataValueList = [];
                    activeFilters.forEach((filterValue, filterIdx) => {
                        if (filterValue) {
                            const dataField = dataItem[filterColumnRefs[filterIdx]]
                                ?.toString?.()
                                ?.toLowerCase?.();
                            availableFilterQueryList.push(
                                filterValue?.toString?.()?.toLowerCase?.()
                            );
                            rowDataValueList.push(dataField);
                        }
                    });
                    return availableFilterQueryList.every((query, queryIdx) =>
                        rowDataValueList[queryIdx]?.includes?.(query)
                    );
                });
            }
            parsedData = normaliseObjectArray(data);
        }
        return parsedData;
    }, [receivers, activeFilters]);

    const onSetFilters = (fltrs) => {
        setActiveFilters(fltrs ?? []);
    };

    const onClickInviteOne = () => {
        dispatch(
            modalActions.showModal({
                modalType: modalTypes.INVITE_RECEIVER_MODAL
            })
        );
    };

    const onClickInviteMultiple = () => {
        history.push('/receivers/invite-multiple');
    };

    const HeaderActions = () => (
        <div className="d-flex align-items-center justify-content-end">
            <button
                type="button"
                className="btn btn-primary mx-2 w-100 text-truncate"
                onClick={onClickInviteMultiple}>
                Invite multiple
            </button>
            <button type="button" className="btn btn-primary mx-2 w-100" onClick={onClickInviteOne}>
                Invite
            </button>
        </div>
    );

    return (
        <div className="pt-4">
            <PaginatedTable
                rounded
                columns={columns}
                columnNames={columnNames}
                headerActions={<HeaderActions />}
                rows={parsedRewardees.allIds}
                data={parsedRewardees.byId}
                filters={filters}
                onSetFilters={onSetFilters}
                pagination={parsedRewardees.allIds.length > 5}
                pageSize={5}
            />
        </div>
    );
};

export default ReceiversAll;
