// Ceylon Exchange Mentoring - Certificate of Mastery
import React from 'react';
import { parseDate } from '../../../utils/dataUtils';
import QRCode from 'qrcode.react';
import './Template2.styles.scss';

const Template2 = ({ certificate }) => {
    const [logoURL, setLogoURL] = React.useState('');
    const [seal1URL, setSeal1URL] = React.useState('');
    const [seal2URL, setSeal2URL] = React.useState('');

    const generateDataURLFromFile = (file) => {
        if (file?.name && file?.size) {
            return URL.createObjectURL(file);
        }
        return null;
    };

    const revokeURL = (url) => {
        if (url) {
            URL.revokeObjectURL(url);
        }
    };

    React.useEffect(() => {
        revokeURL(logoURL);
        revokeURL(seal1URL);
        revokeURL(seal2URL);
        const newLogoURL = generateDataURLFromFile(certificate?.certificate_logo);
        const newSeal1URL = generateDataURLFromFile(certificate?.signature_one);
        const newSeal2URL = generateDataURLFromFile(certificate?.signature_two);
        setLogoURL(newLogoURL);
        setSeal1URL(newSeal1URL);
        setSeal2URL(newSeal2URL);
        return () => {
            revokeURL(newLogoURL);
            revokeURL(newSeal1URL);
            revokeURL(newSeal2URL);
        };
    }, [certificate]);

    return (
        <div className="template-2-certificate-container">
            <div className="certificate-root">
                <div className="certificate-content">
                    <div className="certificate-badge-container">
                        <img
                            src={
                                logoURL ||
                                '/assets/certificate/template-2/mr-template-2-logo-icon.png'
                            }
                            alt=""
                            className="certificate-badge"
                        />
                    </div>
                    <div className="certificate-title-container">
                        {certificate?.title || 'Certificate Title'}
                    </div>
                    <div className="certificate-recievers-info-container">
                        <div className="awarded-to-text">Awarded to</div>
                        <div className="recievers-name">Receiver&lsquo;s Name</div>
                    </div>
                    <div className="certificate-description-container">
                        {certificate?.description || ''}
                    </div>
                    {certificate?.custom_attributes?.length ? (
                        <div className="certificate-custom-attributes-container">
                            {certificate.custom_attributes.map((attribute, idx) => (
                                <div key={idx}>
                                    <div className="attribute-label">{attribute?.label || ''}</div>
                                    <div className="attribute-value">{attribute?.value || ''}</div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        ''
                    )}
                    <div className="certificate-signature-pane">
                        <div className="signatures-container">
                            {certificate?.show_signature_one ? (
                                <div className="signature">
                                    {!seal1URL && <div className="signature-text">Signature 1</div>}
                                    {seal1URL && (
                                        <div className="signature-image-container">
                                            <img
                                                src={seal1URL}
                                                alt="Signature"
                                                className="signature-image"
                                            />
                                        </div>
                                    )}
                                    <div className="signature-line" />
                                    <div className="signature-hint">
                                        {certificate?.signature_title_one || ''}
                                    </div>
                                </div>
                            ) : (
                                <div className="mx-4" />
                            )}
                            <div className="certificate-date-container">
                                <div className="date">
                                    {parseDate(certificate?.date, 'DD/MM/YYYY')}
                                </div>
                                <div className="signature-line" />
                                <div className="hint-text">Date</div>
                            </div>
                            {certificate?.show_signature_two ? (
                                <div className="signature">
                                    {!seal2URL && <div className="signature-text">Signature 2</div>}
                                    {seal2URL && (
                                        <div className="signature-image-container">
                                            <img
                                                src={seal2URL}
                                                alt="Signature"
                                                className="signature-image"
                                            />
                                        </div>
                                    )}
                                    <div className="signature-line" />
                                    <div className="signature-hint">
                                        {certificate?.signature_title_two || ''}
                                    </div>
                                </div>
                            ) : (
                                <div className="mx-4" />
                            )}
                        </div>
                    </div>
                    <div className="certificate-footer">
                        {!!certificate?.display_qr && (
                            <div className="qr-code">
                                <QRCode value={window.location.origin} size={50} />
                            </div>
                        )}
                        <div className="org-logo-wrapper">
                            <img
                                src={
                                    logoURL ||
                                    '/assets/certificate/template-2/mr-template-2-logo-light.png'
                                }
                                alt=""
                                className="org-logo"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Template2;
