import React, { useRef, useState } from 'react';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { issue } from '@zedeid-sdk/sd-vc-lib';
import { BackBtn } from '../../../components/Buttons';
import Breadcrumbs from '../../../components/Breadcrumbs';
import { Form, Checkbox } from '../../../components/FormElements';
import PaginatedTable from '../../../components/PaginatedTable';
import constants from '../../../utils/constants';
import {
    getClaimsFromActivityElements,
    getCurrentEnv,
    getDisplayValueForRewardeeState,
    normaliseObjectArray,
    parseDate
} from '../../../utils/dataUtils';
import {
    useGenerateRewardsForRewardeeMutation,
    useResetGeneratedRewardsMutation,
    useShareGeneratedRewardsMutation,
    useShareSelectedRewardAgainMutation,
    useGetActivityQuery,
    useGetRewardeesQuery,
    useUpdateActivityMutation,
    useResetActivityElementsMutation
} from '../../../services/activityService';
import { useGetOrganizationQuery } from '../../../services/orgService';
import { useGetProfileQuery } from '../../../services/authService';
import receiverService from '../../../services/receiverService';
import zedeidService from '../../../services/zedeidService';
import notifyError, { errors } from '../../../utils/rtkErrorHelper';
import { showToastError, showToastSuccess } from '../../../utils/toastHelper';

const OngoingActivity = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { state: routerState } = useLocation();

    const [hasVCGenerationError, setHasVCGenerationError] = React.useState(false);
    const [activeFilters, setActiveFilters] = React.useState([]);
    const [activityData, setActivityData] = React.useState({
        name: 'Activity Name',
        start_date: new Date(),
        end_date: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
        is_badge: false,
        badge: null,
        is_certificate: false,
        certificate: null,
        add_group: false,
        add_individuals: false
    });
    const [State, setState] = useState({
        shared: false,
        generate: false
    });
    const [Selected, setSelected] = useState([]);

    const {
        data: profile,
        error: getProfileError,
        isError: isGetProfileError
    } = useGetProfileQuery();
    const {
        data: selectedOrganization,
        error: selectedOrganizationError,
        isError: isSelectedOrganizationError
    } = useGetOrganizationQuery(
        {
            orgId: profile?.organizationId
        },
        { skip: !profile?.organizationId }
    );
    const {
        data: selectedActivity,
        refetch: refetchSelectedActivity,
        error: selectedActivityError,
        isError: isSelectedActivityError
    } = useGetActivityQuery(
        {
            orgId: profile?.organizationId,
            activityId: routerState?.activityId
        },
        { skip: !(profile?.organizationId && routerState?.activityId) }
    );
    const {
        data: rewardees,
        isError: isGetRewardeesError,
        error: getRewardeesError
    } = useGetRewardeesQuery(
        {
            orgId: profile?.organizationId,
            activityId: routerState?.activityId
        },
        { skip: !(profile?.organizationId && routerState?.activityId) }
    );

    const [
        generateRewards,
        { isError: isGenerateRewardsError, error: generateRewardsError }
    ] = useGenerateRewardsForRewardeeMutation();

    const [
        resetActivityRewards,
        { isError: isResetRewardsError, error: resetRewardsError }
    ] = useResetGeneratedRewardsMutation();

    const [
        shareActivityRewards,
        { isError: isShareRewardsError, error: shareRewardsError }
    ] = useShareGeneratedRewardsMutation();

    const [
        shareSelectedActivityReward,
        { isError: isShareSelectedActivityRewardError, error: shareSelectedActivityRewardError }
    ] = useShareSelectedRewardAgainMutation();

    const [
        updateActivity,
        { isError: isUpdateActivityError, error: updateActivityError }
    ] = useUpdateActivityMutation();

    const [
        resetActivityElement,
        { isError: isResetActivityElementError, error: resetActivityElementError }
    ] = useResetActivityElementsMutation();

    React.useEffect(() => {
        if (selectedActivity) {
            refetchSelectedActivity();
        }
    }, []);

    React.useEffect(() => {
        if (Array.isArray(rewardees) && Selected.length === 0) {
            //add to check list
            if (rewardees.length > 0) {
                setSelected(
                    rewardees.map((item) => {
                        return { id: item.id, checked: true };
                    })
                );
            }
        }
    }, [rewardees, Selected]);

    React.useEffect(() => {
        notifyError(isGetProfileError, getProfileError, errors.AUTH_GET_PROFILE);
        notifyError(isSelectedActivityError, selectedActivityError, errors.ACTIVITY_RETRIEVE);
        notifyError(isGetRewardeesError, getRewardeesError, errors.ACTIVITY_REWARDEE_RETRIEVAL);
        notifyError(
            isGenerateRewardsError,
            generateRewardsError,
            errors.ACTIVITY_REWARDEE_GENERATE_REWARD
        );
        notifyError(isResetRewardsError, resetRewardsError, errors.ACTIVITY_RESET_REWARDS);
        notifyError(isShareRewardsError, shareRewardsError, errors.ACTIVITY_SHARE_REWARDS);
        notifyError(
            isShareSelectedActivityRewardError,
            shareSelectedActivityRewardError,
            errors.ACTIVITY_REWARDEES_SHARE_REWARD
        );
        notifyError(
            isSelectedOrganizationError,
            selectedOrganizationError,
            errors.ORGANIZATION_RETRIEVE
        );
        notifyError(isUpdateActivityError, updateActivityError, errors.ACTIVITIY_UPDATE);
        notifyError(
            isResetActivityElementError,
            resetActivityElementError,
            errors.ACTIVITIES_RESET_ELEMENTS
        );
    }, [
        getProfileError,
        isGetProfileError,
        selectedActivityError,
        isSelectedActivityError,
        isGetRewardeesError,
        getRewardeesError,
        isGenerateRewardsError,
        generateRewardsError,
        isResetRewardsError,
        resetRewardsError,
        isShareRewardsError,
        shareRewardsError,
        isShareSelectedActivityRewardError,
        shareSelectedActivityRewardError,
        isSelectedOrganizationError,
        selectedOrganizationError,
        isUpdateActivityError,
        updateActivityError,
        isResetActivityElementError,
        resetActivityElementError
    ]);

    React.useEffect(() => {
        if (selectedActivity) {
            const { activity, rewardElemets: elements } = selectedActivity;

            let isCertificate = false;
            let certificateData = null;
            let isBadge = false;
            let badgeData = null;

            if (elements?.length) {
                badgeData = elements.find(
                    (element) => element?.type === constants.ELEMENT_TYPE_BADGE
                );
                certificateData = elements.find(
                    (element) => element?.type === constants.ELEMENT_TYPE_CERTIFICATE
                );
                isBadge = !!badgeData;
                isCertificate = !!certificateData;
            }

            setActivityData({
                ...activity,
                ...{
                    start_date: activity?.start_date && dayjs(activity?.start_date).toDate(),
                    end_date: activity?.end_date && dayjs(activity?.end_date).toDate(),
                    is_badge: isBadge,
                    badge: badgeData,
                    is_certificate: isCertificate,
                    certificate: certificateData
                }
            });
        }
    }, [selectedActivity]);

    React.useEffect(() => {
        if (!routerState?.activityId) {
            history.push('/activities');
        }
    }, [routerState]);

    const filters = [
        { type: constants.FILTER_TYPE_TEXT },
        { type: constants.FILTER_TYPE_TEXT },
        { type: constants.FILTER_TYPE_TEXT },
        {
            type: constants.FILTER_TYPE_OPTIONS,
            options: [
                { label: 'Reward Generated', value: true },
                { label: 'Not Generated', value: false }
            ]
        },
        {
            type: constants.FILTER_TYPE_OPTIONS,
            options: [
                { label: 'Reward Shared', value: true },
                { label: 'Not Shared', value: false }
            ]
        }
    ];

    const filterColumnRefs = ['name', 'status', 'email', 'generated', 'shared'];

    const parsedRewardees = React.useMemo(() => {
        let parsedData = {
            byId: {},
            allIds: []
        };
        if (rewardees?.length) {
            let data = rewardees;
            if (activeFilters?.some((filter) => filter && typeof filter === 'string')) {
                data = rewardees.filter((dataItem) => {
                    const availableFilterQueryList = [];
                    const rowDataValueList = [];
                    activeFilters.forEach((filterValue, filterIdx) => {
                        if (filterValue) {
                            const dataField = dataItem[filterColumnRefs[filterIdx]]
                                ?.toString?.()
                                ?.toLowerCase?.();
                            availableFilterQueryList.push(
                                filterValue?.toString?.()?.toLowerCase?.()
                            );
                            rowDataValueList.push(dataField);
                        }
                    });
                    return availableFilterQueryList.every((query, queryIdx) =>
                        rowDataValueList[queryIdx]?.includes?.(query)
                    );
                });
            }

            parsedData = normaliseObjectArray(data);
        }
        return parsedData;
    }, [rewardees, activeFilters]);

    const isActivityEndedOrEnding = () => {
        const endDate = selectedActivity?.activity?.end_date;
        const currentDate = dayjs();
        const parsedEndDate = dayjs(endDate);
        return (
            currentDate.isSame(parsedEndDate, 'day') || currentDate.isAfter(parsedEndDate, 'day')
        );
    };

    const onGenerateRewardsForRewardee = async (
        rowData,
        issuerPrivateKey = '',
        confirmed = false
    ) => {
        let error = '';
        if (activityData?.is_badge || activityData?.is_certificate) {
            if (!isActivityEndedOrEnding() && !confirmed) {
                const confirmation = window.confirm(
                    'Activity is not in ending or ended state. Are you sure to generate rewards?'
                );
                if (!confirmation) {
                    error = 'Reward generate confirmation discarded';
                    return Promise.reject(error);
                }
            }

            const { organizationId: orgId } = profile;
            const { id: activityId } = selectedActivity?.activity;
            const { receiverId: receiverId, status, reward } = rowData;
            let receiverDID;

            if (!reward?.generated && status === constants.REWARDEE_REGISTERED) {
                const env = getCurrentEnv();
                const {
                    data: receiverInfo,
                    isSuccess: isReceiverInfoSuccess,
                    error: receiverInfoError
                } = await dispatch(
                    receiverService.endpoints.getReceiverInfo.initiate({
                        orgId,
                        receiverId,
                        env
                    })
                );
                if (isReceiverInfoSuccess) {
                    receiverDID = receiverInfo?.users?.[0].receivingDID;
                    const receiver = receiverInfo?.users?.[0];

                    let issuerKey = issuerPrivateKey;

                    if (!issuerPrivateKey) {
                        issuerKey = window.prompt('Please enter private key');
                    }

                    if (!issuerKey) {
                        error = 'Private key not provided';
                        return Promise.reject(error);
                    }

                    const { activity, rewardElemets: rewardElements } = selectedActivity;
                    const claims = getClaimsFromActivityElements(
                        activity,
                        rewardElements,
                        receiver,
                        selectedOrganization
                    );
                    const {
                        data: response,
                        error: getHolderPubKeyError,
                        isError: isGetHolderPubKeyError
                    } = await dispatch(
                        zedeidService.endpoints.getHolderPublicKey.initiate({
                            receiverDID
                        })
                    );

                    if (getHolderPubKeyError && isGetHolderPubKeyError) {
                        const formattedError = {
                            response: {
                                data: {
                                    message: getHolderPubKeyError?.data?.error || ''
                                }
                            }
                        };
                        notifyError(
                            isGetHolderPubKeyError,
                            formattedError,
                            errors.ZEDEID_HOLDER_PUB_KEY
                        );
                        error = 'Receiver public key error';
                        return Promise.reject(error);
                    }

                    if (!(getHolderPubKeyError && isGetHolderPubKeyError)) {
                        const {
                            publicKey: holderPublicKey
                        } = response.didDocument.authentication[0];

                        try {
                            const signature = issue(issuerKey, claims, holderPublicKey);
                            const payload = {
                                generatedRewards: [
                                    {
                                        generated: true,
                                        signature,
                                        receiverId
                                    }
                                ]
                            };
                            await generateRewards({
                                orgId,
                                activityId,
                                payload
                            });
                            return Promise.resolve(' generated');
                        } catch (err) {
                            notifyError(true, '', err);
                            error = err;
                            return Promise.reject(error);
                        }
                    }
                } else {
                    error = receiverInfoError;
                    showToastError('Unable to retrieve receiver information.');
                    console.error(receiverInfoError);
                    return Promise.reject(error);
                }
            } else {
                error = 'Invalid rewardee for reward generation';
                return Promise.reject(error);
            }
        } else {
            showToastError('You should have at least one element to generate rewards');
            error = 'No elements to generate rewards';
            return Promise.reject(error);
        }
    };

    const onShareRewardAgain = async ({ receiverId: receiverId, reward }) => {
        const { generated } = reward;
        if (generated) {
            const { organizationId: orgId } = profile;
            const { id: activityId } = selectedActivity?.activity;
            const env = getCurrentEnv();
            const result = await shareSelectedActivityReward({
                orgId,
                activityId,
                receiverId,
                body: { env }
            });
            return {
                result,
                receiverId
            };
        }
    };

    const shareRewardsForAll = async () => {
        setState({ shared: true, generate: true });

        //process one after other
        for (let index = 0; index < parsedRewardees.allIds.length; index++) {
            const rewardeeId = parsedRewardees.allIds[index];
            const rewardee = parsedRewardees.byId[rewardeeId];
            const checkData = Selected.find((i) => i.id === rewardeeId);

            if (!checkData?.checked) continue;
            // if (rewardee.reward.shared) continue

            await onShareRewardAgain(rewardee);
        }

        setState({ shared: false, generate: false });

        showToastSuccess('Reward sharing completed for all eligible receivers.');

        /* parsedRewardees.allIds.forEach(async (rewardeeId) => {
            const rewardee = parsedRewardees.byId[rewardeeId];
            await onShareRewardAgain(rewardee);
        }); */
    };

    const generateRewardsForAll = async () => {
        if (activityData?.is_badge || activityData?.is_certificate) {
            let confirmed = false;
            if (!isActivityEndedOrEnding()) {
                const confirmation = window.confirm(
                    'Activity is not in ending or ended state. Are you sure to generate rewards?'
                );
                if (!confirmation) {
                    return;
                }
            }
            confirmed = true;

            let issuerKey = '';
            issuerKey = window.prompt('Please enter private key');

            if (!issuerKey) {
                return;
            }

            /*             const rewardGenerationRequests = parsedRewardees.allIds.map(
                            (rewardeeId) => {
                                const rewardee = parsedRewardees.byId[rewardeeId];
                                return onGenerateRewardsForRewardee(
                                    rewardee,
                                    issuerKey,
                                    confirmed
                                );
                            }
                        );
            
                        try {
                            await Promise.all(rewardGenerationRequests);
                            showToastSuccess(
                                "Reward generation completed for all eligible receivers."
                            );
                        } catch (error) {
                            showToastError("Reward generation has errors");
                        } */

            try {
                setState({ shared: false, generate: true });

                //process one after other
                for (let index = 0; index < parsedRewardees.allIds.length; index++) {
                    const rewardeeId = parsedRewardees.allIds[index];
                    const rewardee = parsedRewardees.byId[rewardeeId];
                    const checkData = Selected.find((i) => i.id === rewardeeId);

                    if (!checkData?.checked) continue;

                    await onGenerateRewardsForRewardee(rewardee, issuerKey, confirmed);
                }

                setState({ shared: false, generate: false });

                showToastSuccess('Reward generation completed for all eligible receivers.');
            } catch (error) {
                showToastError('Reward generation has errors');
            }
        } else {
            showToastError('You should have at least one element to generate rewards');
        }
    };

    const resetRewards = async () => {
        if (
            window.confirm(
                'You are about to reset rewards for this activity. Please confirm reset rewards request.'
            )
        ) {
            const { organizationId: orgId } = profile;
            const { id: activityId } = selectedActivity?.activity;
            await resetActivityRewards({ orgId, activityId });
        }
    };

    const onSelectDeselect = (val) => {
        setSelected(
            Selected.map((item) => {
                return { ...item, checked: val };
            })
        );
    };

    const generatedRewardsAvailable = () => {
        return !!rewardees?.some((rewardee) => rewardee?.reward?.generated);
    };

    const renderGeneratedStatus = (rowData) => {
        const { generated } = rowData?.reward;
        const checkedData = Selected.find((i) => i.id == rowData.id);

        if (generated) {
            return (
                <div className="px-3">
                    <img src="/assets/vector/mr-verified.svg" alt="🗸" />
                </div>
            );
        } else if (State.generate && checkedData.checked) {
            return (
                <div className="lds-spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            );
        }

        return '';
    };

    const renderSharedStatus = (rowData) => {
        const { shared } = rowData?.reward;

        const checkedData = Selected.find((i) => i.id == rowData.id);

        if (shared) {
            return (
                <div className="px-3">
                    <img src="/assets/vector/mr-verified.svg" alt="🗸" />
                </div>
            );
        } else if (State.shared && checkedData.checked) {
            return (
                <div className="lds-spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            );
        }

        return '';
    };

    const renderCheckboxStatus = (rowData) => {
        let selected = Selected.find((i) => i.id === rowData.id);

        return (
            <div className="px-3">
                <div className="custom-control custom-checkbox mr-3 mb-2">
                    <input
                        type="checkbox"
                        className="custom-control-input"
                        id={rowData.id}
                        value={rowData.id}
                        checked={selected?.checked || false}
                        onChange={(e) => onChangeIsSelected(e, rowData.id)}
                    />
                    <label
                        className="custom-control-label fw-700 input-text-sm"
                        htmlFor={rowData.id}></label>
                </div>
            </div>
        );
    };

    const renderViewAction = (rowData) => {
        const { generated, shared } = rowData?.reward;
        if (generated && shared) {
            return (
                <button type="button" className="btn p-0 w-100">
                    View
                </button>
            );
        }
        return ' ';
    };

    const renderShareAgainAction = (rowData) => {
        const { generated, shared } = rowData?.reward;
        if (generated && shared) {
            return (
                <button
                    type="button"
                    onClick={(e) => {
                        e.stopPropagation();
                        onShareRewardAgain(rowData);
                    }}
                    style={{ minWidth: 100 }}
                    className="btn p-0 w-100">
                    Share Again
                </button>
            );
        }
        return ' ';
    };

    const renderActiveStatus = () => {
        const labelText = getDisplayValueForRewardeeState(constants.REWARDEE_REGISTERED);
        return (
            <div className="d-flex align-items-center">
                <img src="/assets/vector/mr-verified.svg" alt="" />
                <div className="px-2">{labelText}</div>
            </div>
        );
    };

    const renderInvitedStatus = () => (
        <div className="d-flex align-items-center">
            <img src="/assets/vector/mr-clock.svg" alt="" />
            <div className="px-2">Invited</div>
        </div>
    );

    const renderRewardeeStatus = (rowData) => {
        const { status } = rowData;
        switch (status) {
            case constants.REWARDEE_REGISTERED:
                return renderActiveStatus(rowData);
            case constants.REWARDEE_INVITED:
                return renderInvitedStatus();
            default:
                return '';
        }
    };

    const columns = [
        renderCheckboxStatus,
        'name',
        renderRewardeeStatus,
        'email',
        renderGeneratedStatus,
        renderSharedStatus,
        renderViewAction,
        renderShareAgainAction
    ];

    const columnNames = ['Name', 'Status', 'Email', 'Generated', 'Shared'];

    const actions = [];

    const onSetFilters = (fltrs) => {
        setActiveFilters(fltrs ?? []);
    };

    const onClickBack = () => {
        history.goBack();
    };

    // on checkbox state change
    const onChangeIsSelected = (e, id) => {
        let i = Selected.findIndex((i) => i.id === id);

        if (i > -1) {
            Selected[i].checked = e.target.checked;
            setSelected([...Selected]);
        }
    };

    const onClickMakeAsARewardedActivity = async () => {
        const { organizationId: orgId } = profile;
        const { id: activityId } = selectedActivity?.activity;
        const activity = {
            status: constants.ACTIVITY_REWARDED
        };

        const confirmation = window.confirm(
            'You are about to change the state of this activity to "Rewarded" state. Are you sure?'
        );

        if (confirmation) {
            await updateActivity({ activity, orgId, activityId });
            history.push('/activities/rewarded/', { activityId });
        }
    };

    const onEditBadge = () => {
        let elementId = '';
        if (activityData?.is_badge && activityData?.badge) {
            elementId = activityData?.badge?.id;
            history.push(`/elements/${elementId}`, {
                overrideActiveRoute: '/activities'
            });
        } else {
            showToastError('Unable to find added reward badge information.');
        }
    };

    const onEditCertificate = () => {
        let elementId = '';
        if (activityData?.is_certificate && activityData?.certificate) {
            elementId = activityData?.certificate?.id;
            history.push(`/elements/${elementId}`, {
                overrideActiveRoute: '/activities'
            });
        } else {
            showToastError('Unable to find added reward certificate information.');
        }
    };

    const onResetBadge = async () => {
        let elementId = '';
        if (activityData?.is_badge && activityData?.badge) {
            elementId = activityData?.badge?.id;
            const { id: activityId } = selectedActivity?.activity;
            const confirm = window.confirm('Confirm element deletion.');
            if (confirm) {
                const { organizationId: orgId } = profile;
                await resetActivityElement({
                    orgId,
                    elementId,
                    activityId
                });
            }
        } else {
            showToastError('Unable to find added reward badge information.');
        }
    };

    const onResetCertificate = async () => {
        let elementId = '';
        if (activityData?.is_certificate && activityData?.certificate) {
            elementId = activityData?.certificate?.id;
            const { id: activityId } = selectedActivity?.activity;
            const confirm = window.confirm('Confirm element deletion.');
            if (confirm) {
                const { organizationId: orgId } = profile;
                await resetActivityElement({
                    orgId,
                    elementId,
                    activityId
                });
            }
        } else {
            showToastError('Unable to find added reward certificate information.');
        }
    };

    const handleRewardGenerationForReciever = async (rowData) => {
        try {
            await onGenerateRewardsForRewardee(rowData);
            showToastSuccess('Rewards generated.');
        } catch (error) {
            showToastError('Error while trying to generate rewards for receiver.');
        }
    };

    return (
        <div className="container-fluid">
            <Breadcrumbs rootName="Home" hideDashboard />
            <div className="p-4 rounded bg-white">
                <div className="row">
                    <div className="col-md-2">
                        <div className="p-3">
                            <BackBtn onClick={onClickBack} />
                        </div>
                    </div>
                    {isActivityEndedOrEnding() && (
                        <div className="col-md-3 offset-md-7">
                            <div className="py-4">
                                <button
                                    onClick={onClickMakeAsARewardedActivity}
                                    type="button"
                                    className="btn btn-primary">
                                    Make as a Rewarded Activity
                                </button>
                            </div>
                        </div>
                    )}
                </div>
                <div className="row">
                    <div className="col-lg-9">
                        <Form initialValues={activityData} enableReinitialize>
                            <div className="container-fluid">
                                <div className="row my-3">
                                    <div className="col-md-4">
                                        <div className="h5 text-primary">Activity Details</div>
                                    </div>
                                </div>
                                <div className="row mb-5">
                                    <div className="col-md-3 fw-700">Name</div>
                                    <div className="col-md-4">{activityData?.name || ''}</div>
                                </div>
                                <div className="row my-5">
                                    <div className="col-md-3 fw-700">Starting Date</div>
                                    <div className="col-md-4">
                                        {parseDate(activityData?.start_date, 'DD/MM/YYYY')}
                                    </div>
                                </div>
                                <div className="row my-5">
                                    <div className="col-md-3 fw-700">End Date</div>
                                    <div className="col-md-4">
                                        {parseDate(activityData?.end_date, 'DD/MM/YYYY')}
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <div className="col-md-3">
                                        <div className="h5 text-primary">Reward Elements</div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 d-flex align-items-center">
                                        <Checkbox name="is_badge" label="Badge" disabled />
                                    </div>
                                    <div className="col-md-3 d-flex align-items-center">Badge</div>
                                    <div className="col-md-1 d-flex align-items-center">
                                        <button type="button" onClick={onEditBadge} className="btn">
                                            <img
                                                src="/assets/vector/mr-btn-update.svg"
                                                alt="Edit"
                                            />
                                        </button>
                                    </div>
                                    <div className="col-md-1 d-flex align-items-center">
                                        <button
                                            type="button"
                                            className="btn text-danger"
                                            onClick={onResetBadge}>
                                            Reset
                                        </button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 d-flex align-items-center">
                                        <Checkbox
                                            name="is_certificate"
                                            label="Certificate"
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-3 d-flex align-items-center">
                                        Certificate
                                    </div>
                                    <div className="col-md-1 d-flex align-items-center">
                                        <button
                                            onClick={onEditCertificate}
                                            type="button"
                                            className="btn">
                                            <img
                                                src="/assets/vector/mr-btn-update.svg"
                                                alt="Edit"
                                            />
                                        </button>
                                    </div>
                                    <div className="col-md-1 d-flex align-items-center">
                                        <button
                                            type="button"
                                            className="btn text-danger"
                                            onClick={onResetCertificate}>
                                            Reset
                                        </button>
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <div className="col-md-3">
                                        <div className="h5 text-primary">Reward Receivers</div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <PaginatedTable
                                            columns={columns}
                                            columnNames={columnNames}
                                            data={parsedRewardees.byId}
                                            rows={parsedRewardees.allIds}
                                            filters={filters}
                                            actions={actions}
                                            headerActions={[]}
                                            onSetFilters={onSetFilters}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                    <div className="col-lg-3 d-flex flex-column justify-content-end">
                        <button
                            disabled={State.generate}
                            type="button"
                            className="btn btn-primary my-2"
                            onClick={generateRewardsForAll}>
                            Generate Rewards
                        </button>
                        <button
                            disabled={State.shared || State.generate}
                            type="button"
                            className="btn btn-primary my-2"
                            onClick={shareRewardsForAll}>
                            Share Rewards
                        </button>
                        {/* {generatedRewardsAvailable() ? (
                            <button
                                disabled={State.shared || State.generate}
                                type="button"
                                className="btn btn-primary my-2"
                                onClick={shareRewardsForAll}
                            >
                                Share Rewards
                            </button>
                        ) : (
                            <button
                                disabled={State.generate}
                                type="button"
                                className="btn btn-primary my-2"
                                onClick={generateRewardsForAll}
                            >
                                Generate Rewards
                            </button>
                        )} */}
                        <button
                            type="button"
                            className="btn btn-outline-primary my-2"
                            onClick={() => onSelectDeselect(true)}>
                            Select All
                        </button>
                        <button
                            type="button"
                            className="btn btn-outline-primary my-2"
                            onClick={() => onSelectDeselect(false)}>
                            De-Select All
                        </button>
                        <button
                            type="button"
                            className="btn btn-outline-primary my-2"
                            onClick={resetRewards}
                            // TODO: verify this logic
                            disabled>
                            Reset Rewards
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OngoingActivity;
