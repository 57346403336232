import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useGetIssuerProfileQuery } from '../services/orgService';
import { Form, MultilineTextField, TextField } from '../components/FormElements';
import { BackBtn } from '../components/Buttons';
import EditableLogo from '../components/EditableLogo';
import notifyError, { errors } from '../utils/rtkErrorHelper';
import { showToastError } from '../utils/toastHelper';

const IssuerProfile = () => {
    const history = useHistory();
    const { issuerId: orgId } = useParams();
    const [profileData, setProfileData] = React.useState({
        name: '',
        description: '',
        domain: '',
        logo: ''
    });
    const {
        data: issuerProfile,
        isError: isIssuerProfileError,
        error: issuerProfileError
    } = useGetIssuerProfileQuery({ orgId }, { skip: !(orgId && Number(orgId) > 0) });

    React.useEffect(() => {
        if (!Number(orgId) > 0) {
            showToastError('Invalid issuer ID. Please try again.');
        }
    }, [orgId]);

    React.useEffect(() => {
        if (issuerProfile) {
            setProfileData(issuerProfile);
        }
    }, [issuerProfile]);

    React.useEffect(() => {
        notifyError(isIssuerProfileError, issuerProfileError, errors.ISSUER_PROFILE_RETRIEVE);
    }, [isIssuerProfileError, issuerProfileError]);

    const onClickBack = () => {
        history.push('/signin');
    };

    return (
        <div id="issuerProfile">
            <div className="card bg-white rounded p-3">
                <div className="top">
                    <BackBtn onClick={onClickBack} title="Navigate to Login" />
                </div>
                <div className="content">
                    <div className="section">
                        <h5 className="title">Issuer Details</h5>

                        <div className="section-content">
                            {/*                             <h6 className="sub-title">Issuer Name</h6> */}
                            <p className="desc">{profileData.name}</p>
                        </div>
                        <div className="section-content">
                            {/* <h6 className="sub-title">Issuer Description</h6> */}
                            <p className="desc">{profileData.description}</p>
                        </div>
                        <div className="section-content">
                            {/* <h6 className="sub-title">Issuer Domain</h6> */}
                            <a target="_blank" href={profileData.domain} className="desc">
                                {profileData.domain}
                            </a>
                        </div>
                    </div>
                    <div className="section image-section">
                        <div className="section-logo">
                            <EditableLogo
                                logo={profileData?.logo || '/assets/img/mr-badge-placeholder.png'}
                                fullSize
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IssuerProfile;
