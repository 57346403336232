import React from 'react';
import _ from 'lodash';
import { useHistory, useParams } from 'react-router';
import * as Yup from 'yup';
import { BackBtn } from '../../../components/Buttons';
import { Form, SubmitButton, TextField } from '../../../components/FormElements';
import {
    useGetOrganizationQuery,
    useUpdateOrganizationMutation,
    useUpdateOrgUserMutation
} from '../../../services/orgService';
import constants from '../../../utils/constants';
import notifyError, { errors } from '../../../utils/rtkErrorHelper';
import { getCurrentEnv, normaliseObjectArray } from '../../../utils/dataUtils';
import Switch from '../../../components/Switch';
import PaginatedTable from '../../../components/PaginatedTable';
import Prompt from '../../../components/Prompt';
import { useGetProfileQuery } from '../../../services/authService';
import { modalActions } from '../../../state/modal';
import { modalTypes } from '../../../components/ModalContainer';
import { useDispatch } from 'react-redux';

const organizationValidationSchema = Yup.object().shape({
    name: Yup.string().required('Enter organization name'),
    domain: Yup.string().required('Enter domain'),
    admin: Yup.string().required('Enter organization admin name'),
    adminEmail: Yup.string()
        .email('Invalid email address')
        .required('Enter organization admin email')
});

const OrganizationsDetails = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { organizationId } = useParams();

    const {
        data: organization,
        isError: isGetOrganziationError,
        error: getOrganizationError
    } = useGetOrganizationQuery(
        {
            orgId: organizationId
        },
        { skip: !organizationId }
    );

    const [
        updateOrganization,
        { isError: isUpdateOrganizationError, error: updateOrganizationError }
    ] = useUpdateOrganizationMutation();

    const [
        updateOrgUser,
        { error: updateUserError, isError: isUpdateUserError }
    ] = useUpdateOrgUserMutation();

    const { data: profile } = useGetProfileQuery();

    const [organizationData, setOrganizationData] = React.useState({
        name: '',
        domain: '',
        admin: '',
        adminEmail: '',
        admin_1: '',
        adminEmail_1: ''
    });
    const [activeFilters, setActiveFilters] = React.useState([]);
    const [showActiveStateChangePrompt, setShowActiveStateChangePrompt] = React.useState(false);
    const [selectedOrganizationAdmin, setSelectedOrganizationAdmin] = React.useState(null);
    const [selectedActiveState, setSelectedActiveState] = React.useState(null);
    const [adminsStatus, setAdminStatus] = React.useState({});

    React.useEffect(() => {
        if (organization) {
            const parsedOrganization = { ...organization };

            setOrganizationData(parsedOrganization);
        }
    }, [organization, activeFilters]);

    React.useEffect(() => {
        notifyError(isGetOrganziationError, getOrganizationError, errors.ORGANIZATION_RETRIEVE);
        notifyError(isUpdateOrganizationError, updateOrganizationError, errors.ORGANIZATION_UPDATE);
        notifyError(isUpdateUserError, updateUserError, errors.ORGANIZATION_USER_UPDATE);
    }, [
        isGetOrganziationError,
        getOrganizationError,
        isUpdateOrganizationError,
        updateOrganizationError,
        isUpdateUserError,
        updateUserError
    ]);

    const onSubmitOrganization = async (values) => {
        let submittedAdminEmail = '';

        const { name, domain, id: orgId, admin, adminEmail } = values;

        const organizationInfo = _.pickBy(
            {
                name,
                domain,
                admin,
                adminEmail:
                    submittedAdminEmail && submittedAdminEmail !== adminEmail
                        ? adminEmail
                        : undefined,
                env: getCurrentEnv()
            },
            _.identity
        );
        await updateOrganization({ organization: organizationInfo, orgId });
        history.push('/organizations');
    };

    const onClickBack = () => {
        history.push('/organizations');
    };

    const onChangeSwitch = async (orgId, val) => {
        setSelectedOrganizationAdmin(orgId);
        setSelectedActiveState(val);
        setShowActiveStateChangePrompt(true);
    };

    const renderActiveStatus = (rowData) => {
        const labelText = adminsStatus[rowData?.id] ? 'Active' : 'Inactive';
        return (
            <div>
                <Switch
                    value={!!adminsStatus[rowData?.id]}
                    onChange={(val) => {
                        onChangeSwitch(rowData?.id, val);
                    }}
                    label={labelText}
                    confirmAction
                />
            </div>
        );
    };

    const onSetFilters = (fltrs) => {
        setActiveFilters(fltrs ?? []);
    };

    const filters = [
        { type: constants.FILTER_TYPE_TEXT },
        { type: constants.FILTER_TYPE_TEXT },
        {
            type: constants.FILTER_TYPE_OPTIONS,
            options: [
                { label: 'Active', value: true },
                { label: 'Inactive', value: false }
            ]
        }
    ];

    const columns = ['username', 'email', renderActiveStatus];

    const columnNames = ['Name', 'Email', 'Status'];

    const filterColumnRefs = ['username', 'email', 'is_active'];

    const parsedAdmins = React.useMemo(() => {
        let parsedData = {
            byId: {},
            allIds: []
        };
        if (organization) {
            const { users } = organization;
            const admins = users
                .filter(({ role }) => role === constants.ROLE_ORG_ADMIN)
                .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

            if (admins?.length) {
                let data = admins;

                if (activeFilters?.some((filter) => filter && typeof filter === 'string')) {
                    data = data.filter((dataItem) => {
                        const availableFilterQueryList = [];
                        const rowDataValueList = [];
                        activeFilters.forEach((filterValue, filterIdx) => {
                            if (filterValue) {
                                let dataField = dataItem[filterColumnRefs[filterIdx]]
                                    ?.toString?.()
                                    ?.toLowerCase?.();
                                availableFilterQueryList.push(
                                    filterValue?.toString?.()?.toLowerCase?.()
                                );

                                /* check current status of the admin */
                                if (filterIdx == 2) {
                                    let status = adminsStatus[dataItem.id];
                                    dataField = status?.toString?.()?.toLowerCase?.();
                                }
                                rowDataValueList.push(dataField);
                            }
                        });

                        return availableFilterQueryList.every((query, queryIdx) =>
                            rowDataValueList[queryIdx]?.includes?.(query)
                        );
                    });
                }

                if (!Object.keys(adminsStatus).length) {
                    const tempAdminsStatus = {};
                    for (let admin of data) {
                        tempAdminsStatus[admin.id] = admin.is_active;
                    }
                    setAdminStatus(tempAdminsStatus);
                }

                parsedData = normaliseObjectArray(data);
            }
        }
        return parsedData;
    }, [organization, activeFilters, adminsStatus]);

    const onCloseOrganizationAdminStateChangePrompt = () => {
        setShowActiveStateChangePrompt(false);
        setSelectedOrganizationAdmin(null);
        setSelectedActiveState(null);
    };

    const updateOrganizationAdminActiveState = async (password) => {
        if (selectedActiveState != null) {
            const { users } = organization;
            const admin = users.find((i) => i?.id === selectedOrganizationAdmin);

            if (admin) {
                const parsedUser = {
                    ...admin,
                    is_active: selectedActiveState,
                    adminId: profile.id,
                    password: password
                };

                await updateOrgUser({
                    user: parsedUser,
                    userId: admin.id,
                    orgId: admin.organizationId
                });
                setAdminStatus({ ...adminsStatus, [admin.id]: selectedActiveState });
            }
            setSelectedOrganizationAdmin(null);
            setSelectedActiveState(null);
        }
    };

    const onClickInviteAdmin = () => {
        dispatch(
            modalActions.showModal({
                modalType: modalTypes.INVITE_ADMIN_MODAL
            })
        );
    };

    return (
        <div>
            <div className="h4">Organizations/ Manage Organization</div>
            <div className="container-fluid bg-white mt-4 p-3 rounded">
                <Form
                    validationSchema={organizationValidationSchema}
                    initialValues={organizationData}
                    onSubmit={onSubmitOrganization}
                    enableReinitialize>
                    <div className="row">
                        <div className="col-md-4 pl-4">
                            <BackBtn onClick={onClickBack} title="Organizations" />
                        </div>
                        <div className="col-md-2 offset-md-4">
                            <SubmitButton title="Save" className="btn btn-primary w-100 py-2" />
                        </div>
                        <div className="col-md-2">
                            <button
                                type="button"
                                className="btn btn-outline-primary w-100 py-2"
                                onClick={onClickBack}>
                                Cancel
                            </button>
                        </div>
                    </div>
                    <div className="container-fluid p-0">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="container-fluid">
                                    <div className="row justify-content-center my-3">
                                        <div className="col-12">
                                            <TextField
                                                name="name"
                                                placeholder="Organization Name"
                                                label="Organization Name"
                                            />
                                        </div>
                                    </div>
                                    <div className="row justify-content-center my-3">
                                        <div className="col-12">
                                            <TextField
                                                name="domain"
                                                placeholder="Domain"
                                                label="Domain"
                                            />
                                        </div>
                                    </div>
                                    <div className="row justify-content-end my-3">
                                        <div className="col-md-5">
                                            <button
                                                type="button"
                                                className="btn btn-primary w-100 py-2"
                                                onClick={onClickInviteAdmin}>
                                                Add New Admin
                                            </button>
                                        </div>
                                    </div>

                                    <PaginatedTable
                                        rounded
                                        columns={columns}
                                        columnNames={columnNames}
                                        rows={parsedAdmins.allIds}
                                        data={parsedAdmins.byId}
                                        filters={filters}
                                        onSetFilters={onSetFilters}
                                        headerActions={[]}
                                        pagination={parsedAdmins.allIds.length > 4}
                                        pageSize={4}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>

            <Prompt
                show={showActiveStateChangePrompt}
                onClose={onCloseOrganizationAdminStateChangePrompt}
                onDone={updateOrganizationAdminActiveState}
                title="Password Confirmation"
                message="Confirm status change. Please enter your password."
                inputProps={{
                    type: 'password',
                    placeholder: 'Password'
                }}
            />
        </div>
    );
};

export default OrganizationsDetails;
